import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';

import api from '../api';

const pagination = createSelector(
  state => state.invitations.get('pagination'),
  pagination => pagination.toJS(),
);

const invitations = createSelector(
  state => state.invitations.get('invitations'),
  invitations => invitations.toJS(),
);

const invitation = createSelector(
  state => state.invitations.get('invitation'),
  invitation => invitation.toJS(),
);

export const invitationsSelectors = {
  invitations,
  invitation,
  pagination,
};

const initialState = fromJS({
  invitations: [],
  invitation: {},
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  }
});

export const reducer = handleActions(
  {
    INVITATIONS_FETCH_INVITATION_FULFILLED: (state, { payload }) => state.set('invitation', fromJS(payload)),
    INVITATIONS_FETCH_INVITATIONS_FULFILLED: (state, { payload }) => state
      .set('invitations', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
  },
  initialState
);

export const invitationsActions = {
  fetchInvitations: createAction('INVITATIONS_FETCH_INVITATIONS', params => api.get('invitations', { params })),
  fetchInvitation: createAction('INVITATIONS_FETCH_INVITATION', invitationId => api.get(`invitations/${invitationId}`)),
  createInvitation: createAction('INVITATIONS_INVITATION_CREATE', props => api.post('invitations/create', { ...props })),
  sendInvitation: createAction('INVITATIONS_INVITATION_SEND', props => api.post('invitations/send', { ...props })),
  deleteInvitation: createAction('INVITATIONS_DELETE', props => api.post('invitations/delete', { ...props })),
  updateInvitation: createAction('INVITATIONS_UPDATE', (invitationId, props) => api.put(`invitations/update/${invitationId}`, { ...props })),
};
