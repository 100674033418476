import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Grid, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Select, TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Field, Form as FormWrapper } from 'react-final-form';
import map from 'lodash/map';
import get from 'lodash/get';

import { useStyles } from '../../../utils/styles';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';
import { ButtonPrimary } from '../../../_shared';
import { AutoCompleteField } from '../../../_shared/components';

export const FilterMembers = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { navigateTo, route } = useRoute();
  const [isReady, setIsReady] = useState(false);
  const [initialValues, setInitialValues] = useState({
    subsidy: null,
    itemCategories: [],
    activity: [],
  });
  
  const itemCategories = useSelector(administrationSelectors.itemCategories);
  const activityDirections = useSelector(administrationSelectors.activityDirections);
  const subsidies = useSelector(administrationSelectors.subsidies);
  
  const fetchItemCategories = useDispatchHook(administrationActions.fetchItemCategories);
  const fetchActivityDirections = useDispatchHook(administrationActions.fetchActivityDirections);
  const fetchSubsidies = useDispatchHook(administrationActions.fetchSubsidies);
  
  useEffect(() => {
    (async () => {
      await fetchItemCategories({ noPagination: true });
      await fetchActivityDirections({ noPagination: true });
      await fetchSubsidies();
      setIsReady(true);
    })();
  }, [fetchItemCategories, fetchActivityDirections, fetchSubsidies]);
  
  const handleSearch = useCallback(values => {
    let routeParams = { lng: route.params.lng, ...values };

    navigateTo('public', routeParams, {replace: false});
  }, [navigateTo, route]);
  
  useEffect(() => {
    if (route.params) {
      setInitialValues({
        subsidy: get(route, 'params.subsidy', null),
        itemCategories: get(route, 'params.itemCategories', []),
        activity: get(route, 'params.activity', []),
      })
    }
  }, [route]);
  
  if (!isReady) return null;

  return (
    <Wrapper>
      <FormWrapper
        onSubmit={handleSearch}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.field}
                  label={t('addMember.name')}
                  name="name"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  className={classes.select}
                  name="subsidy"
                  label={t('addMember.subsidy')}
                  formControlProps={{ margin: 'none' }}
                >
                  <MenuItem value="">
                    <em>{t('common.select')}</em>
                  </MenuItem>
                  {map(subsidies, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="itemCategories"
                  multiple
                  label={t('addMember.itemCategories')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.itemCategories')}
                  options={map(itemCategories, ({ id, name }) => ({ label: name, value: +id }))}
                  selected={values.itemCategories}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="activity"
                  multiple
                  label={t('addMember.activityDirections')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.activityDirections')}
                  options={map(activityDirections, ({ id, name }) => ({ label: name, value: +id }))}
                  selected={values.activity}
                />
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.search')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;
