import React, {useCallback, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';

import { PageHeader } from '../../../_shared/headers';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions } from '../../../redux/administration';
import Form from './components/form';
import { LayoutContext } from '../../../utils';

const AddItemCategory = () => {
  const { t } = useTranslation();
  const { navigateTo } = useRoute();
  const createItemCategory = useDispatchHook(administrationActions.createItemCategory);
  const { images } = useContext(LayoutContext);
  
  const onSubmit = useCallback(async ({ name, isPublic }) => {
    await createItemCategory({ name: JSON.stringify(name), isPublic, icon: !isEmpty(images) ? images[0] : null });
    navigateTo('layout.itemCategories', {}, {replace: false});
  }, [createItemCategory, navigateTo, images]);
  
  return (
    <Container fluid>
      <PageHeader title={t('itemCategory.add')} backLink="layout.itemCategories" />
      <FormWrapper
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddItemCategory;