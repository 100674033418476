import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import styled from '@emotion/styled';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import filter from 'lodash/filter';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions } from '../../../redux/administration';
import { filedToCurrentLanguage } from '../../../utils';
import { Map } from './map';
import CONFIG from '../../../config';

type Props = {
  handleSubsidy: Function,
}

export const StatisticsMap = ({ handleSubsidy }: Props) => {
  const [services, setServices] = useState([]);
  const [service, setService] = useState(null);
  const fetchServicesProducts = useDispatchHook(administrationActions.fetchServicesProducts);
  const { route } = useRoute();
  
  const groupedServices = groupBy(services, 'name');
  const filteredServices = Object.keys(groupedServices).map(k => ({
    name: k,
    id: groupedServices[k][0].id,
    icon: groupedServices[k].length ? groupedServices[k][0].icon : null,
    count: sumBy(groupedServices[k], 'dataCount'),
    subsidies: without(groupedServices[k].map(i => i.subsidyId), null),
  }));

  const renderImg = src => `${CONFIG.api}/images/${src}`;
  
  useEffect(() => {
    (async () => {
      const { value } = await fetchServicesProducts();
      setServices(value);
    })();
  }, [fetchServicesProducts, setServices]);

  const filteredSubsidies = useCallback(() => {
    const subsidyGrouped = groupBy(filter(services, ({ id }) => service ? id === service : true), 'subsidyId');
    return Object.keys(subsidyGrouped).map(k => ({
      subsidyId: +k,
      serviceId: subsidyGrouped[k][0].id,
      count: sumBy(subsidyGrouped[k], 'dataCount'),
    }));
  }, [service, services]);

  const handleClick = useCallback(id => {
    if (service === id) return setService(null);
    return setService(id);
  }, [setService, service]);

  return (
    <Row>
      <Col md={9} xs={12}>
        <Map services={filteredSubsidies()} fullHeight={false} key="statistics-map" handleSubsidy={id => handleSubsidy({ subsidyId: id, services: uniq(services.map(({id}) => id)), service })} />
      </Col>
      <Col md={3} xs={12}>
        <List>
          {map(filteredServices, ({ name, icon, count, id }) => (
            <ListItem button key={name} onClick={() => handleClick(id)} selected={service === id}>
              <ListItemAvatar>
                <Avatar>
                  {<Logo src={renderImg(icon)} />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={filedToCurrentLanguage(name, get(route, 'params.lng', 'ro'))} secondary={`(${count})`} />
            </ListItem>
          ))}
        </List>
      </Col>
    </Row>
  );
};

const Logo = styled('img')`
  width: 100%;
  padding: 4px;
`;