import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { PageHeader, GridList, Pagination } from '../../_shared';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { trainingActions, trainingSelectors } from '../../redux/training';

const TrainingTypes = () => {
  const { t }= useTranslation('');
  const { route } = useRoute();
  
  const types = useSelector(trainingSelectors.types);
  const pagination = useSelector(trainingSelectors.pagination);
  
  const fetchTrainingType = useDispatchHook(trainingActions.fetchTrainingTypes);
  const deleteTrainingType = useDispatchHook(trainingActions.deleteTrainingType);
  
  const columns = [
    { field: 'name', headerName: t('training.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchTrainingType({
      page: get(route, 'params.page', 1),
    });
  }, [fetchTrainingType, route]);

  return (
    <Container fluid>
      <PageHeader title={t('trainingType.header', { count: pagination.total })} addLink="layout.addTrainingTypes" />
      <GridList
        actions={{
          delete: deleteTrainingType,
          edit: 'layout.editTrainingTypes',
        }}
        columns={columns}
        rows={types}
      />
      <Pagination data={pagination} />
    </Container>
  );
};

export default TrainingTypes;
