import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

const subjects = createSelector(
  state => state.fairs.get('subjects'),
  subjects => subjects.toJS(),
);

export const fairsSelectors = {
  subjects,
};

const initialState = fromJS({
  subjects: [],
});

export const reducer = handleActions(
  {
    FAIRS_FETCH_SUBJECTS: (state, { payload }) => state.set('subjects', fromJS(payload)),
  },
  initialState
);

export const fairsActions = {
  fetchSubjects: createAction('FAIRS_FETCH_SUBJECTS'),
};
