import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { PageHeader } from '../../../_shared/headers';
import { GridList, Pagination } from '../../../_shared';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';

const ActivityDirections = () => {
  const { t }= useTranslation('');
  const { route } = useRoute();
  
  const activityDirections = useSelector(administrationSelectors.activityDirections);
  const pagination = useSelector(administrationSelectors.pagination);
  
  const fetchActivityDirections = useDispatchHook(administrationActions.fetchActivityDirections);
  const deleteActivityDirection = useDispatchHook(administrationActions.deleteActivityDirection);

  const columns = [
    { field: 'name', headerName: t('events.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchActivityDirections({
      page: get(route, 'params.page', 1),
    });
  }, [fetchActivityDirections, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('activityDirection.header', { count: pagination.total })} addLink="layout.addActivityDirection" />
      <GridList
        actions={{
          delete: deleteActivityDirection,
          edit: 'layout.editActivityDirection',
        }}
        columns={columns}
        rows={activityDirections}
      />
      <Pagination data={pagination} />
    </Container>
  );
};

export default ActivityDirections;
