import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import RoomIcon from '@material-ui/icons/Room';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';

import { useDispatchHook } from '../../../utils/hooks';
import { filedToCurrentLanguage } from '../../../utils';
import { Modal } from '../../../_shared';
import { membersActions } from '../../../redux/members';
import { administrationActions } from '../../../redux/administration';
import { MapLegend } from './mapLegend';

type Props = {
  showLegend?: boolean,
  fullHeight?: boolean,
  services?: {}[],
  handleSubsidy?: Function,
}

export const Map = ({ showLegend, services, fullHeight, handleSubsidy }: Props) => {
  const { t } = useTranslation('');
  const [enteredCity, setEnteredCity] = useState(null);
  const [reports, setReports] = useState(null);
  const [legends, setLegends] = useState([]);
  
  const fetchMapReport = useDispatchHook(membersActions.fetchMapReport);
  const fetchSubsidy = useDispatchHook(administrationActions.fetchSubsidy);
  
  let cities = [
    {
      id: 2,
      name: "Balti",
      slug: "balti",
      left: 35,
      top: 22,
      width: 35,
      height: 39,
      color: '#397274',
      regions: [
        {
          name: "Sangerei",
          slug: "sangerei",
          left: 39,
          top: 26,
        },
        {
          name: "Rascani",
          slug: "rascani",
          left: 15,
          top: 15,
          width: 130,
          height: 54,
        },
        {
          name: "Falesti",
          slug: "falesti",
          left: 16,
          top: 21,
          width: 114,
          height: 54,
        },
        {
          name: "Glodeni",
          slug: "glodeni",
          left: 24,
          top: 27,
          width: 102,
          height: 63,
        },
      ]
    },
    {
      id: 9,
      name: "Chisinau",
      slug: "chisinau",
      left: 60,
      top: 46,
      width: 40,
      height: 40,
      color: '#e86c21',
      regions: [
        {
          name: "Anenii noi",
          slug: "anenii-noi",
          left: 68,
          top: 49,
        },
        {
          name: "Ialoveni",
          slug: "ialoveni",
          left: 57,
          top: 50,
          width: 60,
          height: 50,
        },
        {
          name: "Straseni",
          slug: "straseni",
          left: 51,
          top: 40,
        },
      ]
    },
    {
      id: 1,
      name: "Edinet",
      slug: "edinet",
      left: 13,
      top: 7,
      color: '#e5a812',
      regions: [
        {
          name: "Donduseni",
          slug: "donduseni",
          left: 26,
          top: 4,
          withoutMark: true,
        },
        {
          name: "Briceni",
          slug: "briceni",
          left: 5,
          top: 2,
          withoutMark: true,
        },
        {
          name: "Ocnita",
          slug: "ocnita",
          width: 100,
          height: 60,
          left: 20,
          top: 0,
          withoutMark: true,
        },
      ]
    },
    {
      id: 3,
      name: "Soroca",
      slug: "soroca",
      left: 41,
      top: 8,
      width: 60,
      color: '#f0c876',
      regions: [
        {
          name: "Drochia",
          slug: "drochia",
          left: 32,
          top: 11,
        },
        {
          name: "Florești",
          slug: "floresti",
          left: 44,
          top: 17,
          width: 51,
        },
      ]
    },
    {
      id: 11,
      name: "SRL Intereconomservice",
      slug: "transnistria",
      left: 54,
      top: 10,
      width: 60,
      color: '#f0c876',
      regions: [
        {
          name: "Rabnita",
          slug: "rabnita",
          left: 68,
          top: 19,
          width: 32,
        },
        {
          name: "Dubasari",
          slug: "dubasari",
          left: 76,
          top: 39,
        },
        {
          name: "Tiraspol",
          slug: "tiraspol",
          left: 86,
          top: 51,
          width: 52,
        },
      ]
    },
    {
      id: 4,
      name: "Orhei",
      slug: "orhei",
      left: 57,
      top: 32,
      color: '#c0adb6',
      regions: [
        {
          name: "Telenesti",
          slug: "telenesti",
          left: 46,
          top: 27,
        },
        {
          name: "Rezina",
          slug: "rezina",
          left: 57,
          top: 22,
          width: 45,
          height: 50,
        },
        {
          name: "Șoldănești",
          slug: "soldanesti",
          left: 53,
          top: 18,
          width: 60,
          height: 43,
        },
      ]
    },
    {
      id: 6,
      name: "Ungheni",
      slug: "ungheni",
      left: 30,
      top: 36,
      color: '#b93f30',
      regions: [
        {
          name: "Călărași",
          slug: "calarasi",
          left: 43,
          top: 35,
        },
        {
          name: "Nisporeni",
          slug: "nisporeni",
          left: 37,
          top: 43,
        },
      ]
    },
    {
      id: 5,
      name: "Hâncești",
      slug: "hancesti",
      left: 45,
      top: 51,
      color: '#8c63ab',
      regions: [
        {
          name: "Basarabeasca",
          slug: "basarabeasca",
          left: 61,
          top: 65,
          width: 40,
        },
        {
          name: "Cimișlia",
          slug: "cimislia",
          left: 57,
          top: 58,
        },
        {
          name: "Leova",
          slug: "leova",
          left: 45,
          top: 60,
        },
      ]
    },
    {
      id: 8,
      name: "Tighina",
      slug: "tighina",
      left: 77,
      top: 55,
      width: 40,
      height: 50,
      color: '#62a851',
      regions: [
        {
          name: "Căușeni",
          slug: "causeni",
          left: 71,
          top: 57,
          width: 60,
          height: 90,
        },
        {
          name: "Stefan Voda",
          slug: "stefan-voda",
          left: 81,
          top: 63,
          width: 97,
          height: 66,
        },
      ]
    },
    {
      id: 7,
      name: "Cahul",
      slug: "cahul",
      left: 40,
      top: 79,
      width: 74,
      height: 145,
      color: '#397274',
      regions: [
        {
          name: "Cantemir",
          slug: "cantemir",
          left: 42,
          top: 70,
        },
        {
          name: "Taraclia",
          slug: "taraclia",
          left: 50,
          top: 81,
        },
      ]
    },
    {
      id: 10,
      name: "Gagauzia",
      slug: "gagauzia",
      left: 53,
      top: 67,
      width: 40,
      height: 40,
      color: '#917f63',
      regions: [
        {
          name: "Comrat",
          slug: "comrat",
          left: 53,
          top: 73,
          width: 67,
          height: 60,
        },
        {
          name: "Ciadîr-Lunga",
          slug: "ciadar-lunga",
          left: 58,
          top: 77,
          width: 40,
          height: 40,
        },
        {
          name: "Vulcănești",
          slug: "vulcanesti",
          left: 48,
          top: 96,
          width: 40,
          height: 40,
        },
      ]
    },
  ];

  if (!isEmpty(services)) {
    cities = cities.map(city => {
      const service = find(services, ({subsidyId}) => subsidyId === city.id);
      return ({
        ...city,
        count: service ? service.count : 0,
      })
    });
  }

  const handleClick = useCallback(async (cityId, subsidyName) => {
    try {
      if (showLegend) {
        return;
      }
      const { value } = await fetchMapReport(cityId);
      setReports({ ...value, subsidyName });
    } catch (e) {
      setReports(null);
    }
  }, [fetchMapReport, setReports, showLegend]);
  
  const onMouseEnter = throttle(useCallback(async city => {
    setEnteredCity(city.slug);
    if (showLegend) {
      if (some(legends, ({ id }) => city.id === id)) return;
      try {
        const { value } = await fetchSubsidy(city.id);
        setLegends(legends.concat({...value, slug: city.slug}));
      } catch (e) {
        console.warn(e);
      }
    }
  }, [showLegend, legends, setLegends, fetchSubsidy]), 200);


  const renderMark = useCallback(city => {
    if (get(city, 'count', 0) > 0) {
      return (
        <Count
          key={city.slug}
          color={get(city, 'color')}
          title={city.name}
          top={get(city, 'top')}
          left={get(city, 'left')}
          width={get(city, 'width', 74)}
          height={get(city, 'height', 65)}
          onClick={() => handleSubsidy(city.id)}
        >{get(city, 'count')}</Count>
      )
    }
    if (!isEmpty(services)) return null;
    return (
      <>
        <Mark
          key={city.slug}
          title={city.name}
          onClick={() => handleClick(city.id, city.name)}
          onMouseEnter={() => onMouseEnter(city)}
          // onMouseLeave={() => setEnteredCity(null)}
          top={get(city, 'top')}
          left={get(city, 'left')}
          width={get(city, 'width', 74)}
          height={get(city, 'height', 65)}
        >
          {city.withoutMark ? null : <RoomIcon />}
        </Mark>
        {map(get(city, 'regions', []), region => (
          <Mark
            key={region.slug}
            title={region.name}
            onClick={() => handleClick(city.id, city.name)}
            onMouseEnter={() => onMouseEnter(city)}
            // onMouseLeave={() => setEnteredCity(null)}
            top={get(region, 'top')}
            left={get(region, 'left')}
            width={get(region, 'width', 74)}
            height={get(region, 'height', 65)}
          />
        ))}
      </>
    );
  }, [onMouseEnter, handleClick, services, handleSubsidy]);
  
  const hasInteractiveData = cities.some(({count}) => count > 0);
  
  return (
    <Wrapper fullHeight={fullHeight}>
      {hasInteractiveData ? (
        <Branches>
          {cities.map(({ count, name, color }) => count > 0 && (
            <Legend color={color}>{name}: ({count})</Legend>
          ))}
        </Branches>
      ) : null}
      {showLegend ? (
        <MapLegend report={find(legends, ({ slug }) => slug === enteredCity)} />
      ) : null}
      {!showLegend ? (
        <Modal
          isOpen={!!reports}
          onClose={() => setReports(null)}
          header={get(reports, 'subsidyName')}
        >
          <ModalContainer>
            <h5>{t('header.members')}: {get(reports, 'members')}</h5>
            <br/>
            <h5>{t('member.companies')}: {get(reports, 'companies')}</h5>
      
            {!isEmpty(get(reports, 'activity')) && (
              <>
                <br/>
                <h5>{t('addMember.activityDirections')}</h5>
                {map(get(reports, 'activity'), activity => (
                  <div>{filedToCurrentLanguage(get(activity, 'name'))}: <b>{get(activity, 'dataCount')}</b></div>
                ))}
              </>
            )}
      
      
            {!isEmpty(get(reports, 'categories')) && (
              <>
                <br/>
                <h5>{t('addMember.itemCategories')}</h5>
                {map(get(reports, 'categories'), category => (
                  <div>{filedToCurrentLanguage(get(category, 'name'))}: <b>{get(category, 'dataCount')}</b></div>
                ))}
              </>
            )}
          </ModalContainer>
        </Modal>
      ) : null}
      <Container fullWidth={showLegend || !fullHeight}>
        <Marks>
          {map(cities, city => renderMark(city))}
        </Marks>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428 540">
          <g id="Moldova">
            <MarkPath active={enteredCity === 'edinet'} id="Briceni" d="M52.4,66.8l0-0.2l-0.6-0.9l-1.1-0.9l-1-0.5l-1.5,1.3l-2.3-0.8l-3.3-2v-1.1l2.4-0.2l1.4-1.4v-1.6L42,56.9L41.1,55l-0.6-2.1
            L39,51.5l-1.1,1.1l-1.4,2l-1.2,0.6l-0.5-3.1l-0.8-1.4l-5.1-5.3l-1.3,0.8l-2.8-3.5l-2.1-1l-5,1l-2.4-0.5L14,39.5l-1.3,2l-1.3-0.3
            l-2.8-2.5l-2.8,0.1L0,41.6l0.1-1.5l0.8-2.9l1.3-2.2l4-2.5l0.6-2.4l0.7-1.6l2.4,0.9l1.6,1.9l1.3,2.3l1.5,2l2.3,1l2.4-0.2l1.4-1.3
            l0.5-2.4l-0.1-3.5l-0.6-3.5l-0.7-2.2L19.4,22l1.3-1.8l0.7-0.1l2,0.8l0.8,0l1.1-1.1l0.3-1.3l0.4-1.1l1.3-0.4l4,1.8l4,3.4l4.2,2.6
            l4.6-0.8l1.2-1.2l0.9,0.2l0.8,0.5l1.3-0.1l1.5,0.3l0.7-0.5l-0.2-0.9l-0.4-1l-0.1-0.7l0.1-1.2l-0.3-1.6l0.1-1.3l1.3-0.5l1.3,0.4
            l2.5,1.6l13,5l4,0.2l4.6-2.4l0.7-0.8l0.2,0.6l2,8.1l5.1,5.2l-2.1,3.9l0.9,5.9l-3.1,2.9l-4,0.3l-3.5,1.5l-3.5-2l-1.4-3.4l-2.9-0.4
            l-3,3l-0.4,5.4l-0.7,5.2l-2.6,3.3l-1.8,4.9l-2.8,2.5L52.4,66.8z" className=""></MarkPath>
      
            <MarkPath active={enteredCity === 'edinet'} id="Edinet" d="M68.2,99.4V99l-0.8-0.4l-2.6-2.1l1.4-1.3l0.8-1.6L67.1,92l-1.4-1.6L64,91.3l-1.1-2.5l-1.6-2.3l-3.1,1.4l-0.3-0.5l-0.2-0.6
          L57.5,86l-0.1-0.8l2.5-1.1v-1.3l-3.2-1.8l-2.9-2.6l-2.1-3.5l-0.9-4.5l0.3-1l1.3-1.7l0.1-0.9l1.1-0.2l2.8-2.5l1.8-4.9l2.6-3.3
          l0.7-5.2l0.4-5.4l3-3l2.9,0.4l1.4,3.4l3.5,2l3.5-1.5l4-0.3l3.1-2.9l-0.9-5.9l2.1-3.9l11.2,10.1l13,1.9l5.2,6.8l1.1,2.5l-0.9,1.9
          l-3,5.1l1.9,5l0.1,4.4l1.1,4.7l0.1,1.8l-9.8,7.2l-4.8,1.7l-2.7,0l-2.6-0.3l-2.1,1.7l-3.1-1.5l-2.2-3.3l-3.6,1.1l-3.2,3.8l-1.9,5.3
          l-2.5,4l-3.4,1.4l-3.4,0.4L68.2,99.4z" className=""></MarkPath>
            <MarkPath active={enteredCity === 'balti'} id="Rascani" d="M81.6,134.2l-0.1-0.3l-0.6-0.6l-2.2-1.6l-0.5-0.8l-0.6-1.5l-1.4-2.7l-1.7-2.6l-1.4-1.2l4.2-2.6l-0.9-1.5l-1.3-0.7l-1.5-0.4
          l-1.3-1l0.1-1.1l-0.2-5.6l0.1-0.8l-2.2-2.6l-2.8-1.6l-1.3-1.7l2.1-2.9v-0.8l1-0.1l3.4-0.4l3.4-1.4l2.5-4l1.9-5.3l3.2-3.8l3.6-1.1
          l2.2,3.3l3.1,1.5l2.1-1.7l2.6,0.3l2.7,0l4.8-1.7l9.8-7.2l-0.1-1.8l-1.1-4.7l-0.1-4.4l4,2.7l3.1,4.9l5.2,1.2l5,3.1l-0.6,6.2l-1,1.3
          l-0.2,1.6l0.7,1.7l0.1,2l-0.5,1.2l-0.4,1.6l1.5,2.9l2.2,1.9l4.4,2.4l4-2.2l2.9-3.9l3.6-0.2l7.7,9.7l2.5,2l0.4,3.4l1.1,5l3.6,0.9
          l0.2,5.8l-6.2,3.2l-6.5,2.5l-4-1.8l-6.5-7.6l-4.2-0.3l-3.6,2.1l-4.1,0.4l-11.1-6.5l-5.6-1.4l-5.4,0.5l-3.4,3.9l-4.1-0.3l-4.9,1.2
          l-4.1,5l-0.6,3.5l-1.1,3.3l-2,1.9l-2.2,1.5L81.6,134.2z"></MarkPath>
            <MarkPath active={enteredCity === 'balti'} id="Falesti" d="M98.8,165.4l-0.1-0.5l-3.8-1.5l-3.4-3.5l-7.9-10.5l-2.8-4.8l-1.1-4l2.8-0.6v-1.1l-0.7-1.3l0.2-1.9l-0.4-1.5l1.3-0.6
          l2.2-1.5l2-1.9l1.1-3.3l0.6-3.5l4.1-5l4.9-1.2l4.1,0.3l3.4-3.9l5.4-0.5l5.6,1.4l11.1,6.5l4.1-0.4l3.6-2.1l4.2,0.3l6.5,7.6l4,1.8
          l1.2,2.7l0.2,3.4l-3.2-1.2l-2.6-3l-2,0.6l-1.2,4.3l-2.9,3.8l-3.6,2l-1.9-1L132,140l-3,5.5l-1.5,1.6l-1.6,1.4l-1.7,4.8l-4.5,1.6
          l-4.7-0.4l-8.5,4L98.8,165.4z"></MarkPath>
            <MarkPath active={enteredCity === 'balti'} id="Glodeni" d="M99.1,166.3l-0.2-1l7.6-6.9l8.5-4l4.7,0.4l4.5-1.6l1.7-4.8l1.6-1.4l1.5-1.6l3-5.5l1.9-0.2l1.9,1l3.6-2l2.9-3.8l1.2-4.3
          l2-0.6l2.6,3l3.2,1.2l5.4,2.2l7.4,0.4l-2.3,5.2l-0.5,5.7l7.4,0.5l1.8,9.7l-0.2,3.5l-3.1,1.1l-1.7,1.8l-1.4,2.4l-4.1,3l-0.3,4.3
          l3.8,1.9l3.3,0l2.2,3.1l-1,4.3l-2.7,2.9l-3-0.9l-3,0.2l-1.6,1.1l0.2,4.2l-1.6,1l-8.4,1l-8.2-0.2l-8,1.4l-2.2,2.9l-7.2,2.8l-3.2,2.9
          l-1,0.8l-0.3-0.2l-1.7-1.2l1-5.5l-2.2-1.9l0.4-2.1l0.5-1.9l0.7-1.4l1-0.8l-0.9-1.8l-1.5-1.4l-1.8-1.1l-1.7-0.7l-0.2,0.1l-3.1-0.1
          l-0.7-0.4l-0.5-0.4l-0.6-0.4l-1.1-0.2l-1.3-1.1l-0.8-2.6l-0.9-4.8l-0.3-0.1l-1.3-0.2l-0.5-0.3l-0.3-0.8l0-1.6l-0.2-0.7l-0.4-1.1
          L99.1,166.3z"></MarkPath>
            <MarkPath active={enteredCity === 'ungheni'} id="Ungheni" d="M173,266.4l-0.1-0.2l-1.1-3l-2.4,1.2l-2.6-1.2l-2.9-1.8l-3-0.6l0-2.6l-1.5-1.4l-3.4-2.3l-3.7-4.2l-1.1-0.8l-1.2-0.4
          l-0.5-0.5l0.7-1.3v-1.2l-5.3-2.9l-1.4-2l1.4-0.4l0.2-0.8l-0.4-1.1l-0.3-1.4l-0.2-0.1l-1.5-4.9l-3.1-4l-1.3-2.3l0.2-2.2l-2.5-4.3
          l-1.3-1.4l-3-0.8l-1-0.6l-0.9-0.8l-1.3-0.8l-3.3-0.7l-0.9-0.5l-1.6-2.6l-2.9-7.1l-1.4-1.2l1-0.8l3.2-2.9l7.2-2.8l2.2-2.9l8-1.4
          l8.2,0.2l8.4-1l1.6-1l-0.2-4.2l1.6-1.1l3-0.2l3,0.9l6.4,1.2l2.3,2.7l2.2-0.6l4.7-2.8l5.7,0.4l4.4,3.8l4.7,3.1l-0.1,4.7l-2.2,2.6
          l-3.2,1l-7,5.9l-4.5,2.3l3.6,4.7l5.1,1.4l-4.3,2.5l-3.8,5.2l-4.1,1.6l-3.6,0.1l-3.3,0.7l-2.8,8.1l-1,9.3l0.9,3.9l1.9,2.2l1,0.2
          l0.9,0.6l0.4,2.4l0.1,2.5l1.2,3.8l3.2,0.4l2.2,1.4l-1.6,3.1L173,266.4z" className=""></MarkPath>
            <MarkPath active={enteredCity === 'ungheni'} id="Nisporeni" d="M181.1,283.4l-0.3-2.7l-2.4-6.5l-1.7-3l-3.8-4.8l1.6-1.6l1.6-3.1l-2.2-1.4l-3.2-0.4l-1.2-3.8l-0.1-2.5l-0.4-2.4l-0.9-0.6
          l-1-0.2l-1.9-2.2l-0.9-3.9l1-9.3l2.8-8.1l3.3-0.7l3.6-0.1l4.1-1.6l7.1,7.8l8.1,4.9l3.4,1.2l3.3,2l3.4,0.7l3.4,0.1l1.5,1.8l1.5,2.7
          l-0.9,2.6l-1.3,2.2l1.8,4.7l3.7,0.5l2.3-1.3l2.4-0.3l1.9,1.5l2.2,0.7l3.6-1.5l2.7,3.3l-1,1.4l-1.2,1.2l-1.8,1.1l-1.3,1.9l3.6,2.7
          l-3.4,7.3l-4.8,7l-3.2-1.6l1.2-5l-1.6-3.5l-6.4-8.8l-1.9,3.6l1.2,2.4l0.8,2.7l-2.1,3l-3,0.7l-5.5,5.6l-4,6.4l-13.3-2.3L181.1,283.4
          z"></MarkPath>
            <MarkPath active={enteredCity === 'hancesti'} id="Hancesti" d="M183.3,297.3l0.2-2.1v-1.3l-1.4-1.7l0.2-2.1l-0.1-2l-2-1.4l1-2.7l-0.1-0.4l1.1,0.3l13.3,2.3l4-6.4l5.5-5.6l3-0.7l2.1-3
          l-0.8-2.7l-1.2-2.4l1.9-3.6l6.4,8.8l1.6,3.5l-1.2,5l3.2,1.6l4.8-7l3.4-7.3l-3.6-2.7l1.3-1.9l1.8-1.1l3.9,4.1l4.1,2.7l-0.4,3.2
          l-0.9,2.9l5.6,6.3l6.1,5.2l1.4,1.7l0.5,2.6l0.1,2.6l0.8,2.2l2.2-1.5l1.9-2.8l3.6,3.7l1.4,7.4l-1,3.2l0.6,3.4l-3.6,1.2l-3.4,2.2
          l-3.1,2.6l-3.5,1.6l-1.7,7.9l-2.8,5.5l-0.9-7l-4.1-1.7l-4.7,3l-4.3,0.9l-4-3.8l-4.5-1.2l-0.7,2.2l-0.7,2.4l-1.9,0.1l-1.4,1.1
          l2.4,3.4l-0.4,3.4l0.3,1.9l0.9,2.2l-1.1,1.4l-6.2,1.5l-2.7-1.3l-1-3.9l-3.9,1l-2.2,0.9l-0.1-0.7l-1.2-4.8l-6.8-13.7l0-3.3l-4.9-8.5
          l-2-5L183.3,297.3z"></MarkPath>
            <MarkPath active={enteredCity === 'hancesti'} id="Leova" d="M195.9,376.5l0.2-0.1l0.6-1l-0.9-2.4l1.7-0.9l0.7-1.3l0.1-1.5l0-5.5l-0.2-1.5l-0.4-0.2l-0.5,0.2l-0.5-0.4l-1.6-3.1
          l-0.3-1.4l0.3-6.6l0.4-1.3l1.1-0.8l-1.1-2.9l0.6-2.5l1.2-2.2l0.8-1.9l0-2.4l-0.3-1.9l2.2-0.9l3.9-1l1,3.9l2.7,1.3l6.2-1.5l1.1-1.4
          l-0.9-2.2l-0.3-1.9l0.4-3.4l-2.4-3.4l1.4-1.1l1.9-0.1l0.7-2.4l0.7-2.2l4.5,1.2l4,3.8l4.3-0.9l4.7-3l4.1,1.7l0.9,7l0.3,3.8l2,2.4
          l-0.6,2l-0.9,1.9l-0.3,2.5l0,2.7l-1.7,2.5l0.7,2l1.2,4.3l-0.4,5.3l-1.2,2.9l-1.9,1.7l-1.7,0.3l-0.8,1.9l-8.2,6.6l-2.6-1.1l-2.6-0.4
          l-1.7,1.5l-0.6,3.2l-4.1,3.5l-4.7-2l-0.1-1.9l0.1-1.8l-2.1-0.5l-2.2,1.2l-2.6,0.9l-1.6,2.7l-2.2,1.5l-2.4-0.8L195.9,376.5z"></MarkPath>
            <MarkPath active={enteredCity === 'cahul'} id="Cantemir" d="M181.8,428.3l2.1-5.9l0.7-4.3l1.4-4.1l-0.4-1.5l-0.7-1.2l-0.8-1l-2.2-3.7l-0.2-1.5l1.4-0.7l1.4-0.4l0.3-1l-0.4-3l0.5-1.2
          l1.2-0.6l1.4-0.4l1.2-0.6l1.5-1.4l1.6-3.7l0.2-0.6l-0.1-2.2l-0.5-3.6l0.3-1.3l1.5-0.5l0.6-0.7l0.7-5.4l0.8-0.8l0.9-0.4l0.3,0.4
          l2.4,0.8l2.2-1.5l1.6-2.7l2.6-0.9l2.2-1.2l2.1,0.5l-0.1,1.8l0.1,1.9l4.7,2l4.1-3.5l0.6-3.2l1.7-1.5l2.6,0.4l2.6,1.1l0.6,3.7
          l-2.2,2.8l0.8,3.2l5.5,4.2l0.3,3.8l-1.4,5.1l-1,5.4l1,5.3l-0.4,5l-3.1,3.8l-3.5,3.1l-1.7,3l1.7,2.8l0.4,3.3l-0.7,3.5l-5.7,2.1
          l-5.9-0.1l-4.8-3.6l-4.8,0.1l-1.4,2.9l-1.9,2.3l-4.6-2.2l-5.7-0.3L181.8,428.3z"></MarkPath>
            <MarkPath active={enteredCity === 'cahul'} id="Cahul" d="M181.5,429.2l0.3-0.9l5.4,1.9l5.7,0.3l4.6,2.2l1.9-2.3l1.4-2.9l4.8-0.1l4.8,3.6l5.9,0.1l5.7-2.1l0.8,1.4l1.4,0.1l-0.8,6.6
          l-1.6,6.8l-0.2,5.6l0.9,5.3l1.7,1.9l1.1,2.5l-1.4,2.8l-2.3,1.5l-3.7,1.3l-1.9,4.6l-1.4,5.1l-6.6,7.8l-2.9,5.5l-0.6,8.8l5,6.9
          l7.7,1.5l7.2-6l3.4-4.4l1.6-5.6l-0.2-5.1l1.9-3.4l3.1,4.5l1.1,6.9l1.6,0.3l0.1,0l0.5,1.9l-0.8,1.4l-3.5,1.1l-2.1,1.4l-1.3,1.5
          l-2.8,4.2l-0.8,2l1.1,1.3l1.9,1.1l1.6,1.6l0.7,2.4l0.2,2.4l0.5,2.4l1.7,2.3l-3.2,1.5l-0.7,0.7l-0.1,0.2l-0.1,0l-0.2-0.1l-9.2-0.1
          l-9-3.8l-8.7,0.6l-1.1,9.2l-0.1,1.8l0,0l-6.5,4.9l0,0l-1.1,2l-0.8,2.2l-0.3,1.2l-3.2-3.9l-0.9-1.8l-0.1-5.9l-0.2-0.4l-0.3-0.4
          l-0.5-1.1l-2-3.7l-2.8-2.2l-6.8-3.6l1.5-2l2-1.9l2-1.5l1.6-0.6l4,0l1.7-0.9l-0.8-2.3l0.2-2.8l-1.1-17.5l-1-1.6L184,482l-1.9-5.3
          l-0.3-5.1l0.5-0.9l1.6-1.2l0.4-0.9L184,468l-1.1-2.2l-0.3-1.3l0.2-1.4l0.5-1.3l0.3-1.3L183,459l-0.7-1.2l-0.3-1l-0.2-2l-3-8.8
          l-0.4-2.5l0.2-1.7l1.5-6.2l0-0.9l-0.7-0.5l0-0.9l0.3-0.9l1-0.6L181.5,429.2z"></MarkPath>
            <MarkPath active={enteredCity === 'edinet'} id="Ocnita" d="M154.4,19.7L154.4,19.7l-2.9,2l-6.5,3.2l-12.9-1.1l-6,1.5l-7.6-1.5l-2,3.2l-1.4,4.7l-2.2,3.3l-2.6,7.5l-1.6,3.2l-13-1.9
          L84.5,33.6l-5.1-5.2l-2-8.1l-0.2-0.6l6.6-8.3l4.4-2.3l2.3,0.6L94,13l1.7,0.6l2-1l7.4-6.3l2.8-3.8h5l1.4-0.4l3.2-2.1l2.7,0.4
          l0.3,4.8l2.5,1.2l15.2-0.1l4.1,1.9L150,14l1.8,2L154.4,19.7z"></MarkPath>
            <MarkPath active={enteredCity === 'edinet'} id="Donduseni" d="M154.4,19.7l1.4,2.1l1,0.9l2.7,4.2l4.9,1.9l4.4,0.5l0,0.1l1.6,7.7l0,7.8l-5.1,2.3l-2.7,6l-4.3,1l-4-2.5l-3.7-1l-0.2,3.5
          l2.9,1.4l0.3,2.8l0.5,2.2l1.2,1.3l-0.2,2.9l-4.3,0.9l-4.8-0.8L141,66l-2,2.6l-0.7,3.4l-2.3,1.5l-2.1,1.9l0,2l0.7,1.8l-1.7,0.8
          l-2.5-1.1l-5-3.1l-5.2-1.2l-3.1-4.9l-4-2.7l-1.9-5l3-5.1l0.9-1.9l-1.1-2.5l-5.2-6.8l1.6-3.2l2.6-7.5l2.2-3.3l1.4-4.7l2-3.2l7.6,1.5
          l6-1.5l12.9,1.1l6.5-3.2L154.4,19.7L154.4,19.7z"></MarkPath>
            <MarkPath active={enteredCity === 'soroca'} id="Soroca" d="M168.7,29.4l6.4,0.8l2.5,1.2l2,2.4l0.7,3.3l-2.4,7.2l1.8,1.4l2.7,0.1l1.9-0.5l3.8-3.2l2-0.3l0.9,3l0.4,3.5l1.3,2.1l2.1,0.7
          l2.8-0.6l2.6-1.5l1.9-1.7l2.1-1.3l5.6-0.7l2.1,0.3l1.3,1.5l0.2,3.5l-0.8,3.8l-1.4,1.7l-1.8,1.2l-1.9,2.2l-0.8,2.5l0.2,2.5l1.4,1.5
          l2.6-0.5l1.9-2.2l1.3-2.8l1.6-2.2l2.8-0.4l1.9,1.3l1.2,2.5l-0.2,2.8l-2,2.3l3.5,7.2l1.5,1.5l2.5,1.7L228,77l0.5-1.7l0-3.1l0.7-3.1
          l0.1,0l5.2,3.8l-3,6.5l-4.6,3.5l-1.8,6.6l-3.6,1.9l-3.5-2.4l-2.4,1.3l-2.4,0.7l-5.8-2.4l-2.1-1.5l-2.1-0.9l-0.9,1.6l-0.8,1.9
          l-3.9,0.9l-4.1-0.4h-8.5l-8.4,1.5l-5.1-3.5l1.3-3.1l3.2-5.9l3.2-1.5l2.5-2.1l-1.4-7.8l-4.6-5.7l-13.3-8.8l2.7-6l5.1-2.3l0-7.8
          l-1.6-7.7L168.7,29.4z"></MarkPath>
            <MarkPath active={enteredCity === 'transnistria'} id="SRL Intereconomservice" d="M229.2,69l0.2-0.7l2.2-6.6l2.7-1.2l3.9,0.2l11.2,4.7l8.5,0.1l4.4,0.8l3.4,2.3l0.8,1.5l0.3,1.3l0.1,1.2l0.3,1.3l2,4.7
          l0.4,4.8l0.2,1.1l0.8,1.2l2,1.9l0.8,1.4l2.5,4.3l3.9,4.3l2.6,2l-0.6,3l-0.1,5.6l0.8,0.6l0.7,1.5l-9.9-3l-2.4-2l-1.5-1.2l0.2-4.4
          l-2.1-4.4l-3.4-3.1l0,0l0,0.1l-1,1.5l-0.7,0.6l-1.8,2.5l-2.1,0.8l-2.2,0l-4.5,3.4l-3.1,6.4l-0.1,3.9l1.5,3.5l-3.9,2.9l-4.4-3.8
          l-3.8-4.4l-4.7-1.1l-3.2,1.7l-3.2-0.9l2.2-4.9l1.1-7.9l-1.1-5.3l-3.6-1.7l1.8-6.6l4.6-3.5l3-6.5l-5.2-3.8L229.2,69z"></MarkPath>
            <MarkPath active={enteredCity === 'transnistria'} id="Rabnita" d="M282.3,99.4l1.7,1.3l3.7,1.5l4.4-0.9l5.3-7l3.9-1.9l2.1,0.1l1.7,0.7l1.5,1.4l1.4,2.3l1,2.8l1.1,6.2l1,2.7l1.7,1.3l4.8,1.5
          l1.2,0.9l-0.4,2.5l-1.9,2.6l-2.3,2.4l-1.5,2l-1.1,3.2l0,2l1.2,1.2l4.2,1.5l1.6,1.5l0.5,2l-1.5,2.3l-3.6,4.7l-0.7,5.7l0.1,6.4
          l-1.3,6.8l-3.2,5.5l-3.1,4.2l-1.6,4.7l1.6,7.2l0,0l0,0l0,0l0.8,1.6l0,0.1l-0.1,0.8l-1.1,2.5l-2.3-0.7l0,0l-0.5-1.6l-1.3-1.6l0,0
          l-5.3-3.4l0,0l-1.9-2.2l-0.8-2l-0.3-0.6l-0.4-3.1l-0.1-3.8l-0.6-2l-2.8-4.1l-0.8-3.2l0.3-0.9l0.5-2.1l0.2-2.2l-0.6-1l-0.3-0.8
          l-0.9-4.8l-0.6-10.1l0.6-4l4.5-11.1l0.1-4.8l-4.6-3.9l0,0l-4.6-1.4l-0.7-1.5l-0.8-0.6l0.1-5.6L282.3,99.4z"></MarkPath>
            <MarkPath active={enteredCity === 'transnistria'} id="Dubasari" d="M306.8,182.3l0.3,0.7l1.9,5.4l1,1.9l2.2,1.7l1.3-1.1l1.2-1.9l2-0.9l1.7,0.9l2.2,3.8l3.7,2.5l0.8,1.9l0.5,2.1l1,1.9l1.7,1
          l1.9,0.4l1.9,0.8l1.6,2.4l0.6,2.8l0,2.6l0.4,2.6l0.4,0.7l0,0l-0.1,0.1l-2.1,3l0.3,1.8l0.1,2.4l-1,2.1l-1.4,1.6l-4,3.8l-3.2,4.6
          l2.2,8.3l0.2,1.1l0.3,1.1l-0.8,0.3l0,0l-0.2-0.8l0,0l-1.6-0.4l-1.7-1l-0.1,0l-1.7-1.8l0,0l-1.6-0.7l-1.2,2.4H317h0l-0.9-1.6l0,0
          l-1.5-0.9l-1.5,0l-1.1,1.2l0.1,1.9l1,1.9l0.7,2l-0.6,2.2l-1.2,0.4l-1.5-0.6l-1.3-0.2l-1,3.1l-2.2,2.6l-0.7,1.4l-0.7-0.7l-2.5-2.5
          l0,0l-1.7-1.2l-1.8-0.5l-0.1,0l1.1-2.7l1.9-3.2l2.2-2.7l4-2.3l2.3-5.2l1.9-0.9l-1.9-6l0,0l3.2-5.7l-1.8-5l-5,2l-1,0.9l0,0l3.1,5.3
          l-5,2l-4.4-2.3l0.4-4.2l-0.7-4.7l-6-6.7l-2.3-3.2l-2.6-2.2l2.1-2.4l1.1-3.6l2.1-0.8l3.2,1.9l1.7-2.1l2.1-0.8l0.9-5.6l2.1-3l0.4-1.8
          l-0.3-1l0,0l2.3,0.7l1.1-2.5L306.8,182.3L306.8,182.3z"></MarkPath>
            <MarkPath active={enteredCity === 'transnistria'} id="Dubasari" d="M335.3,214.6l1.2,2.1l1.7,1.5l1.8,0.9l2,0.2l2-0.6l2.1-2l0.8-2.5l0.6-2.7l1.2-2.8l2.5-0.3l2.4,0.2l2.3,0.8l2,1.8l1.6,3.1
          l1.1,4.2l0.1,4.2l-1.4,3.1l-3,1.6l-0.4,3.9l1.2,9.4l-0.7,4.4l-1.8,2.1l-2.6,0.8l-3.4,0.3l-0.3,0.5l-0.1,0.6l0.1,0.5l0.3,0.6l3.7,7
          l1.1,1.1l1.3-1.1l1.1-2.3l1.4-2l2.3-0.2l1.6,1.1l1.4,1.9l0.9,2.4l0.1,2.7l-0.9,2.4l-1.5,1.7l-1.3,2l-0.9,6.7l-0.5,2.4l-0.1,0
          l-9.9,0.3l-2,1.5l0,0l-0.2-0.4l0-0.1l-2.9-0.7l-2.4,0.9l-2.3,1.8l-2.6,1.4l-3.2-0.3l-1.7-1.2l-0.4-0.3l-2-2.4l-1.6-2.9l-1-3.1
          l1.7,0.7h1.9l2-0.6l3.1-2l0.5,0l0.3-0.3l0.6-1.4l0-0.1l-0.5,0.2l0,0l-0.5-1.2l-0.6-2.7h0.8l1.1,0.7l0.1,0.1l0.2-2.3l-0.6-5.2
          l-0.8-1.4l-0.9-0.9l-4.2-4.3l0,0l-3-1.5l-0.7-0.6l-0.4-0.4l-0.5-1.2l0.2-1.2l0-0.1l0,0l0.8-0.3l-0.3-1.1l-0.2-1.1l-2.2-8.3l3.2-4.6
          l4-3.8l1.4-1.6l1-2.1l-0.1-2.4l-0.3-1.8l2.1-3L335.3,214.6L335.3,214.6z"></MarkPath>
            <MarkPath active={enteredCity === 'transnistria'} id="Tiraspol" d="M358.4,276.1l-0.1,0.4l-0.1,2.3l1.1,2l0.7,0.2l3.1-0.3l1.1,0.6l1.9,2.2l1,0.9l2,0.7l4,0.3l1.9,0.7l2,2l2.8,4.7l2.2,1.6
          l3.9,0.6l3.6-0.4l3.5,0.4l3.5,2.9l0.6,1.2l1,3.2l0.6,1l1.1,0.1l1.1-0.8l1.2-0.5l1.3,0.7l0.3,1.2l0,3.8l0.2,1.6l0.5,1.3l1.3,2.6
          l0.5,1.4l0.2,3.3l-1,11l-0.5,2.1l-0.6,1.6l-0.4,1.7l-0.1,2.3l0.4,1.8l1.5,3.6l0.3,1.7l-1.4,3.8l-2.7,0.5l-2.1,0.2l-1-0.9l-2.5-3.3
          l-0.3-0.7l0-0.1l-1.8,0l-1.5-0.3l-1.2-1.1l-0.8-2.2l0-0.1l-4.5,3.2l-1.3,0.4l-4-2.2l-1.7-0.2l-0.1,0l0.9-2.3l1.5-1.8l1.7-1.3
          l1.7-0.8l-2.1-5.3l-1.1-1.2l-0.1-0.1l-2.6-0.8l-0.9,0.3l-1.1,0.6h-0.9h0l-0.4-1.5l-0.5-0.4l0,0l-1.2-0.2l-2.2,0.1l-0.1,0l-1.8-2.9
          l-4.3-1.1l-2.6-2.5l-3-1.9l-2-7.7l-1.4-1.7l-1.1-1.3l0-0.1l-0.2,0.2l-2.3-0.8l0,0l-1.1-1.6l-1,1.1l-1.5-0.5l-1.6-3.4l-2.3-2.1
          l-1.1-6.3l3.7-3.2l0.6-2.2l-0.3-1.7v-4.3l-0.4-3.3l-1.1-1.7l0,0l2-1.5L358.4,276.1L358.4,276.1z"></MarkPath>
            <MarkPath active={enteredCity === 'tighina'} id="Ștefan Vodă" d="M399.6,348.6l0.4,3.9l1.7,2.1l5.4,2.3l0.2,0.4l0.6,1.7l0.4,0.4l0.8-0.3l1.5-1l0.7,0l1,0.8l0.8,1.1l1.5,3.1l-0.9,1.5
          l5.6,4.5l2.1,2.5l1.2-1.1l1.8-0.3l2,0.4l1.6,1l-3,5.5l-3.2,3.1l-5.3,0.9l-14.4-0.8l-2,0.4l-2.2,1.2l-4.5,4l-2.4,0.4l-2.4-2.7
          l-0.2-1.2l0.1-3.4l-0.2-1.6l-0.6-1.5l-2.5-4l-6.4-6.1l-1.6-2.7l0,5l-1.3,2.6l-2.5,1l-3.4,0l-0.8,1.1l0.6,4.3l-0.9,2.9l-1.6,1.7
          l-2,0.7l-2.1-0.2l-1.9-1.2l-1.6-2.2l-1.7-3.9l-1.7-1.6l-1.7-0.6l-3.8-0.7l-1.8-0.8L348,370l-0.2-1.3l0.2-1.4l0.7-1.4l0.7-1.2
          l0.2-1.2l-0.2-1.2l-0.7-1.1l-2.8-0.5l-0.1,0.1l0-0.1l-0.3-2.1l-1.3-4.4l-2.7-3.4l-0.5-3.3l1.4-3.2l0-2.5l0.2-2.5l3.1-3l3.4-2.2
          l2.1,0.6l2.1,0.4l1.6-2.9l1.3-2.9l2,0l1.8-0.6l0.6-5l-1-5.2l3,1.9l2.6,2.5l4.3,1.1l1.8,2.9l0.1,0l2.2-0.1l1.2,0.2l0,0l0.5,0.4
          l0.4,1.5h0h0.9l1.1-0.6l0.9-0.3l2.6,0.8l0.1,0.1l1.1,1.2l2.1,5.3l-1.7,0.8l-1.7,1.3l-1.5,1.8l-0.9,2.3l0.1,0l1.7,0.2l4,2.2l1.3-0.4
          l4.5-3.2l0,0.1l0.8,2.2l1.2,1.1l1.5,0.3l1.8,0l0,0.1l0.3,0.7l2.5,3.3L399.6,348.6z"></MarkPath>
            <MarkPath active={enteredCity === 'tighina'} id="Căușeni" d="M345.7,360.8l-2.4,1.4l-2.3,2.5l-5.3,8.2l-1.5,0l-2.2-3.2l-2.9-6.1l-1.7-0.6l-2.1,3.6l-0.4,2.1l0.6,3.6l-0.1,1.6l-0.8,2.3
          l-0.6,0.3l-0.8-0.3l-1.5,0.5l-4.4,4.9l-2.7,1.7l-2.1-1.8l0-1.8l0.8-1.7l1.1-1.6l0.8-1.7l0.2-2.1l-0.1-15l-0.8-3.8l-1.9-2.5l-0.1,0
          v0l0-4.6l-1.9-6l-5.1-4.5l-6.4-2.3l-6.4-10.1l-3.7-8.6l-2.2-10.9l3.3-1.6l3.5-0.9l3.9-2.8l3.6-3.3l1.7,1.2l-0.8,2.6l-0.3,1.7
          l1.4,1.7l7.6,2l0.6,4.6l2.5,1.1l2.9-2.6l2.6,3.3l3.3-0.2l3.1,0.3l0.5-3.9l0.8-2.1l1.5,1.5l1.7,0.2l3.3-5.3l2.6-0.7l2.6,0.4l3.1-0.2
          l3.2-0.7l2.3,2.1l1.6,3.4l1.5,0.5l1-1.1l1.1,1.6l0,0l2.3,0.8l0.2-0.2l0,0.1l1.1,1.3l1.4,1.7l2,7.7l1,5.2l-0.6,5l-1.8,0.6l-2,0
          l-1.3,2.9l-1.6,2.9l-2.1-0.4L349,334l-3.4,2.2l-3.1,3l-0.2,2.5l0,2.5l-1.4,3.2l0.5,3.3l2.7,3.4l1.3,4.4L345.7,360.8L345.7,360.8z"></MarkPath>
            <MarkPath active={enteredCity === 'hancesti'} id="Cimislia" d="M312.4,351.3l-2.5,0l-10.7,6.1l-2.3,0.9l0-0.1l0.4-3.6l0.6-4.7l-2.3-1.7l-8.7-0.4l-2.7,0.5l-2.5,1.6l-1.7,2.9l-4.1,3.6
          l-1.1,2.8l-1.8,2.2l-2.6,0.4l-3.6,2.4l0.1,4.9l-1.5,2.8l-4.1,0.7l-5.1-4l-11.1-6.9l-5.4-4.2l0.4-5.3l-1.2-4.3l-0.7-2l1.7-2.5l0-2.7
          l0.3-2.5l0.9-1.9l0.6-2l-2-2.4l-0.3-3.8l2.8-5.5l1.7-7.9l3.5-1.6l3.1-2.6l3.4-2.2l3.6-1.2l-0.6-3.4l1-3.2l5.4,4.9l5.5,0.2l2.9-0.3
          l2.9,0.5l2.1-1.9l2-1.3l4,3.5l0.8,0.5l0.8,0l2.3-2.6l2.2,10.9l3.7,8.6l6.4,10.1l6.4,2.3l5.1,4.5l1.9,6L312.4,351.3L312.4,351.3z"></MarkPath>
            <MarkPath active={enteredCity === 'hancesti'} id="Basarabeasca" d="M296.9,358.3l-4.3,1.6l-9.1,6.1l-2.5,3.9l-0.8,5l1,6.5l2.1,8.3l0.1,2.8l-0.7,3.2l-0.9,2.5l0.1,2.4l2.1,2.8l0,0l4.1,3.8
          l-2.6,4.1l-5.7,5.5l-0.7-4.3l0.5-4.8l-3.8-3.1l-4.7,1.3l-2.5-1.1l-1.9-2.5l0.2-2.2l0.4-2l-1.2-4.1l-3.4-0.9l-1.8-3.4l-2.8-0.4
          l-1.5,4.2l-2.6-0.1l1.4-5l3.4-3l1.8-6.1l0.6-6.7l4.1-0.7l1.5-2.8l-0.1-4.9l3.6-2.4l2.6-0.4l1.8-2.2l1.1-2.8l4.1-3.6l1.7-2.9
          l2.5-1.6l2.7-0.5l8.7,0.4l2.3,1.7l-0.6,4.7L296.9,358.3L296.9,358.3z"></MarkPath>
            <MarkPath active={enteredCity === 'cahul'} id="Taraclia" d="M288.2,407.2l3.1,3l0.7,3.8l-1.4,4.2l-2.8,4.7l-4.2,4.7l-0.9,2.8l0.3,4.3l2.2,7.7l-0.3,3.4l-3,1.4l-21.2,5.6l-2.1,1.4
          l-1.3,2.6l-0.1,2.9l1.7,6.1l0.5,2.9l-0.2,3.5l-0.8,2.3l-1.5,1.5l-2.3,1.3l-3.5,0.8l-0.9,0.8l0,1l0.3,1.6l0.2,1.8l-0.4,1.7l-3.2,1.9
          l-8.1,0.8l-2,3.3l0.4,1.3l-0.1,0l-1.6-0.3l-1.1-6.9l-3.1-4.5l-4-3.3l-4.9-0.8l-2.8-4.1l-3.4-2.8l1.9-4.6l3.7-1.3l2.3-1.5l1.4-2.8
          l-1.1-2.5l-1.7-1.9l-0.9-5.3l0.2-5.6l1.6-6.8l0.8-6.6l4.7-0.2l4.6,1.3l4.5,3l3.6,3.8l-1.6,2.9l0.4,2.5l0.8,2.3l3.1,5.7l5-0.2
          l20.1-7.3l7.2-11.8l3.5-15.8l5.7-5.5L288.2,407.2z"></MarkPath>
            <MarkPath active={enteredCity === 'gagauzia'} id="Gagauzia" d="M229.7,521.9l-0.7,1.3l0,0.4l0.3,0.3l0.8,5.9l-0.5,2.1l-2.6,1.2l-7.8-0.3l-9.1-2.4l-8.7-0.7l0,0l0,0l0.1-1.8l1.1-9.2
          l8.7-0.6l9,3.8l9.2,0.1L229.7,521.9L229.7,521.9z M239.6,357.6l5.4,4.2l11.1,6.9l5.1,4l-0.6,6.7l-1.8,6.1l-3.4,3l-1.4,5l2.6,0.1
          l1.5-4.2l2.8,0.4l1.8,3.4l3.4,0.9l1.2,4.1l-0.4,2l-0.2,2.2l1.9,2.5l2.5,1.1l4.7-1.3l3.8,3.1l-0.5,4.8l0.7,4.3l-3.5,15.8l-7.2,11.8
          l-20.1,7.3l-5,0.2l-3.1-5.7l-0.8-2.3l-0.4-2.5l1.6-2.9l-3.6-3.8l-4.5-3l-4.6-1.3l-4.7,0.2l-1.4-0.1l-0.8-1.4l0.7-3.5l-0.4-3.3
          l-1.7-2.8l1.7-3l3.5-3.1l3.1-3.8l0.4-5l-1-5.3l1-5.4l1.4-5.1l-0.3-3.8l-5.5-4.2l-0.8-3.2l2.2-2.8l-0.6-3.7l8.2-6.6l0.8-1.9l1.7-0.3
          l1.9-1.7L239.6,357.6z M231,480.6l-1.9,3.4l0.2,5.1l-1.6,5.6l-3.4,4.4l-7.2,6l-7.7-1.5l-5-6.9l0.6-8.8l2.9-5.5l6.6-7.8l1.4-5.1
          l3.4,2.8l2.8,4.1l4.9,0.8L231,480.6z"></MarkPath>
            <MarkPath d="M270.9,252.1l-2.9-4.6l-4.2-3.1l-0.7-5.4l1.4-6l2-1.4l-0.5-5.1l3.1-4.7l4.7,0.8l5-2.8l4.6-4.1l5.1-1.9l4.3-4.2l6,6.7
          l0.7,4.7l-0.4,4.2l4.4,2.3l5-2l1.5,2.5l1.9,6l-1.9,0.9l-2.3,5.2l-4,2.3l-2.2,2.7l-1.9,3.2l-1.1,2.7l0.1,0l1.8,0.5l1.7,1.2l0,0
          l2.5,2.5l0.7,0.7l0.7-1.4l2.2-2.6l1-3.1l1.3,0.2l1.5,0.6l1.2-0.4l0.6-2.2l-0.7-2l-1-1.9l-0.1-1.9l1.1-1.2l1.5,0l1.5,0.9l0,0
          l0.9,1.6h0h0.7l1.2-2.4l1.6,0.7l0,0l1.7,1.8l0.1,0l1.7,1l1.6,0.4l0,0l0.2,0.8l-2.7,1.2l-2,4.2l-0.6,7.3l0,7.3l0.6,5.1l-1.7,3.3
          l-3.7-1.8l-3.4-2.5l-4.1,1.6l-4,0.8l-3.5-9l-3.7-0.9l-3.5,2.1l-2.4,4.1l-2,0l-2.1-0.5l0.9-5.9l-1.8-2.9l-0.9,3.4l-1.3,2.4l-5.5-7.8
          l-4.4-0.3L270.9,252.1z"></MarkPath>
            <MarkPath active={enteredCity === 'chisinau'} id="Straseni" d="M264.5,232.9l-1.4,6l0.7,5.4l4.2,3.1l2.9,4.6l-5.4,2.6l-5.6-1.1l-5.3-6l-5.8-3.4l0.1,5.2l8.9,8.3l4.4,1.8l-2.7,6.6l-5,0.3
          l-2.9-2l-3.1-1.2l-2.1,1.6l-1.7,2.2l-4.6-3l-3-5.9l-3.5,0.2l-3.9-0.4l-2.7-3.3l-3.6,1.5l-2.2-0.7l-1.9-1.5l-2.4,0.3l-2.3,1.3
          l-3.7-0.5l-1.8-4.7l1.3-2.2l0.9-2.6l-1.5-2.7l-1.5-1.8l1.9-2.4l5.9-0.6l3.1-1.4l6.1-5.7l2.3-2.9l-0.5-4.4l-2-2.2l-1.1-2.6l7.3-6.7
          l5.5-0.6l2.3-1.7l2.2,1.7l2.2,0.4l2.2-2.1l2.6,0.2l1.4,3.8l1.1,4.3l1.6,4.5l2.3,3.5l3.8-1.3l2.9,0.4l1.5,4.2L264.5,232.9z"></MarkPath>
            <MarkPath active={enteredCity === 'chisinau'} id="Anenii noi"  d="M287,267.3l2.1,0.5l2,0l2.4-4.1l3.5-2.1l3.7,0.9l3.5,9l4-0.8l4.1-1.6l3.4,2.5l3.7,1.8l1.7-3.3l-0.6-5.1l0-7.3l0.6-7.3
          l2-4.2l2.7-1.2l0,0.1l-0.2,1.2l0.5,1.2l0.4,0.4l0.7,0.6l3,1.5l0,0l4.2,4.3l0.9,0.9l0.8,1.4l0.6,5.2l-0.2,2.3l-0.1-0.1l-1.1-0.7
          h-0.8l0.6,2.7l0.5,1.2l0,0l0.5-0.2l0,0.1l-0.6,1.4l-0.3,0.3l-0.5,0l-3.1,2l-2,0.6h-1.9l-1.7-0.7l1,3.1l1.6,2.9l2,2.4l0.4,0.3
          l1.7,1.2l3.2,0.3l2.6-1.4l2.3-1.8l2.4-0.9l2.9,0.7l0,0.1l0.2,0.4l1.1,1.7l0.4,3.3v4.3l0.3,1.7l-0.6,2.2l-3.7,3.2l1.1,6.3l-3.2,0.7
          l-3.1,0.2l-2.6-0.4l-2.6,0.7l-3.3,5.3l-1.7-0.2l-1.5-1.5l-0.8,2.1l-0.5,3.9l-3.1-0.3l-3.3,0.2l-2.6-3.3l-2.9,2.6l-2.5-1.1l-0.6-4.6
          l-7.6-2l-1.4-1.7l0.3-1.7l0.8-2.6l-1.7-1.2l-4.7-1.4l-0.8-3.8l1.9-2.5l0.4-3.4l-2.5-2.2l-3.1-0.7l-2.8-1.1l-2.6-0.7l-1.4,1.6
          l-1.1,2.2l-3.4-1.6l-1.8-5.7l3.1-6.3L287,267.3z"></MarkPath>
            <MarkPath active={enteredCity === 'orhei'} id="Orhei" d="M292.8,209.5l-4.3,4.2l-5.1,1.9l-4.6,4.1l-5,2.8l-4.7-0.8l-3.1,4.7l0.5,5.1l-2,1.4l-1.9-3.7l-1.5-4.2
            l-2.9-0.4l-3.8,1.3l-2.3-3.5l-1.6-4.5l-1.1-4.3l-1.4-3.8l-2.6-0.2l-2.2,2.1l-2.2-0.4l-2.2-1.7l1-4.3l-6.7-4.4l-1.5-6.7l6.9-3.1
            l0.1-2.1l-0.9-1.7l0.2-1.7l1-1.6l0-1.8l0.3-1.8l2.5-2.1l2.8-1.5l10.2-7.4l2.4,0.1l2.3,0.6l2.5,2.4l3,0.7l3.4-1.4l4.8-6.2l3-0.5
            l1.5-1.6l0.9-2.1l3,0.8l3.1,2.6l4,6.3l4.7,2.8l0.8,2l1.9,2.2l0,0l5.3,3.4l0,0l1.3,1.6l0.5,1.6l0.3,1l-0.4,1.8l-2.1,3l-0.9,5.6
            l-2.1,0.8l-1.7,2.1l-3.2-1.9l-2.1,0.8l-1.1,3.6l-2.1,2.4l2.6,2.2L292.8,209.5z"></MarkPath>
            <path d="M310,227.9l-1.5-2.5l-3.1-5.3l0,0l1-0.9l5-2l1.8,5L310,227.9L310,227.9z"></path>
            <MarkPath active={enteredCity === 'chisinau'} id="Chisinau" d="M270.9,252.1l3,4.1l4.4,0.3l5.5,7.8l1.3-2.4l0.9-3.4l1.8,2.9l-0.9,5.9l-5,2.5l-3.1,6.3L267,272l-2.1,0l-1.9-0.8l0.3-1.9
          l1.2-1.8l-0.3-4.7l-1.9-3.2l-4.4-1.8l-8.9-8.3l-0.1-5.2l5.8,3.4l5.3,6l5.6,1.1L270.9,252.1z"></MarkPath>
            <MarkPath active={enteredCity === 'orhei'} id="Telenesti" d="M233.4,133.3l5.9,4.4l6.6,2.5l-3.1,9.1l6.8,3.9l1.6,1.8l1.1,2.8l3.5,2.8l-0.9,4l-0.2,5l-10.2,7.4l-2.8,1.5l-2.5,2.1
          l-0.3,1.8l0,1.8l-1,1.6l-0.2,1.7l0.9,1.7l-0.1,2.1l-6.9,3.1l-3.4-4.1l-4.4,0.5l-4.3,2.9l-4.3,1.1l-1.3-0.8l-1.1-0.1l-3.3,1.4
          l-2,0.1l-1.9-0.4l-5.1-3.5l-4.8,2.6l-4.7-3.1l-4.4-3.8l2.1-4.4l0.3-5.4l-1.3-3.8l1.3-3.6l6.6-3.2l11.6,1.4l4.2-0.9l0.6-1.8l0.5-2.1
          l1.4-2.1l1.7-1.1l2.9-0.5l0.5-3.5l-1.1-5.2l-0.3-5.4l1.9-2.2l2.4-1.2l2.1,1.6l2.1,1.2l3.6-5.2L233.4,133.3z"></MarkPath>
            <MarkPath active={enteredCity === 'orhei'} id="Șoldănești" d="M245.9,140.2l-6.6-2.5l-5.9-4.4l-1.8-6.9l-4.3-2.4l-1.8-1.5l-1.8-0.4l-0.9,1.2l-1,0.9l-1.4-7.3l6.2-7.7l3.2,0.9l3.2-1.7
          l4.7,1.1l3.8,4.4l4.4,3.8l3.9-2.9l-1.5-3.5l0.1-3.9l3.1-6.4l4.5-3.4l2.2,0l2.1-0.8l1.8-2.5l0.7-0.6l1-1.5l0-0.1l0,0l3.4,3.1
          l2.1,4.4l-0.2,4.4l1.5,1.2l2.4,2l9.9,3l0,0l1.6,3.3l0.9,4.2l-2.1-0.8l-2.2-0.6l-1.4,1.8l-0.6,2.7l-8.6-1.4l-2.1,3.1l1.9,0.8
          l0.1,1.6l-3,1.2l-2.9,1.6l-3.1,9.2l-8.9-1.7L245.9,140.2z" className=""></MarkPath>
            <MarkPath active={enteredCity === 'soroca'} id="Floresti" d="M226.5,109.1l-6.2,7.7l1.4,7.3l1-0.9l0.9-1.2l1.8,0.4l1.8,1.5l4.3,2.4l1.8,6.9l-3.5,6.4l-3.6,5.2l-2.1-1.2l-2.1-1.6
          l-2.4,1.2l-1.9,2.2l-3.2-3.4l-3.4-3l-9.3-1.7l0.2-3.8l1.6-4.9l-4.1-0.9l-4.5-0.3l-2.2-3.7l-2.3-4.7l-3.1,1l-2.3,0.2l-0.8-4.2
          l-1.5-0.5l-1.4-0.3l-0.7-1.9l-0.4-2.4l-1.8-2.2l-2-2l1-1.9l0.8-1.8l-0.3-1.7l-0.8-1.3l-1.1-4.2l0.5-4.3l8.4-1.5h8.5l4.1,0.4
          l3.9-0.9l0.8-1.9l0.9-1.6l2.1,0.9l2.1,1.5l5.8,2.4l2.4-0.7l2.4-1.3l3.5,2.4l3.6-1.9l3.6,1.7l1.1,5.3l-1.1,7.9L226.5,109.1z"></MarkPath>
            <MarkPath active={enteredCity === 'orhei'} id="Rezina" d="M254.7,169.6l0.2-5l0.9-4l-3.5-2.8l-1.1-2.8l-1.6-1.8l-6.8-3.9l3.1-9.1l6.8-5l8.9,1.7l3.1-9.2l2.9-1.6l3-1.2l-0.1-1.6
          l-1.9-0.8l2.1-3.1l8.6,1.4l0.6-2.7l1.4-1.8l2.2,0.6l2.1,0.8l-0.9-4.2l-1.6-3.3l0,0l4.6,1.4l0,0l4.6,3.9l-0.1,4.8l-4.5,11.1l-0.6,4
          l0.6,10.1l0.9,4.8l0.3,0.8l0.6,1l-0.2,2.2l-0.5,2.1l-0.3,0.9l0.8,3.2l2.8,4.1l0.6,2l0.1,3.8l0.4,3.1l0.3,0.6l-4.7-2.8l-4-6.3
          l-3.1-2.6l-3-0.8l-0.9,2.1l-1.5,1.6l-3,0.5l-4.8,6.2l-3.4,1.4l-3-0.7l-2.5-2.4l-2.3-0.6L254.7,169.6z"></MarkPath>
            <MarkPath active={enteredCity === 'chisinau'} id="Ialoveni" d="M257.9,300.7l-1.4-7.4l-3.6-3.7l-1.9,2.8l-2.2,1.5l-0.8-2.2L248,289l-0.5-2.6l-1.4-1.7l-6.1-5.2l-5.6-6.3l0.9-2.9l0.4-3.2
          l-4.1-2.7l-3.9-4.1l1.2-1.2l1-1.4l3.9,0.4l3.5-0.2l3,5.9l4.6,3l1.7-2.2l2.1-1.6l3.1,1.2l2.9,2l5-0.3l2.7-6.6l1.9,3.2l0.3,4.7
          l-1.2,1.8l-0.3,1.9l1.9,0.8l2.1,0l11.9,4.1l1.8,5.7l3.4,1.6l1.1-2.2l1.4-1.6l2.6,0.7l2.8,1.1l3.1,0.7l2.5,2.2l-0.4,3.4l-1.9,2.5
          l0.8,3.8l4.7,1.4l-3.6,3.3l-3.9,2.8l-3.5,0.9l-3.3,1.6l-2.3,2.6l-0.8,0l-0.8-0.5l-4-3.5l-2,1.3l-2.1,1.9l-2.9-0.5l-2.9,0.3
          l-5.5-0.2L257.9,300.7z"></MarkPath>
            <MarkPath active={enteredCity === 'ungheni'} id="Călărași" d="M238.9,209.7l-2.3,1.7l-5.5,0.6l-7.3,6.7l1.1,2.6l2,2.2l0.5,4.5l-2.3,2.9l-6.1,5.7l-3.1,1.4l-5.9,0.6l-1.9,2.4l-3.4-0.1
          l-3.4-0.7l-3.3-2l-3.4-1.2l-8.1-4.9l-7.1-7.8l3.8-5.2l4.3-2.5l-5.1-1.4l-3.6-4.7l4.5-2.3l7-5.9l3.2-1l2.2-2.6l0.1-4.7l4.8-2.6
          l5.1,3.5l1.9,0.4l2-0.1l3.3-1.4l1.1,0.1l1.3,0.8l4.3-1.1l4.3-2.9l4.4-0.5l3.4,4.1l1.5,6.7l6.7,4.4L238.9,209.7z"></MarkPath>
            <MarkPath active={enteredCity === 'soroca'} id="Drochia" d="M176.7,106.9l-7.8,8.6l-3.3,0l-3.1,1l-3.6-0.9l-1.1-5l-0.4-3.4l-2.5-2l-7.7-9.7l-3.6,0.2l-2.9,3.9l-4,2.2l-4.4-2.4
          l-2.2-1.9l-1.5-2.9l0.4-1.6l0.5-1.2l-0.1-2l-0.7-1.7l0.2-1.6l1-1.3l0.6-6.2l2.5,1.1l1.7-0.8l-0.7-1.8l0-2l2.1-1.9l2.3-1.5l0.7-3.4
          l2-2.6l4.8-0.9l4.8,0.8l4.3-0.9l0.2-2.9l-1.2-1.3l-0.5-2.2l-0.3-2.8l-2.9-1.4l0.2-3.5l3.7,1l4,2.5l4.3-1l13.3,8.8l4.6,5.7l1.4,7.8
          l-2.5,2.1l-3.2,1.5L173,85l-1.3,3.1l5.1,3.5l-0.5,4.3l1.1,4.2l0.8,1.3l0.3,1.7l-0.8,1.8L176.7,106.9z"></MarkPath>
            <MarkPath active={enteredCity === 'balti'} id="Sîngerei" d="M162.4,116.5l3.1-1l3.3,0l7.8-8.6l2,2l1.8,2.2l0.4,2.4l0.7,1.9l1.4,0.3l1.5,0.5l0.8,4.2l2.3-0.2l3.1-1l2.3,4.7l2.2,3.7
          l4.5,0.3l4.1,0.9l-1.6,4.9l-0.2,3.8l9.3,1.7l3.4,3l3.2,3.4l0.3,5.4l1.1,5.2l-0.5,3.5l-2.9,0.5l-1.7,1.1l-1.4,2.1l-0.5,2.1l-0.6,1.8
          l-4.2,0.9l-11.6-1.4l-6.6,3.2l-1.3,3.6l1.3,3.8l-0.3,5.4l-2.1,4.4l-5.7-0.4l-4.7,2.8l-2.2,0.6l-2.3-2.7l-6.4-1.2l2.7-2.9l1-4.3
          l-2.2-3.1l-3.3,0l-3.8-1.9l0.3-4.3l4.1-3l1.4-2.4l1.7-1.8l3.1-1.1l0.2-3.5l-1.8-9.7l-7.4-0.5l0.5-5.7l2.3-5.2l3.4-0.3l2.3,5.2
          l2.3,0l1.9-1.9l1.1-2.2l-0.6-2.2l-0.7-7l-3.8-2l-4.5,0.8l-2.9-4.9L162.4,116.5z"></MarkPath>
            <MarkPath active={enteredCity === 'balti'} id="Balti" d="M162.6,122.3l2.9,4.9l4.5-0.8l3.8,2l0.7,7l0.6,2.2l-1.1,2.2l-1.9,1.9l-2.3,0l-2.3-5.2l-3.4,0.3l-7.4-0.4l-5.4-2.2l-0.2-3.4
          l-1.2-2.7l6.5-2.5L162.6,122.3z"></MarkPath>
          </g>
        </svg>
      </Container>
    </Wrapper>
  );
};

Map.defaultProps = {
  fullHeight: true,
}

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({fullHeight}) => fullHeight ? '80vh' : '55vh'};
  ${({fullHeight}) => !fullHeight && `
    justify-content: flex-end;
  `}
`;

const Container = styled('div')`
  position: relative;
  max-width: ${({fullWidth}) => fullWidth ? '100%' : '960px'};
  
  svg {
    fill: rgba(255, 255, 255, 0);
    height: 100%;
  }
  
  path {
    stroke: #fda916;
    stroke-width: 1px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: .5s;
  }
`;

const Marks = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Mark = styled('span')`
  position: absolute;
  left: ${({left}) => left}%;
  top: ${({top}) => top}%;
  width: ${({width}) => `${width}px`};
  height: ${({height}) => `${height}px`};
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  
  svg {
    font-size: 32px;
    fill: #4caf50;
    path {
      stroke: #fff;
    }
  }
`;

const MarkPath = styled('path')`
  ${({active}) => active && `
    fill: #0064a8;
  `}
`;

const ModalContainer = styled('div')`
  max-height: 320px;
  overflow: auto;
`;

const Count = styled('span')`
  position: absolute;
  left: ${({left}) => left}%;
  top: ${({top}) => top}%;
  width: 40px;
  height: 40px;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: ${({color}) => color || '#0064a8'};
  color: #fff;
  border-radius: 100%;
  transition: transform 1s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Branches = styled.div`
  position: absolute;
  left: 32px;
  padding: 8px 16px;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  top: 40%;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  
  &:before {
    display: block;
    content: "";
    background: ${({color}) => color};
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 8px;
  }
`;