import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import Alert from '@material-ui/lab/Alert';
import map from 'lodash/map';
import get from 'lodash/get';
import has from 'lodash/has';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import hasIn from 'lodash/hasIn';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import { useTranslation } from 'react-i18next';

import { Delete, EditRounded, GetApp, Visibility } from '@material-ui/icons';

import { Href } from '../../_shared/link/link';

type Props = {
  rows: {}[],
  columns: {
    id: number,
    link?: {
      to: string,
      params: {},
    },
  }[],
  actions? : {
    delete?: Function,
    edit: string,
    subsidyId?: number,
  },
};

export const GridList = ({ columns, rows, actions }: Props) => {
  const { t } = useTranslation('');
  
  const toLink = useCallback((col, row) => {
    let linkParams;
    const params = get(col, 'link.params', {});
    linkParams = reduce(
      keys(params),
      (acc, item) => ({ ...acc, [item]: get(row, get(params, item)) }),
      {}
    );
    const field = isArray(get(col, 'field')) ? <Name>{join(map(get(col, 'field'), field => get(row, field)), ' ')}</Name> : get(row, get(col, 'field'));
    return <LinkBtn to={get(col, 'link.to')} params={linkParams}>{field}</LinkBtn>;
  }, []);
  
  const renderRow = (row, col) => {
    let linkParams = {};

    let colResult = get(row, get(col, 'field'));
    if (isObject(colResult)) {
      colResult = <Text>{colResult.text}<p>{colResult.subText}</p></Text>;
    }
    if (get(col, 'link') && get(col, 'field') === 'name') {
      const params = get(col, 'link.params', {});
      linkParams = reduce(
        keys(params),
        (acc, item) => ({ ...acc, [item]: get(row, get(params, item)) }),
        {}
      );
      return <LinkBtn to={get(col, 'link.to')} params={linkParams}>{colResult}</LinkBtn>;
    }
    if (get(col, 'field') === 'participantType') {
      const isMember = get(row, 'isMember') ? <b>{`( ${t('addMember.isMember')} )`}</b> : null;
      return <div>{get(row, 'name') || t('member.individual')} {isMember}</div>;
    }
    if (get(col, 'externalLink') && get(col, 'field') === 'name') {
      const param = get(col, 'externalLink.field');
      return <Link href={`${get(col, 'externalLink.link')}/${get(row, param)}`}><span dangerouslySetInnerHTML={{__html: colResult}} /></Link>;
    }
    if (get(col, 'field') === 'name') {
      return <Name>{colResult}</Name>;
    }
    if (get(col, 'field') === 'status') {
      return <span>{t(`requests.${get(row, 'status')}`)}</span>;
    }
    if (isArray(get(col, 'field'))) {
      return <Name>{join(map(get(col, 'field'), field => get(row, field)), ' ')}</Name>;
    }
    return colResult;
  };
  
  const onDelete = useCallback((props) => {
    if (window.confirm(t('buttons.areYouSure'))) {
      actions.delete(props);
    }
  }, [actions, t]);
  
  const paramsToObject = (arr, row) => reduce(
    arr,
    (acc, item) => ({ ...acc, [item]: get(row, item) }),
    {}
  );
  
  const actionsCol = useCallback(row => (
    <ActionsWrapper rightAlignment>
      {hasIn(actions, 'edit') && get(actions, 'edit') ? (
        <ActionLink to={actions.edit} params={actions.editParams ? paramsToObject(actions.editParams, row) : {id: get(row, 'id')}}><EditRounded /></ActionLink>
      ) : null}
      {hasIn(actions, 'view') && (
        <ActionLink to="layout.member" params={{memberId: get(row, 'id')}}><Visibility /></ActionLink>
      )}
      {hasIn(actions, 'download') && (
        <ActionLink to="layout.member" params={{memberId: get(row, 'id')}}><GetApp /></ActionLink>
      )}
      {hasIn(actions, 'delete') && (
        <DeleteButton onClick={() => onDelete({ id: get(row, 'id') })}><Delete /></DeleteButton>
      )}
    </ActionsWrapper>
  ), [actions, onDelete]);
  
  const hasSubsidyAccess = useCallback((row) => get(actions, 'subsidyId') ? get(row, 'subsidyId') === get(actions, 'subsidyId') : true, [actions]);
  
  return (
    <Container>
      <Wrapper>
        <Header>
          {map(columns, col => (
            <HeaderCol key={get(col, 'field')} width={get(col, 'width', 33.3)} rightAlignment={get(col, 'field') === 'actions'}>
              {get(col, 'headerName', null)}
            </HeaderCol>
          ))}
          {!isEmpty(actions) && (
            <HeaderCol rightAlignment>
              {t('list.actions')}
            </HeaderCol>
          )}
        </Header>
        {!rows.length && <AlertWrapper severity="warning">{t('common.noData')}</AlertWrapper>}
        {map(rows, row => (
          <Row key={get(row, 'id', null)}>
            {map(columns, col => {
              return (
                <Col
                  key={`${get(row, 'id')}-${get(col, 'field')}`}
                  width={get(col, 'width', 33.3)}
                >
                  {has(col, 'link') ? toLink(col, row) : renderRow(row, col)}
                </Col>
              );
            })}
            {!isEmpty(actions) && hasSubsidyAccess(row) && actionsCol(row)}
          </Row>
        ))}
      </Wrapper>
    </Container>
  );
};

const Container = styled('div')`
  @media only screen and (max-width: 600px) {
    overflow: scroll;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
  flex-direction: column;
  
  @media only screen and (max-width: 600px) {
    width: 100vh;
  }
`;

const Header = styled('div')`
  display: flex;
  width: 100%;
  padding-bottom: ${({theme}) => theme.block.padding};
  font-weight: ${({theme}) => theme.fontWeight.bold};
`;

const HeaderCol = styled('div')`
  flex: 0 0 ${({width}) => width}%;
  margin-left: ${({ rightAlignment }) => rightAlignment && 'auto'};
`;

const Row = styled('div')`
  display: flex;
  width: 100%;
  border-top: ${({theme}) => theme.block.border};
`;

const Col = styled('div')`
  flex: 0 0 ${({width}) => width}%;
  padding: ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding} 0;
  margin-left: ${({ rightAlignment }) => rightAlignment && 'auto'};
`;

const LinkBtn = styled(Href)`
  font-weight: ${({theme}) => theme.fontWeight.bold};
  display: block;
  
  p {
    margin: 0;
  }
`;

const Link = styled('a')`
  font-weight: ${({theme}) => theme.fontWeight.bold};
  display: block;
  
  p {
    margin: 0;
  }
`;

const Name = styled('p')`
  margin: 0;
  font-weight: ${({theme}) => theme.fontWeight.bold};
  p {
    margin: 0;
  }
`;

const Text = styled('div')`
  font-weight: ${({theme}) => theme.fontWeight.bold};
  p {
    font-weight: ${({theme}) => theme.fontWeight.normal};
  }
`;

const ActionLink = styled(Href)`
  margin-left: ${({theme}) => theme.block.margin};
`;

const DeleteButton = styled('span')`
  margin-left: ${({theme}) => theme.block.margin};
  cursor: pointer;
`;

const ActionsWrapper = styled(Col)`
  display: flex;
`;

const AlertWrapper = styled(Alert)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;
