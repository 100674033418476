import React from 'react';
import { Radios, TextField } from 'mui-rff';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const FormNote = () => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs={12} md={12}>
        <TextField
          label={t('forms.subject')}
          name="name"
          margin="none"
          required
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          id="note-textarea"
          label={t('forms.note')}
          name="description"
          multiline
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Radios
          label={t('forms.status')}
          name="isPublic"
          formControlProps={{ margin: 'none' }}
          color="primary"
          radioGroupProps={{ row: true }}
          data={[
            { label: t('forms.public'), value: '1' },
            { label: t('forms.private'), value: '0' },
          ]}
        />
      </Grid>
    </Grid>
  );
};