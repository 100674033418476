import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from '@emotion/styled';
import get from 'lodash/get';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { invitationsActions, invitationsSelectors } from '../../redux/invitations';
import { PageHeader } from '../../_shared/headers';
import { ButtonPrimary } from './../../_shared';
import { PaperBox } from '../../_shared/components';


const InvitationContainer = () => {
  const { t } = useTranslation();
  const { route, navigateTo } = useRoute();
  
  const [anchorEl, setAnchorEl] = useState(null);
  
  const invitation = useSelector(invitationsSelectors.invitation);
  const fetchInvitation = useDispatchHook(invitationsActions.fetchInvitation);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const rows = [
    {
      name: t('forms.note'),
      value: get(invitation, 'description'),
    },
  ];
  
  useEffect(() => {
    fetchInvitation(get(route, 'params.id'));
  }, [fetchInvitation, route]);
  
  return (
    <Container fluid>
      <PageHeader title={get(invitation, 'name')} />
      <Row>
        <Column md={12} xs={12}>
          <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            {t('buttons.actions')}
          </ButtonPrimary>
          <Menu
            id="event-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => navigateTo('layout.members', { invitationId: get(route, 'params.id') }, {replace: false})}>{t('invitations.sendInvitation')}</MenuItem>
            <MenuItem onClick={() => navigateTo('layout.editInvitation', { module: get(invitation, 'module'), id: get(invitation, 'id') }, {replace: false})}>{t('common.modify')}</MenuItem>
          </Menu>
        </Column>
        <Column md={12} xs={12}>
          <PaperBox rows={rows} />
        </Column>
      </Row>
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default InvitationContainer;
