import React from 'react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { founderTypes } from './constants';

export const Founders = () => {
  const { t } = useTranslation();
  const rows = [
    {
      id: 1,
      firstName: 'Vasilie',
      lastName: 'Railean',
      address: 'or. Causeni',
      idnp: '963004043270',
      type: 1,
      phone: '+373 69228186',
      createdAt: '2020-03-01',
    },
    {
      id: 2,
      firstName: 'Vasilie',
      lastName: 'Railean',
      address: 'or. Causeni',
      idnp: '963004043270',
      type: 1,
      phone: '+373 69228186',
      createdAt: '2020-03-01',
    }
  ];
  
  return (
    <Wrapper>
      {map(rows, row => (
        <Founder key={get(row, 'id')}>
          <Name>
            <Title>{get(row, 'firstName')} <span>{get(row, 'lastName')}</span></Title>
            <Title>{get(row, 'address')}</Title>
          </Name>
          <Info>
            <Title>{t('forms.idnp')}: <span>{get(row, 'idno', get(row, 'idnp'))}</span></Title>
            <Title>{t('addMember.phoneNumber')}: <span>{get(row, 'phone')}</span></Title>
            <Title>{t('forms.type')}: <span>{t(`member.${founderTypes[get(row, 'type', 1)]}`)}</span></Title>
            <Title>{t('forms.createdAt')}: <span>{get(row, 'createdAt')}</span></Title>
          </Info>
        </Founder>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  width: 100%;
`;

const Founder = styled('div')`
  display: flex;
  width: 100%;
  border-left: ${({theme}) => theme.block.boldBorder};
  margin: 8px 0;
  padding-left: ${({theme}) => theme.block.margin};
`;

const Name = styled('div')`
  flex: 0 0 33.33%;
`;

const Info = styled('div')``;

const Title = styled('h3')`
  font-size: ${({theme}) => theme.fontSize.md};
  font-weight: bold;
  
  span {
    font-weight: normal;
  }
`;
