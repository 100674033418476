import React, { useState, useEffect, useCallback } from 'react';
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import get from 'lodash/get';
import noop from 'lodash/noop';

const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

type Props = {
  label: string,
  fetch: Function,
  input: {
    name: string,
    onChange: Function,
  }
}

export const AsyncAutocomplete = ({ label, fetch, input }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [inputSearch, setInputSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;
  
  const debounceOnChange = useCallback(
    debounce(value => {
      setInputSearch(value);
    }, 400),
    []
  );
  
  const handleChange = (value) => {
    setInputValue(value);
    debounceOnChange(value);
  };
  
  const onSelect = (value, input) => {
    setInputValue(get(value, 'name', ''));
    input.onChange(get(value, 'id', ''));
  };
  
  useEffect(() => {
    let active = true;
    
    (async () => {
      const { value } = await fetch({ name: inputValue });
      
      if (active) {
        setOptions(value.data);
      }
    })();
  }, [inputSearch, inputValue, fetch]);

  return (
      <Autocomplete
        options={options}
        getOptionLabel={option => option.name}
        open={open}
        name={input.name}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        autoComplete
        loading={loading}
        inputValue={inputValue}
        onChange={(e,v ) => {
          onSelect(v, input);
        }}
        includeInputInList
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            name={input.name}
            variant="standard"
            onChange={event => handleChange(event.target.value)}
            fullWidth
          />
        )}
        renderOption={option => {
          return <div>{option.name}</div>;
        }}
      />
  );
};

AsyncAutocomplete.defaultProps = {
  onSelect: noop,
};