const theme = {
  colors: {
    primary: '#565656',
    secondary: '#222222',
    active: '#1c4a97',
    white: '#ffffff',
    green: '#16bb4a',
    black: '#363636',
    grey: '#dee2e6',
    lightGrey: '#f8f9fa',
    lightBlue: '#dae0e5',
    red: '#e54d04',
  },
  block: {
    margin: '16px',
    padding: '10px',
    shadow: '0 2px 2px 1px rgba(0, 0, 0, 0.1)',
    hoverShadow:'0 0 15px rgba(0, 1, 5, 0.2)',
    border: '1px solid #f1f1f1',
    boldBorder: '3px solid #1c4a97',
  },
  background: {
    primary: '#ffffff',
    secondary: '#f7f9fb',
    buttonPrimary: '#f1f1f1',
    buttonSecondary: '#1c4a97',
    red: '#e54d04',
  },
  fontSize: {
    small: '12px',
    md: '14px',
    xs: '18px',
    xl: '36px',
  },
  fontWeight: {
    bold: '700',
    normal: '400',
  },
  header: {
    height: '56px',
    background: '#ffffff',
  },
  footer: {
    height: '56px',
    background: '#ffffff',
  }
};

export default theme;