import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { LayoutContext } from '../../utils';
import api from '../../redux/api';
import CONFIG from '../../config';

type Props = {
  isEditing: boolean,
}

const ImageUploader = ({ isEditing }: Props) => {
  const data = new FormData();

  const { t } = useTranslation('');
  const { images, setImages } = useContext(LayoutContext);
  const [imageList, setImageList] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
    
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        setImageList([file]);
        data.append('image', file);
        api.post('images', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(data => {
          if (data && data.image) {
            setImages([data.image]);
          }
        });
      };
      reader.readAsArrayBuffer(file);
    })
  }, [setImages, data, setImageList]);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: "image/png, image/gif, image/jpeg", multiple: false });
  
  useEffect(() => {
    if (images.length && isEditing) {
      setImageList(images);
    }
  }, [isEditing, images]);
  
  useEffect(() => {
    return () => {
      setImages([]);
    }
  },[setImages]);

  return (
    <Wrapper {...getRootProps()}>
      <List>
        {imageList.map(img => (
          <ImageWrapper key={img}>
            <Image src={isEditing ? `${CONFIG.api}/images/${img}` : window.URL.createObjectURL(img)} />
          </ImageWrapper>
        ))}
      </List>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>{t('images.dragAndDrop')}</p>
      }
    </Wrapper>
  )
};

const Wrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  
  p {
    margin: 0;
  }
`;

const List = styled('div')`
  display: flex;
  width: 100%;
`;

const Image = styled('img')`
  width: 100%;
`;

const ImageWrapper = styled('div')`
  width: 100px;
`;

export default ImageUploader;