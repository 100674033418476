import React from 'react';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import { Delete as DeleteIcon, ImportExport as ImportExportIcon } from '@material-ui/icons';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

type Props = {
  items: [],
  onDeleteImport: Function,
  className?: string,
}

export const ImportExportList = ({ items, onDeleteImport, className }: Props) => {
  const { t } = useTranslation('');
  if(isEmpty(items)) return t('member.noImports');
  
  return (
    <DueWrapper className={className}>
      {items.map(({name, type, id}) => (
        <ListItem key={id}>
          <ListItemAvatar>
            <Avatar>
              <ImportExportIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={name}
            secondary={t(`importTypes.${type}`)}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => onDeleteImport(id)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </DueWrapper>
  )
};

const DueWrapper = styled(List)`
  border: 1px solid #bdbdbd;
`;
