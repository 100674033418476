import React from 'react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { membersSelectors } from '../../../redux/members';


export const MemberNotices = () => {
  const notices = useSelector(membersSelectors.notices);

  return (
    <Wrapper>
      {map(notices, row => (
        <Article key={get(row, 'id')}>
          <Name>{get(row, 'name')}<p>{get(row, 'created_at')}</p></Name>
          <div>{get(row, 'description')}</div>
        </Article>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  width: 100%;
`;

const Article = styled('div')`
  display: flex;
  width: 100%;
  border-left: ${({theme}) => theme.block.boldBorder};
  margin: 8px 0;
  padding-left: ${({theme}) => theme.block.margin};
`;

const Name = styled('div')`
  flex: 0 0 33.33%;
  font-weight: bold;
  padding-right: ${({theme}) => theme.block.margin};
  p {
    font-weight: normal;
    font-size: ${({theme}) => theme.fontSize.small};
    margin: 0;
  }
 `;

