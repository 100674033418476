import React, {useCallback, useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { toastr } from "react-redux-toastr";

import { PageHeader } from '../../_shared/headers';
import { filedToCurrentLanguage } from '../../utils';
import { useDispatchHook, useRoute } from "../../utils/hooks";
import { trainingActions } from '../../redux/training';
import { participantsActions } from '../../redux/participants';
import { ButtonPrimary } from './../../_shared';
import { PaperBox, ItemParticipantsList } from '../../_shared/components';

const TrainingContainer = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [training, setTraining] = useState({});
  const [participants, setParticipants] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    perPage: 0,
    pages: 0,
    currentPage: 0,
  });
  
  const fetchTraining = useDispatchHook(trainingActions.fetchTraining);
  const createReport = useDispatchHook(trainingActions.createReport);
  const fetchParticipantsByModuleAndId = useDispatchHook(participantsActions.fetchParticipantsByModuleAndId);
  
  useEffect(() => {
    (async () => {
      const { value: training } = await fetchTraining(get(route, 'params.trainingId'));
      setTraining(training);
      const { value } = await fetchParticipantsByModuleAndId('training', get(route, 'params.trainingId'), get(route, 'params.page', 1));
      if (get(value, 'data', []).length) {
    
        setParticipants(get(value, 'data', []));
        setPagination({
          total: value.total,
          perPage: value.per_page,
          pages: value.last_page,
          currentPage: value.current_page,
        });
      }
    })();
  }, [fetchTraining, fetchParticipantsByModuleAndId, route]);

  const handleClick = (lobby) => {
    setAnchorEl(lobby.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const generateReport = useCallback(async values => {
    try {
      const { value } = await createReport({
        name: get(values, 'name'),
        moduleName: 'training',
        trainingId: get(route, 'params.trainingId'),
        meta: JSON.stringify({}),
      });
      const date = Date.now();
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleClose();
      toastr.success(t('common.reportWereCreated'));
    } catch (e) {
      console.warn(e);
    }
  }, [t, createReport, route]);
  
  const rows = [
    {
      name: t('addLobby.type'),
      value: filedToCurrentLanguage(get(training, 'type')),
    },
    {
      name: t('addLobby.international'),
      value: get(training, 'international') ? t('common.yes') : t('common.no'),
    },
    {
      name: t('forms.address'),
      value: get(training, 'country'),
    },
    {
      name: `${t('addLobby.startDate')} - ${t('addLobby.endDate')}`,
      value: `${get(training, 'startDate')} / ${get(training, 'endDate')}`,
    },
    {
      name: t('forms.note'),
      value: get(training, 'description'),
    },
    {
      name: t('training.trainer'),
      value: `${get(training, 'trainer.firstName')} ${get(training, 'trainer.lastName')}`,
    },
  ];
  
  return (
    <Container fluid>
      <PageHeader title={get(training, 'name')} backLink="hash" />
      <Row>
        <Column md={12} xs={12}>
          <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            {t('buttons.actions')}
          </ButtonPrimary>
          <Menu
            id="lobby-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={generateReport}>{t('common.generateReport')}</MenuItem>
            <MenuItem onClick={() => {
              navigateTo('layout.addParticipant', { module: 'training', id: get(route, 'params.trainingId') }, {replace: false})
            }}>{t('common.addParticipant')}</MenuItem>
            <MenuItem onClick={() => navigateTo('layout.editTraining', { id: get(route, 'params.trainingId') }, {replace: false})}>{t('common.modify')}</MenuItem>
          </Menu>
        </Column>
        <Column md={12} xs={12}>
          <PaperBox rows={rows} />
        </Column>
      </Row>
      <h2>{t('participants.header', { count: pagination.total })}</h2>
      <ItemParticipantsList participants={participants} pagination={pagination} />
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default TrainingContainer;
