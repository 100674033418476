import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '../../../_shared/headers';
import { GridList } from '../../../_shared';
import { useDispatchHook } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';

const LegalForms = () => {
  const { t }= useTranslation('');
  
  const legalForms = useSelector(administrationSelectors.legalForms);
  const fetchLegalForms = useDispatchHook(administrationActions.fetchLegalForms);
  const deleteLegalForm = useDispatchHook(administrationActions.deleteLegalForm);
  
  const columns = [
    { field: 'name', headerName: t('events.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchLegalForms();
  }, [fetchLegalForms]);
  
  return (
    <Container fluid>
      <PageHeader title={t('legalForm.header', { count: legalForms.length })} addLink="layout.addLegalForm" />
      <GridList
        actions={{
          delete: deleteLegalForm,
          edit: 'layout.editLegalForm',
        }}
        columns={columns}
        rows={legalForms}
      />
    </Container>
  );
};

export default LegalForms;
