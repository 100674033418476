import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { invitationsActions } from '../../redux/invitations';
import Form from './components/form';

const AddInvitation = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  const createInvitation = useDispatchHook(invitationsActions.createInvitation);
  
  const onSubmit = useCallback(async (values) => {
    try {
      await createInvitation({
        ...values,
        module: get(route, 'params.module'),
      });
      toastr.success(t('common.success'));
      navigateTo('layout.invitations', { module: get(route, 'params.module') }, {replace: false});
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [createInvitation, navigateTo, route, t]);
  
  return (
    <Container fluid>
      <PageHeader title={t('invitations.add')} backLink="hash" />
      <FormWrapper
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddInvitation;