import React from 'react';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Phone from '@material-ui/icons/Phone';
import MailOutline from '@material-ui/icons/MailOutline';
import Home from '@material-ui/icons/Home';
import Language from '@material-ui/icons/Language';

import { filedToCurrentLanguage } from '../../../utils';
import { useRoute } from '../../../utils/hooks';

type Props = {
  report: {},
}

export const MapLegend = ({ report }: Props) => {
  const { route } = useRoute();
  if (isEmpty(report)) {
    return null;
  }

  return (
    <Wrapper>
      <Title>{filedToCurrentLanguage(get(report, 'name'), get(route, 'params.lng', 'ro'))}</Title>
      <Line><Phone /> {get(report, 'phone')}</Line>
      <Line><MailOutline /> <a href={`mailto: ${get(report, 'email')}`}>{get(report, 'email')}</a></Line>
      <Line><Home /> {get(report, 'address')}</Line>
      <Line><Language /> <a href={get(report, 'webpage')} target="_blank" rel="noopener noreferrer">{get(report, 'webpage')}</a></Line>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 32px;
  padding: 8px 16px;
  color: #fff;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  bottom: 30%;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  
  a {
    color: #fff;
    text-decoration: underline;
  }
  
  svg {
    margin-right: 8px;
  }
`;