import React, {Children, cloneElement, Node, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import noop from 'lodash/noop';
import { makeStyles, CssBaseline, Drawer, Box, AppBar, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Backdrop, CircularProgress } from '@material-ui/core';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, Notifications as NotificationsIcon } from '@material-ui/icons';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { LayoutProvider } from '../../utils';
import { MainListItems, SecondaryListItems } from './components/listItems';
import { membersActions, membersSelectors } from '../../redux/members';

const Copyright = () => {
  const { t } = useTranslation('');
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {t('header.cci')}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

type Props = {
  children: Node,
}

const NewLayout = ({ children }: Props) => {
  const { t } = useTranslation('');
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  
  const { route, navigateTo } = useRoute();
  
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  
  const requestsByStatus = useSelector(membersSelectors.requestsByStatus);
  
  const fetchRequestsByStatus = useDispatchHook(membersActions.fetchRequestsByStatus);
  
  useEffect(() => {
    if (route && route.name === 'layout') {
      navigateTo('layout.home', {}, {replace: false});
    }
  }, [route, navigateTo]);
  
  useEffect(() => {
    fetchRequestsByStatus('waiting');
  }, [fetchRequestsByStatus]);
  
  return (
    <div className={classes.root}>
      <Backdrop open={false} className={classes.backdrop} onClick={noop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {t('header.cci')}
          </Typography>
          {requestsByStatus.length > 0 && (
            <IconButton color="inherit" onClick={() => navigateTo('layout.requests', {}, {replace: false})}>
              <Badge badgeContent={requestsByStatus.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems leftSideStatus={open} />
        <Divider />
        <List>{SecondaryListItems()}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <LayoutProvider>
            {Children.map(children, child => cloneElement(child, theme))}
          </LayoutProvider>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default NewLayout;