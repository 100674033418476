import React, { useCallback, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Form as FormWrapper } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';

import { LayoutContext, memberModelToService } from '../../utils';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { membersActions } from '../../redux/members';
import { PageHeader } from '../../_shared/headers';
import { validateForm } from './components/validations';
import { Form } from './components';

const initialValues = {
  isMember: false,
  isDual: false,
  hasAttestation: false,
  hasMeditation: false,
  hasServices: false,
  activity: [],
  itemCategories: [],
  tags: [],
  email: [],
  social: [],
};

const AddMemberContainer = () => {
  const { t } = useTranslation();
  
  const { images } = useContext(LayoutContext);
  const { navigateTo } = useRoute();
  
  const createMember = useDispatchHook(membersActions.createMember);
  
  const onSubmit = useCallback(async values => {
    try {
      await createMember(memberModelToService(values, images));
      toastr.success(t('common.success'));
      navigateTo('layout.members', { isMember: get(values, 'isMember', true) }, {replace: false});
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [createMember, images, navigateTo, t]);
  
  return (
    <Container fluid>
      <PageHeader title={t('addMember.header')} backLink="hash" />
      <FormWrapper
        onSubmit={onSubmit}
        validate={errors => validateForm(errors, t('member.requiredField'))}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddMemberContainer;