import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, Typography, Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

type Props = {
  title: string,
  top?: string,
  subTitle?: string,
  children?: Node,
  actions?: Node,
}

const CardBox = ({ title, subTitle, top, children, actions, chip }: Props) => {
  const classes = useStyles();
  
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        {top && (
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {top}
          </Typography>
        )}
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        {chip && (
          <Chip
            icon={<DoneIcon />}
            label={chip}
            color="secondary"
          />
        )}
        {subTitle && (
          <Typography className={classes.pos} color="textSecondary">
            {subTitle}
          </Typography>
        )}
        {children && (
          <Typography variant="body2" component="div">
            {children}
          </Typography>
        )}
      </CardContent>
      {actions && (
        <CardActions disableSpacing>
          {actions}
        </CardActions>
      )}
    </Card>
  );
};

export default CardBox;
