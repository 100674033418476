import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { TextField } from 'mui-rff';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../../redux/app';
import map from 'lodash/map';

const Form = () => {
  const { t } = useTranslation();
  const languages = useSelector(appSelectors.languages);
  
  return (
    <Column expanded>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('addMember.generalData')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="flex-start" spacing={2}>
          {map(languages, lng => (
            <Grid item xs={12} md={12} key={lng}>
              <TextField
                label={`${t('forms.name')} - ${lng}`}
                name={`name[${lng}]`}
                margin="none"
                required
              />
            </Grid>
          ))}
          <Grid item xs={12} md={12}>
            <TextField
              label={t('forms.phoneNumber')}
              name="phone"
              margin="none"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('forms.email')}
              name="email"
              margin="none"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('forms.address')}
              name="address"
              margin="none"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('addMember.webPage')}
              name="webpage"
              margin="none"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Column>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default Form;
