import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import get from 'lodash/get';

import { PageHeader } from '../../../_shared/headers';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions } from '../../../redux/administration';
import Form from './components/form';

const AddUser = () => {
  const { t } = useTranslation();
  const { navigateTo } = useRoute();
  const createUser = useDispatchHook(administrationActions.createUser);
  
  const onSubmit = useCallback(async values => {
    await createUser({ ...values, moduleAccess: JSON.stringify(get(values, 'moduleAccess', '{}')) });
    navigateTo('layout.users', {}, {replace: false});
  }, [createUser, navigateTo]);
  
  return (
    <Container fluid>
      <PageHeader title={t('users.form')} backLink="layout.users" />
      <FormWrapper
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddUser;