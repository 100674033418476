import React, { useCallback, Node } from 'react';
import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useRoute } from '../../utils/hooks';

type Props = {
  title: string,
  addLink?: string,
  backLink?: string,
  children?: Node,
};

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 'auto',
    marginRight: '16px',
  }
}));

export const PageHeader = ({ title, addLink, backLink, children }: Props) => {
  const { t } = useTranslation();
  const { navigateTo } = useRoute();
  const classes = useStyles();
  
  const handleBackLink = useCallback(() => {
    if (backLink === 'hash') return window.history.go(-1);
    return navigateTo(backLink);
  }, [backLink, navigateTo]);
  
  return (
    <Wrapper>
      <Title>{title}</Title>
      {addLink && (
        <Button color="primary" className={classes.button} size="small" variant="contained" onClick={() => navigateTo(addLink)}>
          {t('buttons.add')}
        </Button>
      )}
      {backLink && (
        <Button className={classes.button} size="small" variant="contained" onClick={handleBackLink}>
          {t('buttons.back')}
        </Button>
      )}
      {children && <RightSide>{children}</RightSide>}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  padding: 8px 0;
  min-height: 46px;
  background: #fff;
  margin-bottom: ${({theme}) => theme.block.margin};
`;

const Title = styled('h1')`
  border-left: 3px solid ${({theme}) => theme.background.buttonSecondary};
  font-size: ${({theme}) => theme.fontSize.md};
  text-transform: uppercase;
  padding-left: 16px;
  color: ${({theme}) => theme.colors.secondary};
  font-weight: 600;
  margin: 0;
`;

const RightSide = styled('div')`
  margin-left: auto;
`;
