import React, { useEffect, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';

import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { membersActions, membersSelectors } from '../../../redux/members';
import { filedToCurrentLanguage } from '../../../utils';
import { Pagination } from '../../../_shared';
import { FilterMembers } from './filterMembers';
import CONFIG from '../../../config';

export const PublicMembers = () => {
  const { t } = useTranslation('');

  const { route } = useRoute();
  const [isLoading, setIsLoading] = useState(false);
  
  const fetchMembers = useDispatchHook(membersActions.fetchMembersPublic);
  const members = useSelector(membersSelectors.membersPublic);
  const pagination = useSelector(membersSelectors.pagination);
  
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchMembers({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1)
      });
      setIsLoading(false);
    })();
  }, [fetchMembers, route, setIsLoading]);
  
  const renderImg = src => src ? `${CONFIG.api}/images/${src}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeCvtPo1dpUVshRm_iw4pufCSh4TS66k0XVw&usqp=CAU';
  const renderCategories = categories => categories.map(cat => filedToCurrentLanguage(get(cat, 'name'), get(route, 'params.lng', 'ro'))).join(', ');

  const isMembersShown = Object.keys(omit(route.params, ['lng'])).filter(a => isArray(route.params[a]) ? !isEmpty(route.params[a]) : route.params[a]);

  const renderEmail = member => {
    let emails;
    try {
      emails = JSON.parse(get(member, 'email'));
    } catch (e) {
      emails = [];
    }
    if (isEmpty(emails)) return null;
    return (
      <>
        <br/><b>{t('addMember.email')}</b>:<br/> {emails.map(email => (<><a href={`mailto: ${email}`}>{email}</a><br/></>))}
      </>
    )
  };

  return (
    <Fragment>
      <FilterMembers />
      {!isEmpty(isMembersShown) && isEmpty(members) ? (
        <Alert severity="warning">{t('public.noDataWereFound')}</Alert>
      ) : null}
      
      {isEmpty(isMembersShown) && (
        <Info dangerouslySetInnerHTML={{__html: t('public.noMembers')}} />
      )}
      {!isEmpty(isMembersShown) && (
        <>
          <Wrapper>
            {isLoading && <Loading />}
            {!isLoading && members.map(member => (
              <Container key={get(member, 'id')}>
                <Header>
                  <Logo>
                    <Img src={renderImg(get(member, 'logoUrl'))} />
                  </Logo>
                  <Title>
                    {get(member, 'name')}
                  </Title>
                </Header>
                <Content>
                  {!isEmpty(get(member, 'item_categories', [])) ? (
                    <Line>
                      <b>{t('forms.productsService')}</b>:
                      {renderCategories(get(member, 'item_categories', []))}
                    </Line>
                  ) : null}
  
                  {!isEmpty(get(member, 'activity', [])) ? (
                    <Line>
                      <b>{t('addMember.activityDirections')}</b>:
                      {renderCategories(get(member, 'activity', []))}
                    </Line>
                  ) : null}
          
                  <Line active>
                    <b>{t('addMember.subsidy')}</b>: {filedToCurrentLanguage(get(member, 'branch'), get(route, 'params.lng', 'ro'))}
                    {get(member, 'phone') && <><br/><b>{t('addMember.phoneNumber')}</b>: {get(member, 'phone')}</>}
                    {get(member, 'webPage') && <><br/><b>{t('addMember.webPage')}</b>: <a rel="noopener noreferrer" href={get(member, 'webPage').replace('www', 'https://www')} target="_blank">{get(member, 'webPage')}</a></>}
                    {renderEmail(member)}
                  </Line>
                </Content>
              </Container>
            ))}
          </Wrapper>
          {!isLoading && (<Pagination data={pagination} />)}
        </>
      )}
    </Fragment>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 24px;
  
  @media (max-width: 992px) {
    grid-template-columns: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #fda916;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 100%;
`;

const Logo = styled.div`
  width: 148px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 8px;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 14px;
  margin: 8px 0 0 0;
  font-weight: bold;
  color: #3f51b5;
`;

const Content = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Line = styled.div`
  margin-top: auto;
  a {
    text-decoration: underline;
  }
  
  ${({active}) => active && `
    background-color:  #e4f1ff;
    padding: 8px;
  `}
`;

const Loading = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.1;
`;

const Info = styled('div')`

`;