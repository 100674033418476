import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';
import map from 'lodash/map';

import api from '../api';
import { filedToCurrentLanguage } from '../../utils';

const types = createSelector(
  state => state.events.get('types'),
  state => state.app.get('language'),
  (types, language) => {
    const list = types.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), language) }));
  },
);

const subjects = createSelector(
  state => state.events.get('subjects'),
  state => state.app.get('language'),
  (subjects, language) => {
    const list = subjects.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), language) }));
  },
);

const pagination = createSelector(
  state => state.events.get('pagination'),
  pagination => pagination.toJS(),
);

const events = createSelector(
  state => state.events.get('events'),
  events => events.toJS(),
);

const event = createSelector(
  state => state.events.get('event'),
  event => event.toJS(),
);

export const eventsSelectors = {
  types,
  subjects,
  events,
  event,
  pagination,
};

const initialState = fromJS({
  events: [],
  event: {},
  types: [],
  subjects: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  }
});

export const reducer = handleActions(
  {
    EVENTS_FETCH_EVENT_FULFILLED: (state, { payload }) => state.set('event', fromJS(payload)),
    EVENTS_FETCH_EVENT_TYPES_FULFILLED: (state, { payload }) => state
      .set('types', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    EVENTS_DELETE_EVENT_TYPE_FULFILLED: (state, { payload }) => state.update('types', types => fromJS(types.toJS().filter(({ id }) => id !== +payload))),
  
    EVENTS_FETCH_EVENT_SUBJECTS_FULFILLED: (state, { payload }) => state
      .set('subjects', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    EVENTS_DELETE_EVENT_SUBJECT_FULFILLED: (state, { payload }) => state.update('subjects', types => fromJS(types.toJS().filter(({ id }) => id !== +payload))),
    EVENTS_FETCH_EVENTS_FULFILLED: (state, { payload }) => state
      .set('events', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
  },
  initialState
);

export const eventsActions = {
  fetchEvents: createAction('EVENTS_FETCH_EVENTS', params => api.get('events', { params })),
  fetchEvent: createAction('EVENTS_FETCH_EVENT', eventId => api.get(`event/${eventId}`)),
  createEvent: createAction('EVENTS_EVENT_CREATE', props => api.post('events/create', { ...props })),
  updateEvent: createAction('EVENTS_EVENT_UPDATE', (eventId, props) => api.put(`events/update/${eventId}`, { ...props })),
  deleteEvent: createAction('EVENTS_DELETE', props => api.post('events/delete', { ...props })),
  
  fetchEventTypes: createAction('EVENTS_FETCH_EVENT_TYPES', props => api.get('events/types', props)),
  fetchEventType: createAction('EVENTS_FETCH_EVENT_TYPE', id => api.get('events/types/details', { params: { id } })),
  deleteEventType: createAction('EVENTS_DELETE_EVENT_TYPE', props => api.post('events/types/delete', { ...props })),
  createEventType: createAction('EVENTS_EVENT_TYPE_CREATE', props => api.post('events/types/create', { ...props })),
  updateEventType: createAction('EVENTS_EVENT_TYPE_UPDATE', (props, id) => api.put(`events/types/edit/${id}`, { ...props })),
  
  fetchEventSubjects: createAction('EVENTS_FETCH_EVENT_SUBJECTS', props => api.get('events/subjects', props)),
  fetchEventSubject: createAction('EVENTS_FETCH_EVENT_SUBJECT', id => api.get('events/subjects/details', { params: { id } })),
  deleteEventSubject: createAction('EVENTS_DELETE_EVENT_SUBJECT', props => api.post('events/subjects/delete', { ...props })),
  createEventSubject: createAction('EVENTS_EVENT_SUBJECT_CREATE', props => api.post('events/subjects/create', { ...props })),
  updateEventSubject: createAction('EVENTS_EVENT_SUBJECT_UPDATE', (props, id) => api.put(`events/subjects/edit/${id}`, { ...props })),
  
  createReport: createAction('EVENTS_CREATE_REPORT', props => api.post('events/export', props, { responseType: 'blob' })),
};
