import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, Form as FormWrapper } from 'react-final-form';
import get from 'lodash/get';

import { useRoute } from '../../../../utils/hooks';
import { HeaderText, ButtonPrimary } from '../../../../_shared';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {TextField} from "mui-rff";

export const Filter = () => {
  const { t } = useTranslation();
  
  const { navigateTo, route } = useRoute();
  const [initialValues, setInitialValues] = useState({});
  
  const handleSearch = useCallback(values => {
    navigateTo('layout.itemCategories', {...values, page: 1}, {replace: false});
  }, [navigateTo]);
  
  useEffect(() => {
    setInitialValues({
      ...get(route, 'params', {}),
    });
  }, [route, setInitialValues]);
  
  return (
    <Wrapper>
      <HeaderText text={t('buttons.filter')} />
      <FormWrapper
        onSubmit={handleSearch}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={12}>
                <Field name="name" type="text">
                  {({input}) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name={input.name}
                      onChange={input.onChange}
                      label={t('forms.name')}
                      id={input.name}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  label={t('common.isPublic')}
                  control={
                    <Field name="isPublic" type="checkbox">
                      {props => (
                        <Switch
                          color="primary"
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e, v) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                />
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.search')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;
