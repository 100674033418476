import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { invitationsActions } from '../../redux/invitations';
import Form from './components/form';

const EditInvitation = () => {
  const { t } = useTranslation();
  const { route } = useRoute();
  
  const [initialValues, setInitialValues] = useState({});
  
  const updateInvitation = useDispatchHook(invitationsActions.updateInvitation);
  const fetchInvitation = useDispatchHook(invitationsActions.fetchInvitation);
  
  const onSubmit = useCallback(async values => {
    try {
      await updateInvitation(get(route, 'params.id'), {...values});
      toastr.success(t('common.updated'));
      window.history.go(-1);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [updateInvitation, t, route]);
  
  useEffect(() => {
    (async () => {
      const { value } = await fetchInvitation(get(route, 'params.id'));
      
      setInitialValues({
        name: get(value, 'name'),
        description: get(value, 'description'),
      })
    })();
  }, [route, fetchInvitation]);
  
  
  return (
    <Container fluid>
      <PageHeader title={t('invitations.edit')} backLink="hash" />
      <FormWrapper
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default EditInvitation;