export const validateForm = (values, message) => {
  const errors = {};
  
  if (!values.name) {
    errors.name = message;
  }
  
  if (!values.idno) {
    errors.idno = message;
  }
  
  if (!values.subsidyId) {
    errors.subsidyId = message;
  }
  
  if (!values.subsidyId) {
    errors.subsidyId = message;
  }
  
  if (!values.propertyId) {
    errors.propertyId = message;
  }
  
  if (!values.legalFormId) {
    errors.legalFormId = message;
  }
  
  if (!values.cityId) {
    errors.cityId = message;
  }
  
  if (!values.villageId) {
    errors.villageId = message;
  }
  
  return errors;
};

export const validateNoteForm = (values, message) => {
  const errors = {};
  if (!values.name) {
    errors.name = message;
  }
  
  if (!values.description) {
    errors.description = message;
  }
  
  return errors;
};

export const validateReportForm = (values, message) => {
  const errors = {};
  if (!values.name) {
    errors.name = message;
  }
  
  return errors;
};

export const validateRequestStatus = (values, message) => {
  const errors = {};
  if (!values.memberNumber) {
    errors.memberNumber = message;
  }
  
  if (!values.startDate) {
    errors.startDate = message;
  }

  return errors;
};

export const validateFounderForm = (values, message) => {
  const errors = {};
  /*if (!values.firstName) {
    errors.firstName = message;
  }
  
  if (!values.lastName) {
    errors.lastName = message;
  }
  
  if (!values.idnp) {
    errors.idnp = message;
  }
  
  if (!values.cityId) {
    errors.cityId = message;
  }
  
  if (!values.villageId) {
    errors.villageId = message;
  }
  
  if (!values.email) {
    errors.email = message;
  }*/
  
  return errors;
};