import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';

import { PageHeader } from '../../_shared/headers';
import { validateLobbyForm } from './components/validations';
import { eventModelToService } from '../../utils';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { trainingActions } from '../../redux/training';
import { administrationActions } from '../../redux/administration';
import Form from './components/form';

const EditTraining = () => {
  const { t } = useTranslation();
  const { route } = useRoute();
  
  const [initialValues, setInitialValues] = useState({
    international: '0',
  });
  
  const fetchCities = useDispatchHook(administrationActions.fetchCities);
  const fetchVillages = useDispatchHook(administrationActions.fetchVillages);
  
  const updateTraining = useDispatchHook(trainingActions.updateTraining);
  const fetchTraining = useDispatchHook(trainingActions.fetchTraining);
  
  const onSubmit = useCallback(async values => {
    try {
      await updateTraining(get(route, 'params.id'), eventModelToService(values));
      toastr.success(t('common.updated'));
      window.history.go(-1);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [updateTraining, t, route]);
  
  useEffect(() => {
    const fetchData = async () => {
      const { value } = await fetchTraining(get(route, 'params.id'));
      await fetchCities(get(value, 'countryId'));
      await fetchVillages(get(value, 'cityId'));

      setInitialValues({
        name: get(value, 'name'),
        description: get(value, 'description'),
        startDate: get(value, 'startDate'),
        endDate: get(value, 'endDate'),
        typeId: get(value, 'typeId'),
        countryId: get(value, 'countryId'),
        trainerId: get(value, 'trainerId'),
        international: get(value, 'international') ? '1' : '0',
      })
    };
    fetchData();
  }, [route, fetchTraining, fetchCities, fetchVillages]);
  
  
  return (
    <Container fluid>
      <PageHeader title={t('addTraining.edit')} backLink="hash" />
      <FormWrapper
        onSubmit={onSubmit}
        validate={errors => validateLobbyForm(errors, t('member.requiredField'))}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default EditTraining;