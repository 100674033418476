import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { PageHeader, GridList, Pagination } from '../../_shared';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { eventsActions, eventsSelectors } from '../../redux/events';

const EventTypes = () => {
  const { t }= useTranslation('');
  const { route } = useRoute();
  
  const types = useSelector(eventsSelectors.types);
  const pagination = useSelector(eventsSelectors.pagination);
  
  const fetchEventTypes = useDispatchHook(eventsActions.fetchEventTypes);
  const deleteEventType = useDispatchHook(eventsActions.deleteEventType);
  
  const columns = [
    { field: 'name', headerName: t('events.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchEventTypes({
      page: get(route, 'params.page', 1),
    });
  }, [fetchEventTypes, route]);

  return (
    <Container fluid>
      <PageHeader title={t('eventsType.header', { count: pagination.total })} addLink="layout.addEventTypes" />
      <GridList
        actions={{
          delete: deleteEventType,
          edit: 'layout.editEventTypes',
        }}
        columns={columns}
        rows={types}
      />
      <Pagination data={pagination} />
    </Container>
  );
};

export default EventTypes;
