import React, {useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from '@emotion/styled';
import get from 'lodash/get';
import join from 'lodash/join';
import compact from 'lodash/compact';
import { toastr } from 'react-redux-toastr';

import { PageHeader } from '../../_shared/headers';
import { filedToCurrentLanguage } from '../../utils';
import { useDispatchHook, useRoute } from "../../utils/hooks";
import { lobbyActions, lobbySelectors } from '../../redux/lobby';
import { participantsActions } from '../../redux/participants';
import { ButtonPrimary } from './../../_shared';
import { PaperBox, ItemParticipantsList } from '../../_shared/components';

const LobbyContainer = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [participants, setParticipants] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    perPage: 0,
    pages: 0,
    currentPage: 0,
  });
  
  const fetchLobby = useDispatchHook(lobbyActions.fetchLobby);
  const createReport = useDispatchHook(lobbyActions.createReport);
  const fetchParticipantsByModuleAndId = useDispatchHook(participantsActions.fetchParticipantsByModuleAndId);
  
  const lobby = useSelector(lobbySelectors.lobby);
  
  useEffect(() => {
    fetchLobby(get(route, 'params.lobbyId'));
    (async () => {
      const { value } = await fetchParticipantsByModuleAndId('lobby', get(route, 'params.lobbyId'), get(route, 'params.page', 1));
      if (get(value, 'data', []).length) {
        
        setParticipants(get(value, 'data', []));
        setPagination({
          total: value.total,
          perPage: value.per_page,
          pages: value.last_page,
          currentPage: value.current_page,
        });
      }
    })();
  }, [fetchLobby, fetchParticipantsByModuleAndId, route]);

  const handleClick = (lobby) => {
    setAnchorEl(lobby.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const address = join(compact([get(lobby, 'country'), get(lobby, 'city'), get(lobby, 'village')]), ', ');
  const rows = [
    {
      name: t('addLobby.type'),
      value: filedToCurrentLanguage(get(lobby, 'type')),
    },
    {
      name: t('addLobby.international'),
      value: get(lobby, 'international') ? t('common.yes') : t('common.no'),
    },
    {
      name: t('forms.address'),
      value: address,
    },
    {
      name: `${t('addLobby.startDate')} - ${t('addLobby.endDate')}`,
      value: `${get(lobby, 'startDate')} / ${get(lobby, 'endDate')}`,
    },
    {
      name: t('forms.note'),
      value: get(lobby, 'description'),
    },
    {
      name: t('forms.addedBy'),
      value: 'Vasili',
    },
  ];
  
  const generateReport = useCallback(async values => {
    try {
      const { value } = await createReport({
        name: get(values, 'name'),
        moduleName: 'lobby',
        lobbyId: get(route, 'params.lobbyId'),
        meta: JSON.stringify({}),
      });
      const date = Date.now();
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleClose();
      toastr.success(t('common.reportWereCreated'));
    } catch (e) {
      console.warn(e);
    }
  }, [t, createReport, route]);
  
  return (
    <Container fluid>
      <PageHeader title={get(lobby, 'name')} backLink="hash" />
      <Row>
        <Column md={12} xs={12}>
          <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            {t('buttons.actions')}
          </ButtonPrimary>
          <Menu
            id="lobby-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={generateReport}>{t('common.generateReport')}</MenuItem>
            <MenuItem onClick={() => {
              navigateTo('layout.addParticipant', { module: 'lobby', id: get(route, 'params.lobbyId') }, {replace: false})
            }}>{t('common.addParticipant')}</MenuItem>
            {/*<MenuItem onClick={() => {}}>{t('common.addSpeaker')}</MenuItem>*/}
            <MenuItem onClick={() => navigateTo('layout.editLobby', { id: get(route, 'params.lobbyId') }, {replace: false})}>{t('common.modify')}</MenuItem>
          </Menu>
        </Column>
        <Column md={12} xs={12}>
          <PaperBox rows={rows} />
        </Column>
      </Row>
      <h2>{t('participants.header', { count: pagination.total })}</h2>
      <ItemParticipantsList participants={participants} pagination={pagination} />
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default LobbyContainer;
