import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import get from 'lodash/get';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from "../../utils/hooks";
import { participantsActions } from '../../redux/participants';
import { PaperBox } from '../../_shared/components';

const ParticipantContainer = () => {
  const { t } = useTranslation();
  const { route } = useRoute();
  const [participant, setParticipant] = React.useState({});
  
  const fetchParticipant = useDispatchHook(participantsActions.fetchParticipant);
  
  useEffect(() => {
    (async () => {
      const {value} = await fetchParticipant(get(route, 'params.id'));
      setParticipant(value);
    })();
  }, [fetchParticipant, route]);
  
  const rows = [
    {
      name: t('participants.areaOfInterest'),
      value: get(participant, 'areaOfInterest'),
    },
    {
      name: t('participants.areaOfWork'),
      value: get(participant, 'areaOfWork'),
    },
    {
      name: t('addMember.phoneNumber'),
      value: get(participant, 'phone'),
    },
    {
      name: t('addMember.email'),
      value: get(participant, 'email'),
    },
    {
      name: t('addMember.webPage'),
      value: get(participant, 'webpage'),
    },
  ];
  
  return (
    <Container fluid>
      <PageHeader title={`${get(participant, 'firstName')} ${get(participant, 'lastName')}`} backLink="hash" />
      <Row>
        <Column md={12} xs={12}>
          <PaperBox rows={rows} />
        </Column>
      </Row>
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default ParticipantContainer;
