import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';
import map from 'lodash/map';

import api from '../api';
import { filedToCurrentLanguage } from '../../utils';

const types = createSelector(
  state => state.training.get('types'),
  state => state.app.get('language'),
  (types, language) => {
    const list = types.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), language) }));
  },
);

const pagination = createSelector(
  state => state.training.get('pagination'),
  pagination => pagination.toJS(),
);

const trainings = createSelector(
  state => state.training.get('trainings'),
  trainings => trainings.toJS(),
);

const training = createSelector(
  state => state.training.get('training'),
  training => training.toJS(),
);

const trainer = createSelector(
  state => state.training.get('trainer'),
  trainer => trainer.toJS(),
);

const trainers = createSelector(
  state => state.training.get('trainers'),
  trainers => trainers.toJS(),
);

export const trainingSelectors = {
  types,
  trainings,
  training,
  trainers,
  trainer,
  pagination,
};

const initialState = fromJS({
  trainings: [],
  trainers: [],
  training: {},
  trainer: {},
  types: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  }
});

export const reducer = handleActions(
  {
    TRAINING_FETCH_TRAINING_FULFILLED: (state, { payload }) => state.set('training', fromJS(payload)),
    TRAINING_FETCH_TRAINING_TYPES_FULFILLED: (state, { payload }) => state
      .set('types', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    TRAINING_DELETE_TRAINING_TYPE_FULFILLED: (state, { payload }) => state.update('types', types => fromJS(types.toJS().filter(({ id }) => id !== +payload))),
    TRAININGS_FETCH_TRAINING_FULFILLED: (state, { payload }) => state
      .set('trainings', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    TRAINING_FETCH_TRAINERS_FULFILLED: (state, { payload }) => state
      .set('trainers', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
  },
  initialState
);

export const trainingActions = {
  fetchTrainings: createAction('TRAININGS_FETCH_TRAINING', params => api.get('trainings', { params })),
  fetchTraining: createAction('TRAINING_FETCH_TRAINING', id => api.get(`training/${id}`)),
  createTraining: createAction('TRAINING_TRAINING_CREATE', props => api.post('trainings/create', { ...props })),
  updateTraining: createAction('TRAINING_TRAINING_UPDATE', (id, props) => api.put(`trainings/update/${id}`, { ...props })),
  deleteTraining: createAction('TRAINING_DELETE', props => api.post('trainings/delete', { ...props })),
  
  fetchTrainingTypes: createAction('TRAINING_FETCH_TRAINING_TYPES', props => api.get('trainings/types', props)),
  fetchTrainingType: createAction('TRAINING_FETCH_TRAINING_TYPE', id => api.get('trainings/types/details', { params: { id } })),
  deleteTrainingType: createAction('TRAINING_DELETE_TRAINING_TYPE', props => api.post('trainings/types/delete', { ...props })),
  createTrainingType: createAction('TRAINING_TRAINING_TYPE_CREATE', props => api.post('trainings/types/create', { ...props })),
  updateTrainingType: createAction('TRAINING_TYPE_UPDATE', (props, id) => api.put(`trainings/types/edit/${id}`, { ...props })),
  
  fetchTrainers: createAction('TRAINING_FETCH_TRAINERS', params => api.get('trainings/trainers', { params })),
  fetchTrainer: createAction('TRAINING_FETCH_TRAINER', id => api.get('trainings/trainers/details', { params: { id } })),
  deleteTrainer: createAction('TRAINING_DELETE_TRAINER', props => api.post('trainings/trainers/delete', { ...props })),
  createTrainer: createAction('TRAINING_TRAINER_CREATE', props => api.post('trainings/trainers/create', { ...props })),
  updateTrainer: createAction('TRAINING_TRAINER_UPDATE', (props, id) => api.put(`trainings/trainers/edit/${id}`, { ...props })),
  
  createReport: createAction('TRAINING_CREATE_REPORT', props => api.post('trainings/export', props, { responseType: 'blob' })),
};
