export const validateEventForm = (values, message) => {
  const errors = {};
  
  if (!values.name) {
    errors.name = message;
  }
  
  if (!values.typeId) {
    errors.typeId = message;
  }
  
  if (!values.countryId) {
    errors.countryId = message;
  }
  
/*  if (!values.cityId) {
    errors.cityId = message;
  }
  
  if (!values.villageId) {
    errors.villageId = message;
  }*/
  
  if (!values.startDate) {
    errors.startDate = message;
  }
  
  if (!values.endDate) {
    errors.endDate = message;
  }
  
  return errors;
};

export const validateReportForm = (values, message) => {
  const errors = {};
  if (!values.name) {
    errors.name = message;
  }
  
  return errors;
};