import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '../../../_shared/headers';
import { GridList } from '../../../_shared';
import { useDispatchHook } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';

const Properties = () => {
  const { t }= useTranslation('');
  
  const properties = useSelector(administrationSelectors.properties);
  const fetchProperties = useDispatchHook(administrationActions.fetchProperties);
  const deleteProperty = useDispatchHook(administrationActions.deleteProperty);
  
  const columns = [
    { field: 'name', headerName: t('events.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);
  
  return (
    <Container fluid>
      <PageHeader title={t('properties.header', { count: properties.length })} addLink="layout.addProperty" />
      <GridList
        actions={{
          delete: deleteProperty,
          edit: 'layout.editProperty',
        }}
        columns={columns}
        rows={properties}
      />
    </Container>
  );
};

export default Properties;
