import React from 'react';
import { TextField } from 'mui-rff';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const Form = () => {
  const { t } = useTranslation();
  return (
    <Column expanded>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('invitations.generalData')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('forms.subject')}
              name="name"
              margin="none"
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="note-textarea"
              label={t('forms.note')}
              name="description"
              multiline
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Column>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default Form;