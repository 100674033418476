import React from 'react';
import { Chart } from 'react-google-charts';
import groupBy from 'lodash/groupBy';

import { filedToCurrentLanguage } from '../../../../utils';

type Props = {
  filteredData: [],
  label: string,
  title: string,
  shortTitle: string,
}

export const GeneralChart = ({ filteredData, label, title , shortTitle}: Props) => {
  const services = groupBy(filteredData, 'year');
  const filteredServices = Object.keys(services).map(service => service);
  const legend = [label, ...filteredServices];
  const filteredEntries = groupBy(filteredData, 'name');
  
  const percentage = Object.keys(filteredEntries).map(item => {
    const total = filteredEntries[item].reduce((sum, i) => {
      return sum + i.dataCount;
    }, 0);
    return [filedToCurrentLanguage(item), total];
  });
  
  const years = Object.keys(filteredEntries).map(k => {
    const dataPerYears = filteredServices.map(y => {
      const entry = filteredEntries[k].find(({year}) => year === +y);
      return entry ? entry.dataCount : 0;
    });
    return [filedToCurrentLanguage(k), ...dataPerYears];
  });
  
  return (
    <>
      <Chart
        width="100%"
        height={300}
        chartType="ColumnChart"
        loader={<div>Loading report</div>}
        data={[
          legend,
          ...years
        ]}
        options={{
          title,
          chartArea: { width: '40%' },
          hAxis: {
            title: shortTitle,
            minValue: 0,
          },
          vAxis: {
            title: null,
          },
        }}
        legendToggle
      />
    
      <Chart
        width="100%"
        height={'400px'}
        chartType="PieChart"
        loader={<div>Loading report</div>}
        data={[
          [label, 'Inregistrari'],
          ...percentage,
        ]}
        options={{
          title,
          // Just add this option
          is3D: true,
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </>
  );
};