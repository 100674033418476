import React, { useCallback, useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, List, ListItem, Collapse, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon, Home as HomeIcon, DoubleArrow as DoubleArrowIcon, Message as MessageIcon, Assignment as AssignmentIcon, Accessibility as AccessibilityIcon, Drafts as DraftsIcon, ExpandLess, ExpandMore, SupervisorAccount as SupervisorAccountIcon, Business as BusinessIcon, Forum as ForumIcon } from '@material-ui/icons';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import styled from '@emotion/styled';

import { useRoute } from '../../../utils/hooks';
import { authSelectors } from '../../../redux/auth';
import { membersSelectors } from '../../../redux/members';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

type Props = {
  leftSideStatus: boolean,
}

export const MainListItems = ({ leftSideStatus }: Props) => {
  const { t } = useTranslation('');
  const classes = useStyles();
  const [open, setOpen] = useState({});
  const moduleAccess = useSelector(authSelectors.moduleAccess);
  const user = useSelector(authSelectors.user);

  const { navigateTo, route } = useRoute();
  
  const handleChange = useCallback(state => {
    setOpen({ [state]: !open[state] });
  }, [setOpen, open]);
  
  const makeLink = useCallback((to, text, params = {}) => {
    const active = route.name === to && isEqual(params, route.params);

    return (
      <ListItem button className={classes.nested} onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        navigateTo(to, params, { replace: false });
      }} selected={active}>
        <ListItemIcon>
          <DoubleArrowIcon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  }, [navigateTo, route, classes]);
  
  const handleLogout = useCallback(async () => {
    await reactLocalStorage.remove('refreshToken');
    await reactLocalStorage.remove('accessToken');
    setOpen({});
    navigateTo('auth', {}, {replace: false});
  }, [navigateTo]);
  
  useEffect(() => {
    if (!leftSideStatus) {
      setOpen({});
    }
  }, [leftSideStatus]);

  return (
    <List
      component="nav"
      className={classes.root}
    >
      <ListItem
        button
        selected={route.name === 'layout.home'}
        onClick={() => {
          navigateTo('layout.home', {}, {replace: false});
          setOpen({});
        }}
      >
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={t('header.home')} />
      </ListItem>
      {(get(moduleAccess, 'admin', false) || +get(user, 'isAdmin')) ? (
        <>
          <ListItem button onClick={() => handleChange('administration')}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary={t('administration.header')} />
            {get(open, 'administration', false) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={get(open, 'administration', false)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {makeLink('layout.subsidies', t('administration.subsidies'))}
              {makeLink('layout.properties', t('administration.properties'))}
              {makeLink('layout.activityDirections', t('addMember.activityDirections'))}
              {makeLink('layout.itemCategories', t('addMember.itemCategories'))}
              {makeLink('layout.legalForms', t('addMember.legalForms'))}
              {makeLink('layout.services', t('administration.services'))}
              {makeLink('layout.users', t('administration.users'))}
            </List>
          </Collapse>
        </>
      ) : null}
      
      {get(moduleAccess, 'member', false) && (
        <>
          <ListItem button onClick={() => handleChange('companies')}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={t('member.companies')} />
            {get(open, 'companies', false) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={get(open, 'companies', false)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {makeLink('layout.members', t('header.company'), { isMember: true })}
              {makeLink('layout.members', t('header.economicalAgents'), { isExcluded: true })}
              {makeLink('layout.addMember', t('buttons.add'))}
            </List>
          </Collapse>
        </>
      )}
      
      {get(moduleAccess, 'event', false) && (
        <>
          <ListItem button onClick={() => handleChange('events')}>
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary={t('header.events')} />
            {get(open, 'events', false) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={get(open, 'events', false)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {makeLink('layout.events', t('header.economicMissions'))}
              {makeLink('layout.addEvent', t('buttons.add'))}
              {makeLink('layout.participants', t('common.participants'), { module: 'event' })}
              {makeLink('layout.eventTypes', t('events.manageTypeOfEvents'))}
            </List>
          </Collapse>
        </>
      )}
  
      {get(moduleAccess, 'lobby', false) && (
        <>
          <ListItem button onClick={() => handleChange('lobby')}>
            <ListItemIcon>
              <AccessibilityIcon />
            </ListItemIcon>
            <ListItemText primary={t('header.lobby')} />
            {get(open, 'lobby', false) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={get(open, 'lobby', false)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {makeLink('layout.lobbies', t('header.lobbies'))}
              {makeLink('layout.addLobby', t('buttons.add'))}
              {makeLink('layout.participants', t('common.participants'), { module: 'lobby' })}
              {makeLink('layout.lobbyTypes', t('lobby.manageTypeOfLobby'))}
            </List>
          </Collapse>
        </>
      )}
  
      {get(moduleAccess, 'training', false) && (
        <>
          <ListItem button onClick={() => handleChange('training')}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={t('header.training')} />
            {get(open, 'training', false) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={get(open, 'training', false)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {makeLink('layout.trainers', t('training.trainers'))}
              {makeLink('layout.trainings', t('training.seminars'))}
              {makeLink('layout.participants', t('common.participants'), { module: 'training' })}
              {makeLink('layout.trainingTypes', t('training.manageTypeOfTraining'))}
            </List>
          </Collapse>
        </>
      )}
  
      {get(moduleAccess, 'invitation', false) && (
        <ListItem
          button
          onClick={() => {
            navigateTo('layout.invitations', { module: 'member' }, {replace: false});
            setOpen({});
          }}
          selected={route.name === 'layout.invitations'}
        >
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText primary={t('header.invitations')} />
        </ListItem>
      )}
  
      {get(moduleAccess, 'request', false) && (
        <ListItem
          button
          onClick={() => {
            navigateTo('layout.requests', {}, {replace: false});
            setOpen({});
          }}
          selected={route.name === 'layout.requests'}
        >
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText primary={t('requests.header')} />
        </ListItem>
      )}
      
      <ListItem
        button
        onClick={handleLogout}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={t('header.exit')} />
      </ListItem>
    </List>
  );
};

export const SecondaryListItems = () => {
  const { t } = useTranslation('');
  const { navigateTo } = useRoute();
  
  const requestsByStatus = useSelector(membersSelectors.requestsByStatus);
  const moduleAccess = useSelector(authSelectors.moduleAccess);
  if (!requestsByStatus.length || !get(moduleAccess, 'request', false)) {
    return null;
  }
  return (
    <div>
      <ListSubheader inset>{t('requests.waitingRequests')}</ListSubheader>
      {requestsByStatus.map(request => (
        <RequestItem button onClick={() => navigateTo('layout.request', { requestId: request.id }, {replace: false})}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={request.name} />
        </RequestItem>
      ))}
    </div>
  );
};

const RequestItem = styled(ListItem)`
  overflow: hidden;
  max-width: 290px;
`;