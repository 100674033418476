import React, { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, MenuItem, Typography} from '@material-ui/core';
import {Radios, Select, TextField} from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Field, FormSpy } from 'react-final-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import styled from '@emotion/styled';
import get from 'lodash/get';
import map from 'lodash/map';
import { toastr } from 'react-redux-toastr';
import { Form as FormWrapper } from 'react-final-form';

import { AsyncAutocomplete } from '../../../_shared/components';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { membersActions } from '../../../redux/members';
import { Modal } from '../../../_shared';
import { participantsActions } from '../../../redux/participants';

type Props = {
  initialValues?: { memberName?: string },
  search?: boolean,
}

export const AddParticipantForm = ({ initialValues, search }: Props) => {
  const { t } = useTranslation();
  const [isModalShown, setModalStatus] = useState(false);
  const [participants, setParticipants] = useState([]);
  const { route } = useRoute();
  const fetchMembers = useDispatchHook(membersActions.fetchMembers);
  const getParticipantsByIdno = useDispatchHook(participantsActions.getParticipantsByIdno);
  const assignParticipant = useDispatchHook(participantsActions.assignParticipant);

  const handleChangeParticipant = useCallback(async e => {
      const participantId = get(e, 'target.value');
      if (!participantId) return false;
      try {
          await assignParticipant({ ...route.params, participantId });
          toastr.success(t('common.success'));
          window.history.go(-1);
      } catch (e) {
          toastr.error(get(e, 'message', t('common.error')));
      }
      return true;
  }, [route, t, assignParticipant]);

  return (
    <Row>
      <Col md={12} xs={12}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('addMember.generalData')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={12}>
                <Radios
                  label={t('forms.type')}
                  name="type"
                  formControlProps={{ margin: 'none' }}
                  color="primary"
                  radioGroupProps={{ row: true }}
                  data={[
                    { label: t('member.individual'), value: '1' },
                    { label: t('member.legal'), value: '0' },
                  ]}
                />
              </Grid>
              {search ? (
                  <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item style={{ marginTop: 16 }}>
                          <Button
                              variant="contained"
                              color="secondary"
                              type="button"
                              onClick={() => setModalStatus(true)}
                          >
                              {t('participants.searchByButton', { by: 'IDNO/IDNP' })}
                          </Button>
                      </Grid>
                  </Grid>
              ) : null}
              <FormSpy subscription={{values: true}}>
                {({values}) => +values.type === 0 ? (
                    <Grid item xs={12} md={12}>
                      <Field name="memberId">
                        {props => (
                            <AsyncAutocomplete fetch={fetchMembers} label={t('participants.searchMember')} input={props.input} />
                        )}
                      </Field>
                      {get(initialValues, 'memberName')}
                    </Grid>
                ) : null}
              </FormSpy>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('forms.firstName')}
                  name="firstName"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('forms.lastName')}
                  name="lastName"
                  margin="none"
                  required
                />
              </Grid>
              <FormSpy subscription={{values: true}}>
                {({values}) => +values.type === 0 ? (
                    <Grid item xs={12} md={+values.type === 1 ? 6 : 12}>
                        <TextField
                            label={t('addMember.webPage')}
                            name="webpage"
                            margin="none"
                        />
                    </Grid>
                ) : null}
              </FormSpy>
              <FormSpy subscription={{values: true}}>
                {({values}) => (
                  <Grid item xs={12} md={+values.type === 0 ? 6 : 12}>
                    <TextField
                      label={t('participants.areaOfInterest')}
                      name="areaOfInterest"
                      margin="none"
                    />
                  </Grid>
                )}
              </FormSpy>
              <FormSpy subscription={{values: true}}>
                {({values}) => +values.type === 1 ? null : (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={t('participants.areaOfWork')}
                        name="areaOfWork"
                        margin="none"
                      />
                    </Grid>
                  </>
                )}
              </FormSpy>
              <Grid item xs={12} md={6}>
                <Field name="phone" type="checkbox">
                  {props => (
                    <MuiPhoneNumber
                      data-cy={props.input.name}
                      defaultCountry={'md'}
                      label={t('addMember.phoneNumber')}
                      preferredCountries={['md', 'ro', 'ru']}
                      name={props.input.name}
                      value={props.input.value || initialValues?.phone}
                      onChange={props.input.onChange}
                      required
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('addMember.email')}
                  name="email"
                  type="email"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="note-textarea"
                  label={t('forms.text')}
                  name="description"
                  multiline
                />
              </Grid>
              <FormSpy subscription={{values: true}}>
                {({values}) => +values.type !== 1 ? null : (
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="note-textarea"
                      label={t('forms.address')}
                      name="address"
                      multiline
                    />
                  </Grid>
                )}
              </FormSpy>
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
      <Modal
          isOpen={isModalShown}
          onClose={() => {
            setModalStatus(false);
          }}
          header={t('participants.modalTitle', { by: 'IDNO/IDNP' })}
      >
        <FormWrapper
            onSubmit={async values => {
              try {
                const { value } = await getParticipantsByIdno(values);
                const participantList = get(value, 'participants', []);
                setParticipants(participantList);
                if (!participantList.length) {
                    toastr.error(t('participants.noData'));
                }
              } catch (e) {
                setParticipants([]);
              }
            }}
            render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                          label={t('addMember.name')}
                          name="name"
                          margin="none"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('forms.email')}
                            name="email"
                            type="email"
                            margin="none"
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                          label="IDNO / IDNP"
                          name="idno"
                          type="number"
                          margin="none"
                          onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,13)
                          }}
                      />
                    </Grid>

                    {participants.length ? (
                        <Grid item xs={12} md={12}>
                          <Select
                              name="participantId"
                              label={t('participants.chooseParticipant')}
                              formControlProps={{ margin: 'none' }}
                              required
                              onClick={handleChangeParticipant}
                          >
                            {map(participants, ({id, memberName, firstName, lastName}) => <MenuItem key={id} value={id}>{`${firstName} ${lastName} (${memberName})`}</MenuItem>)}
                          </Select>
                        </Grid>
                    ) : null}

                    <Grid item style={{ marginTop: 16 }}>
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting}
                      >
                        {t('buttons.search')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
            )}
        />
      </Modal>
    </Row>
  )
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;
