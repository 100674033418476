import React, { useEffect } from 'react';
import {Field, FormSpy} from 'react-final-form';
import { useSelector } from 'react-redux';
import { Select } from 'mui-rff';
import { Grid, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import get from 'lodash/get';

import { useDispatchHook } from '../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../redux/administration';

type Props = {
  local: boolean,
  withOutCity?: boolean,
}

export const AddressInputs = ({ local, withOutCity }: Props) => {
  const { t } = useTranslation();
  const countries = useSelector(administrationSelectors.countries);
  const cities = useSelector(administrationSelectors.cities);
  const villages = useSelector(administrationSelectors.villages);
  
  const fetchCountries = useDispatchHook(administrationActions.fetchCountries);
  const fetchCities = useDispatchHook(administrationActions.fetchCities);
  const fetchVillages = useDispatchHook(administrationActions.fetchVillages);
  
  useEffect(() => {
    if (local) {
      fetchCities()
    } else {
      fetchCountries();
    }
  }, [fetchCountries, fetchCities, local]);
  
  return (
    <>
      {!local && (
        <Grid item xs={12} md={12}>
          <Field name="countryId">
            {({ input }) => (
              <Select
                name={input.name}
                label={t('forms.country')}
                onChange={(e, { props: { value } }) => {
                  fetchCities(value);
                  input.onChange(e);
                }}
                formControlProps={{ margin: 'none' }}
                required
              >
                {map(countries, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
              </Select>
            )}
          </Field>
        </Grid>
      )}
      {!withOutCity ? (
        <>
          <Grid item xs={12} md={6}>
            <FormSpy subscription={{values: true}}>
              {({values}) => (
                <Field name="cityId">
                  {({ input }) => (
                    <Select
                      name={input.name}
                      label={t('forms.region')}
                      onChange={(e, { props: { value } }) => {
                        fetchVillages(value);
                        input.onChange(e);
                      }}
                      formControlProps={{ margin: 'none' }}
                      disabled={(!get(values, 'countryId') && !local) || !cities.length}
                    >
                      {map(cities, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </Select>
                  )}
                </Field>
              )}
            </FormSpy>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSpy subscription={{values: true}}>
              {({values}) => (
                <Select
                  name="villageId"
                  label={t('forms.town')}
                  formControlProps={{ margin: 'none' }}
                  disabled={!get(values, 'cityId')}
                >
                  {map(villages, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              )}
            </FormSpy>
          </Grid>
        </>
      ) : null}
    </>
  )
};

AddressInputs.defaultProps = {
  local: false,
};