import { combineReducers } from 'redux';
import { router5Reducer as router } from 'redux-router5';

import { reducer as app } from './app';
import { reducer as auth } from './auth';
import { reducer as members } from './members';
import { reducer as events } from './events';
import { reducer as fairs } from './fairs';
import { reducer as training } from './training';
import { reducer as administration } from './administration';
import { reducer as reports } from './reports';
import { reducer as invitations } from './invitations';
import { reducer as participants } from './participants';
import { reducer as lobby } from './lobby';

import { reducer as toastr } from 'react-redux-toastr';

const rootReducer = combineReducers({
  router,
  app,
  toastr,
  auth,
  members,
  events,
  fairs,
  training,
  administration,
  reports,
  invitations,
  participants,
  lobby,
});

export default rootReducer;