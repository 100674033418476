import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import { DateField } from '../../../_shared/components';

export const MembershipFeeForm = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState();

  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs={12} md={6}>
        <Field name="date">
          {({ input: { value, onChange } }) => (
            <DateField label={t('dues.date')} onChange={onChange} value={value} />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={12}>
        <Field name="amount">
          {({input}) => (
            <CurrencyTextField
              label={t('forms.amount')}
              variant="standard"
              value={value}
              currencySymbol="MDL"
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
              onChange={(event, value) => {
                setValue(value);
                input.onChange(event);
              }}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};
