import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import get from 'lodash/get';

import { ImageUploader } from '../../../_shared/components';
import { useRoute } from '../../../utils/hooks';
import { GeneralData } from './generalData';
import { AddressData } from './addressData';
import { ContactsData } from './contactsData';
import { DirectionsCategoriesData } from './directionsCategoriesData';

const Form = () => {
  const { t } = useTranslation();
  
  const { route } = useRoute();
  
  return (
    <Row>
      <Col md={6}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('addMember.generalData')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <GeneralData />
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
      <Col md={6}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('addMember.address')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <AddressData />
            </Grid>
          </AccordionDetails>
        </Column>
  
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('addMember.legalAddress')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <AddressData name="legal" />
            </Grid>
          </AccordionDetails>
        </Column>

        <Column expanded>
          <AccordionSummary
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>{t('addMember.contacts')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <ContactsData />
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
      
      <Wrapper md={12}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('addMember.directionsAndCategories')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <DirectionsCategoriesData />
            </Grid>
          </AccordionDetails>
        </Column>
      </Wrapper>
  
      <Wrapper md={12}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('addMember.images')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <ImageUploader isEditing={!!+get(route, 'params.id')} />
            </Grid>
          </AccordionDetails>
        </Column>
      </Wrapper>
    </Row>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

const Wrapper = styled(Col)`
  margin-top: ${({theme}) => theme.block.margin};
`;

export default Form;
