import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';
import map from 'lodash/map';

import api from '../api';
import { filedToCurrentLanguage } from '../../utils';

const types = createSelector(
  state => state.lobby.get('types'),
  state => state.app.get('language'),
  (types, language) => {
    const list = types.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), language) }));
  },
);

const pagination = createSelector(
  state => state.lobby.get('pagination'),
  pagination => pagination.toJS(),
);

const lobbies = createSelector(
  state => state.lobby.get('lobbies'),
  lobbies => lobbies.toJS(),
);

const lobby = createSelector(
  state => state.lobby.get('lobby'),
  lobby => lobby.toJS(),
);

export const lobbySelectors = {
  types,
  lobbies,
  lobby,
  pagination,
};

const initialState = fromJS({
  lobbies: [],
  lobby: {},
  types: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  }
});

export const reducer = handleActions(
  {
    LOBBY_FETCH_LOBBY_FULFILLED: (state, { payload }) => state.set('lobby', fromJS(payload)),
    LOBBY_FETCH_LOBBY_TYPES_FULFILLED: (state, { payload }) => state
      .set('types', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    LOBBY_DELETE_LOBBY_TYPE_FULFILLED: (state, { payload }) => state.update('types', types => fromJS(types.toJS().filter(({ id }) => id !== +payload))),
    LOBBY_LIST_FETCH_LOBBY_FULFILLED: (state, { payload }) => state
      .set('lobbies', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
  },
  initialState
);

export const lobbyActions = {
  fetchLobbies: createAction('LOBBY_LIST_FETCH_LOBBY', params => api.get('lobbies', { params })),
  fetchLobby: createAction('LOBBY_FETCH_LOBBY', id => api.get(`lobby/${id}`)),
  createLobby: createAction('LOBBY_LOBBY_CREATE', props => api.post('lobbies/create', { ...props })),
  updateLobby: createAction('LOBBY_LOBBY_UPDATE', (id, props) => api.put(`lobbies/update/${id}`, { ...props })),
  deleteLobby: createAction('LOBBY_DELETE', props => api.post('lobbies/delete', { ...props })),
  
  fetchLobbyTypes: createAction('LOBBY_FETCH_LOBBY_TYPES', props => api.get('lobbies/types', props)),
  fetchLobbyType: createAction('LOBBY_FETCH_LOBBY_TYPE', id => api.get('lobbies/types/details', { params: { id } })),
  deleteLobbyType: createAction('LOBBY_DELETE_LOBBY_TYPE', props => api.post('lobbies/types/delete', { ...props })),
  createLobbyType: createAction('LOBBY_LOBBY_TYPE_CREATE', props => api.post('lobbies/types/create', { ...props })),
  updateLobbyType: createAction('LOBBY_TYPE_UPDATE', (props, id) => api.put(`lobbies/types/edit/${id}`, { ...props })),
  
  createReport: createAction('LOBBY_CREATE_REPORT', props => api.post('lobbies/export', props, { responseType: 'blob' })),
};
