import React, { useCallback, useState } from 'react';
import {Field, Form as FormWrapper} from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import styled from '@emotion/styled';

import { useDispatchHook } from '../../../utils/hooks';
import { membersActions } from '../../../redux/members';

type Props = {
  searchInitialValues: {},
  setReportState: Function,
}

export const ReportForm = ({ searchInitialValues, setReportState }: Props) => {
  const { t } = useTranslation('');
  
  const [initialValues, setInitialValues] = useState({
    email: false,
    webPage: false,
    address: false,
    subsidy: false,
    ownershipType: false,
    legalForms: false,
    exportsImports: false,
    paymentOfDues: false,
    activity: false,
    itemCategories: false,
    administrator: false,
    contacts: false,
  });
  
  const createReport = useDispatchHook(membersActions.createReport);
  
  const onSubmit = useCallback(async values => {
    try {
      const { value } = await createReport({
        ...values,
        moduleName: 'member',
        searchParams: searchInitialValues,
      });
      const date = Date.now();
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      toastr.success(t('common.reportWereCreated'));
    } catch (e) {
      console.warn(e);
    }
    setReportState(false);
  }, [t, createReport, setReportState, searchInitialValues]);
  
  const handleChange = useCallback((e, v) => {
    let newObject = {};
    Object.keys(initialValues).map(k => newObject[k] = v);
    setInitialValues(newObject);
  }, [setInitialValues, initialValues]);

  return (
    <Wrapper>
      <FormWrapper
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!Object.values(values).some(a => !a)}
                    onChange={handleChange}
                  />
                }
                label={t('forms.checkAll')}
              />
            </Grid>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="email" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.email')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="webPage" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.webPage')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="address" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.street')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="subsidy" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.subsidy')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="ownershipType" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.ownershipType')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="legalForms" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.legalForms')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="exportsImports" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.exportsImports')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="paymentOfDues" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.paymentOfDues')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="activity" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.activityDirections')}
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="itemCategories" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.itemCategories')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="administrator" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.administrator')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Field name="contacts" type="checkbox">
                      {(props) => (
                        <Checkbox
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                  label={t('addMember.contacts')}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('reports.generate')}
                </Button>
              </Grid>
            </Grid>
            {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;