import React from 'react';
import { TextField } from 'mui-rff';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const FormBlockMember = () => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs={12} md={12}>
        <TextField
          id="note-textarea"
          label={t('forms.note')}
          name="note"
          multiline
        />
      </Grid>
    </Grid>
  );
};