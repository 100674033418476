import React, {useCallback, useState} from 'react';
import { Form, Field } from 'react-final-form';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Avatar, Button, CssBaseline, TextField, Paper, Grid, Typography, makeStyles } from '@material-ui/core';
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { authActions } from '../../redux/auth';
import authBg from '../../assets/img/auth.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${authBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation('');
  
  const { navigateTo } = useRoute();
  const [unauthorized, setUnauthorized] = useState(false);
  
  const login = useDispatchHook(authActions.login);
  
  const onSubmit = useCallback(({ email, password }) => {
    setUnauthorized(false);
    login({ email, password }).then(({ value }) => {
      reactLocalStorage.set('refreshToken', value.refresh_token);
      reactLocalStorage.set('accessToken', value.access_token);
      navigateTo('layout.home', {}, {replace: false});
    }).catch(err => {
      setUnauthorized(true);
    });
  }, [login, navigateTo]);
  
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('auth.header')}
          </Typography>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <Field name="email">
                  {({input}) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id={input.name}
                      label={t('auth.email')}
                      name={input.name}
                      onChange={input.onChange}
                      autoComplete="email"
                      autoFocus
                    />
                  )}
                </Field>
                <Field name="password">
                  {({input}) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name={input.name}
                      onChange={input.onChange}
                      label={t('auth.password')}
                      type="password"
                      id={input.name}
                      autoComplete="current-password"
                    />
                  )}
                </Field>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {t('auth.login')}
                </Button>
              </form>
            )}
          />
          {unauthorized && (
            <Grid item xs={12}>
              <Alert severity="error">{t('auth.unauthorized')}</Alert>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginContainer;
