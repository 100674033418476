import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, Grid} from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';

import { PageHeader } from '../../_shared/headers';
import { FormFounder } from './components/formFounder';

const AddFounder = () => {
  const { t } = useTranslation();
  const initialValues = { type: 1 };
  
  return (
    <Container fluid>
      <PageHeader title={t('member.addFounder')} />
      <FormWrapper
        onSubmit={() => alert('submitted')}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormFounder />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddFounder;
