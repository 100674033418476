import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import map from 'lodash/map';
import get from 'lodash/get';

import api from '../api';
import { filedToCurrentLanguage } from '../../utils';

const subsidies = createSelector(
  state => state.administration.get('subsidies'),
  state => state.app.get('language'),
  state => state.router.route,
  (subsidies, language, route) => {
    const list = subsidies.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), get(route, 'params.lng', language)) }));
  },
);

const properties = createSelector(
  state => state.administration.get('properties'),
  state => state.app.get('language'),
  state => state.router.route,
  (properties, language, route) => {
    const list = properties.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), get(route, 'params.lng', language)) }));
  },
);

const services = createSelector(
  state => state.administration.get('services'),
  state => state.app.get('language'),
  state => state.router.route,
  (services, language, route) => {
    const list = services.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), get(route, 'params.lng', language)) }));
  },
);

const legalForms = createSelector(
  state => state.administration.get('legalForms'),
  state => state.app.get('language'),
  state => state.router.route,
  (legalForms, language, route) => {
    const list = legalForms.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), get(route, 'params.lng', language)) }));
  },
);

const activityDirections = createSelector(
  state => state.administration.get('activityDirections'),
  state => state.app.get('language'),
  state => state.router.route,
  (activityDirections, language, route) => {
    const list = activityDirections.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), get(route, 'params.lng', language)) }));
  },
);

const itemCategories = createSelector(
  state => state.administration.get('itemCategories'),
  state => state.app.get('language'),
  state => state.router.route,
  (itemCategories, language, route) => {
    const list = itemCategories.toJS();
    return map(list, item => ({ ...item, name: filedToCurrentLanguage(get(item, 'name'), get(route, 'params.lng', language)) }));
  },
);

const pagination = createSelector(
  state => state.administration.get('pagination'),
  pagination => pagination.toJS(),
);

const countries = createSelector(
  state => state.administration.get('countries'),
  countries => countries.toJS(),
);

const cities = createSelector(
  state => state.administration.get('cities'),
  cities => cities.toJS(),
);

const villages = createSelector(
  state => state.administration.get('villages'),
  villages => villages.toJS(),
);

const users = createSelector(
  state => state.administration.get('users'),
  users => users.toJS(),
);

export const administrationSelectors = {
  subsidies,
  properties,
  activityDirections,
  itemCategories,
  legalForms,
  pagination,
  countries,
  cities,
  villages,
  services,
  users,
};

const initialState = fromJS({
  subsidies: [],
  properties: [],
  services: [],
  activityDirections: [],
  itemCategories: [],
  legalForms: [],
  countries: [],
  cities: [],
  villages: [],
  users: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  }
});

export const reducer = handleActions(
  {
    ADMINISTRATION_FETCH_SUBSIDIES_FULFILLED: (state, { payload }) => state.set('subsidies', fromJS(payload)),
    ADMINISTRATION_SUBSIDY_DELETE_FULFILLED: (state, { payload }) => state.update('subsidies', subsidies => fromJS(subsidies.toJS().filter(({ id }) => id !== +payload))),
    
    ADMINISTRATION_FETCH_PROPERTIES_FULFILLED: (state, { payload }) => state.set('properties', fromJS(payload)),
    ADMINISTRATION_PROPERTY_DELETE_FULFILLED: (state, { payload }) => state.update('properties', properties => fromJS(properties.toJS().filter(({ id }) => id !== +payload))),
    
    ADMINISTRATION_FETCH_SERVICES_FULFILLED: (state, { payload }) => state.set('services', fromJS(payload)),
    ADMINISTRATION_SERVICE_DELETE_FULFILLED: (state, { payload }) => state.update('services', properties => fromJS(properties.toJS().filter(({ id }) => id !== +payload))),
  
    ADMINISTRATION_FETCH_LEGAL_FORMS_FULFILLED: (state, { payload }) => state.set('legalForms', fromJS(payload)),
    ADMINISTRATION_LEGAL_FORM_DELETE_FULFILLED: (state, { payload }) => state.update('legalForms', properties => fromJS(properties.toJS().filter(({ id }) => id !== +payload))),
  
    ADMINISTRATION_FETCH_ACTIVITY_DIRECTIONS_FULFILLED: (state, { payload }) => state
      .set('activityDirections', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    ADMINISTRATION_ACTIVITY_DIRECTION_DELETE_FULFILLED: (state, { payload }) => state.update('activityDirections', properties => fromJS(properties.toJS().filter(({ id }) => id !== +payload))),
  
    ADMINISTRATION_FETCH_ITEM_CATEGORIES_FULFILLED: (state, { payload }) => state
      .set('itemCategories', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    ADMINISTRATION_ITEM_CATEGORY_DELETE_FULFILLED: (state, { payload }) => state.update('itemCategories', properties => fromJS(properties.toJS().filter(({ id }) => id !== +payload))),
    ADMINISTRATION_DELETE_USER_FULFILLED: (state, { payload }) => state.update('users', users => fromJS(users.toJS().filter(({ id }) => id !== +payload))),
  
    ADMINISTRATION_FETCH_COUNTRIES_FULFILLED: (state, { payload }) => state.set('countries', fromJS(payload)),
    
    ADMINISTRATION_FETCH_CITIES_FULFILLED: (state, { payload }) => state.set('cities', fromJS(payload)),
    
    ADMINISTRATION_FETCH_VILLAGES_FULFILLED: (state, { payload }) => state.set('villages', fromJS(payload)),
  
    ADMINISTRATION_FETCH_USERS_FULFILLED: (state, { payload }) => state.set('users', fromJS(payload)),
  },
  initialState
);

export const administrationActions = {
  createUser: createAction('ADMINISTRATION_USER_CREATE', props => api.post('user/create', { ...props })),
  fetchUsers: createAction('ADMINISTRATION_FETCH_USERS', () => api.get('users')),
  deleteUser: createAction('ADMINISTRATION_DELETE_USER', props => api.post('user/delete', { ...props })),
  updateUser: createAction('ADMINISTRATION_USER_UPDATE', (props, id) => api.put(`user/update/${id}`, { ...props })),
  
  fetchSubsidies: createAction('ADMINISTRATION_FETCH_SUBSIDIES', () => api.get('subsidies')),
  fetchSubsidy: createAction('ADMINISTRATION_FETCH_SUBSIDY', id => api.get('subsidy', { params: { id } })),
  createSubsidy: createAction('ADMINISTRATION_SUBSIDY_CREATE', props => api.post('subsidies/create', { ...props })),
  deleteSubsidy: createAction('ADMINISTRATION_SUBSIDY_DELETE', props => api.post('subsidies/delete', { ...props })),
  updateSubsidy: createAction('ADMINISTRATION_SUBSIDY_UPDATE', (props, id) => api.put(`subsidies/update/${id}`, { ...props })),
  
  fetchProperties: createAction('ADMINISTRATION_FETCH_PROPERTIES', () => api.get('properties')),
  fetchProperty: createAction('ADMINISTRATION_FETCH_PROPERTY', id => api.get('property', { params: { id } })),
  createProperty: createAction('ADMINISTRATION_PROPERTY_CREATE', props => api.post('properties/create', { ...props })),
  deleteProperty: createAction('ADMINISTRATION_PROPERTY_DELETE', props => api.post('properties/delete', { ...props })),
  updateProperty: createAction('ADMINISTRATION_PROPERTY_UPDATE', (props, id) => api.put(`properties/update/${id}`, { ...props })),
  
  fetchLegalForms: createAction('ADMINISTRATION_FETCH_LEGAL_FORMS', () => api.get('legalForms')),
  fetchLegalForm: createAction('ADMINISTRATION_FETCH_LEGAL_FORM', id => api.get('legalForm', { params: { id } })),
  createLegalForm: createAction('ADMINISTRATION_LEGAL_FORM_CREATE', props => api.post('legalForms/create', { ...props })),
  deleteLegalForm: createAction('ADMINISTRATION_LEGAL_FORM_DELETE', props => api.post('legalForms/delete', { ...props })),
  updateLegalForm: createAction('ADMINISTRATION_LEGAL_FORM_UPDATE', (props, id) => api.put(`legalForms/update/${id}`, { ...props })),
  
  fetchActivityDirections: createAction('ADMINISTRATION_FETCH_ACTIVITY_DIRECTIONS', params => api.get('activityDirections', { params })),
  fetchActivityDirection: createAction('ADMINISTRATION_FETCH_ACTIVITY_DIRECTION', id => api.get('activityDirection', { params: { id } })),
  createActivityDirection: createAction('ADMINISTRATION_ACTIVITY_DIRECTION_CREATE', props => api.post('activityDirections/create', { ...props })),
  deleteActivityDirection: createAction('ADMINISTRATION_ACTIVITY_DIRECTION_DELETE', props => api.post('activityDirections/delete', { ...props })),
  updateActivityDirection: createAction('ADMINISTRATION_ACTIVITY_DIRECTION_UPDATE', (props, id) => api.put(`activityDirections/update/${id}`, { ...props })),
  
  fetchItemCategories: createAction('ADMINISTRATION_FETCH_ITEM_CATEGORIES', params => api.get('itemCategories', { params })),
  fetchItemCategory: createAction('ADMINISTRATION_FETCH_ITEM_CATEGORY', id => api.get('itemCategory', { params: { id } })),
  createItemCategory: createAction('ADMINISTRATION_ITEM_CATEGORY_CREATE', props => api.post('itemCategories/create', { ...props })),
  deleteItemCategory: createAction('ADMINISTRATION_ITEM_CATEGORY_DELETE', props => api.post('itemCategories/delete', { ...props })),
  updateItemCategory: createAction('ADMINISTRATION_ITEM_CATEGORY_UPDATE', (props, id) => api.put(`itemCategories/update/${id}`, { ...props })),
  
  fetchCountries: createAction('ADMINISTRATION_FETCH_COUNTRIES', () => api.get('countries')),
  fetchCities: createAction('ADMINISTRATION_FETCH_CITIES', countryId => api.get('cities', { params: {countryId} })),
  fetchVillages: createAction('ADMINISTRATION_FETCH_VILLAGES', cityID => api.get(`villages/${cityID}`)),
  
  fetchServices: createAction('ADMINISTRATION_FETCH_SERVICES', () => api.get('services')),
  fetchService: createAction('ADMINISTRATION_FETCH_SERVICE', id => api.get('service', { params: { id } })),
  createService: createAction('ADMINISTRATION_SERVICE_CREATE', props => api.post('services/create', { ...props })),
  deleteService: createAction('ADMINISTRATION_SERVICE_DELETE', props => api.post('services/delete', { ...props })),
  updateService: createAction('ADMINISTRATION_SERVICE_UPDATE', (props, id) => api.put(`services/update/${id}`, { ...props })),
  
  fetchServicesProducts: createAction('ADMINISTRATION_SERVICES_PRODUCTS', () => api.get('productsServices'))
};
