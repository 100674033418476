import React, {useCallback, useState, useEffect} from 'react';
import { Button, Grid } from '@material-ui/core';
import { Field, Form as FormWrapper } from 'react-final-form';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useTranslation } from 'react-i18next';
import { TextField } from 'mui-rff';


import { validateRequestStatus } from './validations';
import { useDispatchHook } from '../../../utils/hooks';
import { membersActions } from '../../../redux/members';

type Props = {
  handleStatus: Function,
}

export const ApproveRequestForm = ({ handleStatus }: Props) => {
  const { t } = useTranslation('');
  const [amount, setAmount] = useState(0);
  const [initialValues, setInitialValues] = useState({
    memberNumber: 1,
  });
  const [fee, setFee] = useState(0);
  
  const getMembershipNumber = useDispatchHook(membersActions.getMembershipNumber);
  
  useEffect(() => {
    (async () => {
      const { value } = await getMembershipNumber();
      setInitialValues({ memberNumber: +value + 1 });
    })();
  }, [getMembershipNumber]);
  
  const handleSubmit = useCallback((values) => {
    handleStatus('done', values);
  }, [handleStatus]);
  
  return (
    <FormWrapper
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={errors => validateRequestStatus(errors, t('member.requiredField'))}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label={t('requests.memberNumber')}
                name="memberNumber"
                type="number"
                margin="none"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="startDate"
                name="startDate"
                label={t('requests.startDate')}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/*<Grid item xs={12} md={12}>
              <Field name="amount">
                {({input}) => (
                  <CurrencyTextField
                    label={t('requests.amount')}
                    variant="standard"
                    value={amount}
                    currencySymbol="MDL"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => {
                      setAmount(value);
                      input.onChange(event);
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              <Field name="fee">
                {({input}) => (
                  <CurrencyTextField
                    label={t('requests.fee')}
                    variant="standard"
                    value={fee}
                    currencySymbol="MDL"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => {
                      setFee(value);
                      input.onChange(event);
                    }}
                  />
                )}
              </Field>
            </Grid>*/}
          </Grid>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {t('requests.approve')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
};
