import React from 'react';
import get from 'lodash/get';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, EditRounded as EditIcon } from '@material-ui/icons';

import { Pagination } from '..';
import { CardBox } from './index';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { participantsActions } from '../../redux/participants';

type Props = {
  participants: [],
  pagination: {},
  fetch?: Function,
}

const ItemParticipantsList = ({ participants, pagination, fetch }: Props) => {
  const { t } = useTranslation('');
  
  const { navigateTo } = useRoute();
  
  const deleteParticipant = useDispatchHook(participantsActions.deleteParticipant);
  
  const renderActions = id => {
    return (
      <>
        <IconButton aria-label="delete" onClick={() => navigateTo('layout.editParticipant', { id })}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={async () => {
          await deleteParticipant({ id });
          fetch();
        }}>
          <DeleteIcon />
        </IconButton>
      </>
    );
  };
  
  return (
    <>
      <Wrapper>
        {participants.map(item => {
          const isMember = +get(item, 'isMember', '0') === 1;
          return (
            <CardBox
              key={item.id}
              top={<a href={`mailto: ${get(item, 'email')}`}>{get(item, 'email')}</a>}
              title={`${get(item, 'firstName')} ${get(item, 'lastName')}`}
              subTitle={<span dangerouslySetInnerHTML={{__html: get(item, 'name') || t('member.individual')}} />}
              chip={isMember ? t('addMember.isMember'): null}
              actions={renderActions(item.id)}
            >
              {item.areaOfInterest && (
                <div><b>{t('participants.areaOfInterest')}</b>: {item.areaOfInterest}</div>
              )}
              {item.areaOfWork && (
                <div><b>{t('participants.areaOfWork')}</b>: {item.areaOfWork}</div>
              )}
              {item.phone && (
                <div><b>{t('addMember.phoneNumber')}</b>: {item.phone}</div>
              )}
              {item.email && (
                <div><b>{t('addMember.email')}</b>: {item.email}</div>
              )}
              {item.description && (
                <div><b>{t('member.notices')}</b>: {item.description}</div>
              )}
              {item.webpage && (
                <div><b>{t('addMember.webPage')}</b>: {item.webpage}</div>
              )}
            </CardBox>
          )
        })}
      </Wrapper>
      <Pagination data={pagination} />
    </>
  )
};

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 16px;
  
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default ItemParticipantsList;
