import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import Alert from '@material-ui/lab/Alert';

import { ButtonPrimary } from '../../../_shared';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { invitationsActions } from '../../../redux/invitations';

export const SendInvitation = () => {
  const { t }= useTranslation('');
  const { route } = useRoute();
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sendInvitations = useDispatchHook(invitationsActions.sendInvitation);
  
  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await sendInvitations(route.params);
    toastr.success(t('invitations.success'));
    setStatus(true);
    setIsLoading(false);
  }, [t, sendInvitations, route, setIsLoading]);
  
  if (status) {
    return (
      <AlertWrapper>
        {t('invitations.success')}
      </AlertWrapper>
    );
  }
  
  return (
    <Wrapper>
      <Alert severity="info">
        {isLoading ? 'Procesare' : t('invitations.filterData')}
      </Alert>
      {!isLoading && (
        <>
          <br />
          <ButtonPrimary
            type="button"
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {t('buttons.sendInvitation')}
          </ButtonPrimary>
        </>
      )}
    </Wrapper>
  )
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;

const AlertWrapper = styled(Alert)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

