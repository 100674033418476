import React, {useState, useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

import { PageHeader, ButtonPrimary, GridList, Pagination } from '../../_shared';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { eventsActions, eventsSelectors } from '../../redux/events';
import { EventsSearchForm } from './components/eventsSearchForm';

const EventsContainer = () => {
  const { t }= useTranslation('');
  const { navigateTo, route } = useRoute();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setLoadingState] = useState(false);
  
  const fetchEvents = useDispatchHook(eventsActions.fetchEvents);
  const deleteEvent = useDispatchHook(eventsActions.deleteEvent);
  
  const events = useSelector(eventsSelectors.events);
  const pagination = useSelector(eventsSelectors.pagination);
  
  const columns = [
    { field: 'name', headerName: t('events.name'), width: 50.6, link: { to: 'layout.event', params: { eventId: 'id' }} },
    { field: 'startDate', headerName: t('events.startDate'), width: 27.3 },
  ];
  
  const handleDeleteEvent = useCallback(async props => {
    await deleteEvent({ id: get(props, 'id') });
    toastr.success(t('common.successDeleted'));
    navigateTo('layout.events', { ...get(route, 'params'), page: 1 }, {replace: false});
  }, [deleteEvent, navigateTo, route, t]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoadingState(true);
      await fetchEvents({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    };
    fetchData();
  }, [fetchEvents, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('events.header', { count: pagination.total })}>
        <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={e => setAnchorEl(e.currentTarget)}>
          {t('buttons.actions')}
        </ButtonPrimary>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => navigateTo('layout.addEvent', {}, {replace: false})}>{t('buttons.add')}</MenuItem>
        </Menu>
      </PageHeader>
      <Row>
        <Col xs={12} md={9} lg={8}>
          {!isLoading ? (
            <GridList
              columns={columns}
              rows={events}
              actions={{
                delete: handleDeleteEvent,
                edit: 'layout.editEvent',
              }}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={3} lg={4}>
          <EventsSearchForm />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default EventsContainer;
