import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  MenuItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Field } from 'react-final-form';
import { Select, TextField } from 'mui-rff';
import map from 'lodash/map';

import constants from '../../../../redux/auth/constants';
import { useDispatchHook, useRoute } from '../../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../../redux/administration';
import Switch from "@material-ui/core/Switch";

const Form = () => {
  const { t } = useTranslation();
  const subsidies = useSelector(administrationSelectors.subsidies);
  const fetchSubsidies = useDispatchHook(administrationActions.fetchSubsidies);
  
  const { route } = useRoute();
  useEffect(() => {
    (async () => {
      await fetchSubsidies();
    })();
  }, [fetchSubsidies]);
  
  return (
    <Column expanded>
      <AccordionDetails>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('users.name')}
              name="name"
              margin="none"
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('forms.email')}
              name="email"
              type="email"
              margin="none"
              disabled={route.name.includes('edit')}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label={t('forms.password')}
              name="password"
              type="password"
              margin="none"
              required
            />
          </Grid>
  
          <Grid item xs={12} md={6}>
            <Field name="subsidyId">
              {({ input }) => (
                <Select
                  name="subsidyId"
                  label={t('addMember.subsidy')}
                  onChange={(e) => {
                    input.onChange(e);
                  }}
                  formControlProps={{ margin: 'none' }}
                  required
                >
                  {map(subsidies, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              )}
            </Field>
          </Grid>
  
          <Grid item xs={12} md={12}>
            <FormControlLabel
              label={t('common.hasEditRestrictions')}
              control={
                <Field name="editRestrictions" type="checkbox">
                  {props => (
                    <Switch
                      color="primary"
                      checked={props.input.checked}
                      name={props.input.name}
                      value={props.input.value}
                      onChange={(e, v) => props.input.onChange(e)}
                    />
                  )}
                </Field>
              }
            />
          </Grid>
  
          <Grid item xs={12} md={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t('users.modules')}</FormLabel>
              <FormGroup>
                {constants.modulesAccess.map(i => (
                  <FormControlLabel
                    control={
                      <Field name={`moduleAccess[${i}]`}>
                        {(props) => (
                          <Checkbox
                            checked={props.input.value}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={(e) => props.input.onChange(e)}
                          />
                        )}
                      </Field>
                    }
                    label={t(`module.${i}`)}
                  />
                ))}
                
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Column>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default Form;
