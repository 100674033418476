import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { Menu, MenuItem } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';

import { PageHeader } from '../../_shared/headers';
import { PaperBox } from '../../_shared/components';
import { ButtonPrimary, Modal } from './../../_shared';
import { filedToCurrentLanguage } from '../../utils';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { membersActions } from '../../redux/members';
import { ApproveRequestForm, GeneratePaymentTicket } from './components';
import { useMember } from '../../hooks';
import CONFIG from '../../config';

const RequestContainer = () => {
  const { t } = useTranslation();
  const { route: { params }, navigateTo } = useRoute();
  
  const fetchRequest = useDispatchHook(membersActions.fetchRequest);
  const updateRequestStatus = useDispatchHook(membersActions.updateRequestStatus);
  
  const [request, setRequest] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, updateStatus] = useState(null);
  const [isModalShown, setModalStatus] = useState(false);
  const [generatePaymentTicketStatus, setGeneratePaymentTicketStatus] = useState(false);
  
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleGeneratePaymentTicket = () => {
    setModalStatus(true);
    setGeneratePaymentTicketStatus(true);
  };
  
  const { handleDownloadCard, handleDownloadTicket } = useMember();
  
  useEffect(() => {
    (async() => {
      const { value } = await fetchRequest(get(params, 'requestId'));

      let address;
      let social;
      try {
        address = JSON.parse(get(value, 'request.address'));
        social = JSON.parse(get(value, 'request.social'));
      } catch (e) {
        console.warn(e);
        address = null;
        social = null;
      }
      setRequest({
        ...value.request,
        activity: value.activity,
        categories: value.categories,
        priorityCountries: value.priorityCountries,
        services: value.services,
        address,
        social,
        member: value.member
      });
    })();
  }, [fetchRequest, params, status]);

  const rows = [
    {
      name: t('addMember.requestNr'),
      value: get(request, 'id'),
    },
    {
      name: t('addMember.name'),
      value: get(request, 'name'),
    },
    {
      name: t('addMember.idno'),
      value: get(request, 'idno'),
    },
    {
      name: t('addMember.membershipCard'),
      value: get(request, 'membershipCard'),
    },
    {
      name: t('addMember.foundationDate'),
      value: get(request, 'registeredDate'),
    },
    {
      name: t('addMember.subsidy'),
      value: filedToCurrentLanguage(get(request, 'subsidy')),
    },
    {
      name: t('dues.date'),
      value: get(request, 'created_at'),
    },
  ];
  let address ;
  try {
    address = join([get(request, 'city'), get(request, 'village'), Object.values(request.address)], ', ');
  } catch (e) {}
  
  let files;
  try {
    files = JSON.parse(get(request, 'files'));
  } catch(e) { files = []; }
  const rows2 = [
    {
      name: t('addMember.ownershipType'),
      value: filedToCurrentLanguage(get(request, 'property')),
    },
    {
      name: t('addMember.legalForms'),
      value: filedToCurrentLanguage(get(request, 'legalForm')),
    },
    {
      name: t('common.requestMemberCCI'),
      value: get(request, 'request') ? t('addEvent.yes') : t('addEvent.no'),
    },
    {
      name: t('common.numberOfEmployees'),
      value: get(request, 'numberOfEmployees'),
    },
    {
      name: t('addMember.legalAddress'),
      value: address,
    },
    {
      name: t('addMember.email'),
      value: get(request, 'email'),
    },
    {
      name: t('addMember.webPage'),
      value: get(request, 'webPage'),
    },
    {
      name: t('addMember.socialLink'),
      value: join(compact(get(request, 'social')), ', '),
    },
    {
      name: t('common.priorityCountries'),
      value: join(map(get(request, 'priorityCountries'), 'name'), ', '),
    },
    {
      name: t('addMember.description'),
      value: get(request, 'description'),
    },
    {
      name: t('common.activity'),
      value: join(map(get(request, 'activity'), ({name}) => filedToCurrentLanguage(name)), ', '),
    },
    {
      name: t('addMember.itemCategories'),
      value: join(map(get(request, 'categories'), ({name}) => filedToCurrentLanguage(name)), ', '),
    },
    {
      name: t('common.services'),
      value: join(map(get(request, 'services'), ({name}) => filedToCurrentLanguage(name)), ', '),
    },
    {
      name: t('requests.files'),
      value: files && files.length ? map(files, ({ path, name }) => <a target="_blank" rel="noopener noreferrer" href={`${CONFIG.api}/${path.replace('public', 'storage')}`}>{name}</a>) : 'Nu a fost atasat',
    },
    {
      name: t('requests.status'),
      value: t(`requests.${get(request, 'status')}`),
    },
  ];

  const handleStatus = useCallback((status, values = { memberNumber: null, startDate: null, endDate: null }) => {
    handleClose();
    setModalStatus(false);
    if (status === 'approved') {
      setModalStatus(true);
      return;
    }
    updateRequestStatus({ status: status === 'done' ? 'approved' : status, uuid: get(request, 'uuid'), ...values });
    updateStatus(status === 'done' ? 'approved' : status);
    toastr.success(t('requests.successUpdated'));
  }, [updateRequestStatus, request, t, setModalStatus]);

  return (
    <Container fluid>
      <PageHeader title={`${get(request, 'firstName')} ${get(request, 'lastName')}`} backLink="hash" />
      <Row>
        {get(request, 'status') === 'waiting' && (
          <Column md={12} xs={12}>
            <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
              {t('buttons.actions')}
            </ButtonPrimary>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleStatus('approved')}>{t('requests.approve')}</MenuItem>
              <MenuItem onClick={() => handleStatus('declined')}>{t('requests.decline')}</MenuItem>
              <MenuItem onClick={handleGeneratePaymentTicket}>{t('requests.generatePaymentTicket')}</MenuItem>
              <MenuItem onClick={() => handleStatus('move')}>{t('requests.move')}</MenuItem>
            </Menu>
          </Column>
        )}
        {get(request, 'status') === 'approved' && (
          <Column md={12} xs={12}>
            <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
              {t('buttons.actions')}
            </ButtonPrimary>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleDownloadCard('card', get(request, 'uuid'), handleClose)}>{t('requests.downloadCard')}</MenuItem>
              <MenuItem onClick={() => handleDownloadCard('patent', get(request, 'uuid'), handleClose)}>{t('requests.downloadPatent')}</MenuItem>
              <MenuItem onClick={() => handleDownloadTicket(params, get(request, 'uuid'), handleClose)}>{t('requests.downloadPaymentTicket')}</MenuItem>
              <MenuItem onClick={() => handleStatus('move')}>{t('requests.move')}</MenuItem>
            </Menu>
          </Column>
        )}
        <Column md={12} xs={12}>
          {!isEmpty(request.member) && <AlertWrapper severity="info">{t('common.dataWereFound')}: <span onClick={() => navigateTo('layout.member', { memberId: get(request, 'member.id') }, {replace: false})}>{get(request, 'member.name')}</span></AlertWrapper>}
          <PaperBox
            rows={rows}
          />
        </Column>
        <Column md={12} xs={12}>
          <PaperBox rows={rows2} />
        </Column>
      </Row>
      <Modal
        isOpen={isModalShown}
        onClose={() => {
          setModalStatus(false);
          setGeneratePaymentTicketStatus(false);
        }}
        header={t('requests.approveActions')}
      >
        {
          generatePaymentTicketStatus ?
            (
                <GeneratePaymentTicket
              id={get(request, 'uuid')}
              handleDownloadTicket={handleDownloadTicket}
              setGeneratePaymentTicketStatus={setGeneratePaymentTicketStatus}
              setModalStatus={setModalStatus}
            />) :
            (<ApproveRequestForm handleStatus={handleStatus} />)
        }
      </Modal>
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

const AlertWrapper = styled(Alert)`
  margin-bottom: ${({theme}) => theme.block.margin};
  span {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export default RequestContainer;
