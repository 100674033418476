import React, { useEffect } from 'react';
import { FormSpy, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Grid, MenuItem } from '@material-ui/core';
import { TextField, Select } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import get from 'lodash/get';

import { useDispatchHook } from '../../../utils/hooks';
import { administrationSelectors, administrationActions } from '../../../redux/administration';

type Props = {
  name?: string,
}

export const AddressData = ({ name }: Props) => {
  const { t } = useTranslation();
  
  const cities = useSelector(administrationSelectors.cities);
  const villages = useSelector(administrationSelectors.villages);
  
  const fetchCities = useDispatchHook(administrationActions.fetchCities);
  const fetchVillages = useDispatchHook(administrationActions.fetchVillages);
  
  useEffect(() => {
    fetchCities();
  }, [fetchCities]);
  
  return (
    <>
      {!name && (
        <>
          <Grid item xs={12} md={6}>
            <Field name="cityId">
              {({ input }) => (
                <Select
                  name="cityId"
                  label={t('addMember.region')}
                  onChange={(e, { props: { value } }) => {
                    fetchVillages(value);
                    input.onChange(e);
                  }}
                  formControlProps={{ margin: 'none' }}
                  required
                >
                  {map(cities, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSpy subscription={{values: true}}>
              {({values}) => (
                <Select
                  name="villageId"
                  label={t('addMember.town')}
                  formControlProps={{ margin: 'none' }}
                  disabled={!get(values, 'cityId')}
                  required
                >
                  {map(villages, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              )}
            </FormSpy>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={6}>
        <TextField
          label={t('addMember.street')}
          name={name ? `${name}_street` : 'street'}
          margin="none"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('addMember.home')}
          name={name ? `${name}_home` : 'home'}
          margin="none"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('addMember.postCode')}
          name={name ? `${name}_postCode` : 'postCode'}
          margin="none"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('addMember.office')}
          name={name ? `${name}_office` : 'office'}
          margin="none"
        />
      </Grid>
    </>
  );
};