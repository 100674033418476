import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Provider } from 'react-redux';

import './i18n/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import store from './store';
import { router } from './router';
import { appActions } from './redux/app';
import { authSelectors, authActions } from './redux/auth'
import { setupApiInstances } from './redux/api';
import App from './App';

async function render() {
  setupApiInstances({ store });
  router.setDependencies({ store, authSelectors, authActions });
  
  const defaultLanguage = reactLocalStorage.get('language', 'ro');
  store.dispatch(appActions.setLanguage(defaultLanguage));
  
  router.start(() => {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback="Loading...">
          <App/>
        </Suspense>
      </Provider>,
      document.getElementById('root')
    );
  });
}

window.addEventListener('DOMContentLoaded', render);