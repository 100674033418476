import React  from 'react';
import { Grid } from '@material-ui/core';
import { TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import ChipInput from "material-ui-chip-input";
import InputAdornment from '@material-ui/core/InputAdornment';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import LinkedIn from '@material-ui/icons/LinkedIn';

export const ContactsData = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <Grid item xs={12} md={6}>
        <Field name="phone">
          {props => (
            <TextField
              margin="none"
              required
              fullWidth
              name={props.input.name}
              onChange={props.input.onChange}
              label={t('addMember.phoneNumber')}
              id={props.input.name}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('addMember.webPage')}
          placeholder="ex: www.cci.md"
          name="webPage"
          margin="none"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Field name="email">
          {({ input: { value, onChange } }) => (
            <ChipInput
              value={value}
              style={{ width: '100%' }}
              label={t('addMember.email')}
              onAdd={(newVal) => {
                const newArr = [...value, newVal];
                onChange(newArr);
              }}
              onDelete={(deletedVal) => {
                const newArr = value.filter((state) => state !== deletedVal);
                onChange(newArr);
              }}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          id="facebook"
          name="social[0]"
          label="Facebook"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Facebook />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          id="instagram"
          name="social[1]"
          label="Instagram"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Instagram />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          id="LinkedIn"
          name="social[2]"
          label="LinkedIn"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkedIn />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
};