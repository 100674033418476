import React from 'react';
import format from "date-fns/format";
import roLocale from "date-fns/locale/ro";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

class RuLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "LLLL", { locale: this.locale });
  }
  
  getDatePickerHeaderText(date) {
    return format(date, "dd MMMM", { locale: this.locale });
  }
}

type Props = {
  label: string,
  value: string,
  onChange: Function,
};

export const DateField = ({ label, value, onChange }: Props) => {
  return (
    <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={roLocale}>
      <DatePicker
        fullWidth
        format="d MMM yyyy"
        variant="inline"
        label={label}
        value={value ? value : new Date()}
        maxDate={new Date()}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
};
