import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, MenuItem } from '@material-ui/core';
import { TextField, Select } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ChipInput from 'material-ui-chip-input';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationSelectors, administrationActions } from '../../../redux/administration';
import { membersSelectors } from '../../../redux/members';

export const GeneralData = () => {
  const { t } = useTranslation();
  
  const { route } = useRoute();
  
  const [checked, setChecked] = useState({
    isMember: false,
    hasServices: false,
    hasMeditation: false,
    hasAttestation: false,
    isDual: false,
    status: true,
  });

  const subsidies = useSelector(administrationSelectors.subsidies);
  const properties = useSelector(administrationSelectors.properties);
  const legalForms = useSelector(administrationSelectors.legalForms);
  const member = useSelector(membersSelectors.member);
  
  const fetchSubsidies = useDispatchHook(administrationActions.fetchSubsidies);
  const fetchProperties = useDispatchHook(administrationActions.fetchProperties);
  const fetchLegalForms = useDispatchHook(administrationActions.fetchLegalForms);
  
  const handleChecked = useCallback((props, e, v) => {
    setChecked({ ...checked, [props.input.name]: v });
    props.input.onChange(e);
  }, [checked, setChecked]);
  
  useEffect(() => {
    fetchSubsidies();
    fetchProperties();
    fetchLegalForms();
  }, [fetchSubsidies, fetchProperties, fetchLegalForms]);
  
  useEffect(() => {
    if (get(route, 'params.id') && !isEmpty(member)) {
      setChecked({
        hasAttestation: !!+get(member, 'hasAttestation', '0'),
        hasMeditation: !!+get(member, 'hasMeditation', '0'),
        hasServices: !!+get(member, 'hasServices', '0'),
        isDual: !!+get(member, 'isDual', '0'),
        isMember: !!+get(member, 'isMember', '0'),
        status: !!+get(member, 'status', '1'),
      });
    }
  }, [member, route]);
  
  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          label={t('addMember.activeStatus')}
          control={
            <Field name="status" type="checkbox">
              {props => (
                <Switch
                  color="primary"
                  checked={checked.status}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, v) => handleChecked(props, e, v)}
                />
              )}
            </Field>
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label={t('addMember.name')}
          name="name"
          margin="none"
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('addMember.idno')}
          name="idno"
          type="number"
          margin="none"
          onInput = {(e) =>{
            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,13)
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('addMember.membershipCard')}
          name="membershipCard"
          type="number"
          margin="none"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="registeredDate"
          name="registeredDate"
          label={t('addMember.registeredDate')}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="foundationDate"
          name="foundationDate"
          label={t('addMember.foundationDate')}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Select
          name="subsidyId"
          label={t('addMember.subsidy')}
          formControlProps={{ margin: 'none' }}
          required
        >
          {map(subsidies, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
        </Select>
      </Grid>
      <Grid item xs={12} md={6}>
        <Select
          name="propertyId"
          label={t('addMember.ownershipType')}
          formControlProps={{ margin: 'none' }}
          required
        >
          {map(properties, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
        </Select>
      </Grid>
      <Grid item xs={12} md={12}>
        <Select
          name="legalFormId"
          label={t('addMember.legalForms')}
          formControlProps={{ margin: 'none' }}
          required
        >
          {map(legalForms, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
        </Select>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          label={t('addMember.isMember')}
          control={
            <Field name="isMember" type="checkbox">
              {props => (
                <Switch
                  color="primary"
                  checked={checked.isMember}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, v) => handleChecked(props, e, v)}
                />
              )}
            </Field>
          }
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Field name="tags">
          {({ input: { value, onChange } }) => (
            <ChipInput
              value={value}
              style={{ width: '100%' }}
              label={t('common.tags')}
              onAdd={(newVal) => {
                const newArr = [...value, newVal];
                onChange(newArr);
              }}
              onDelete={(deletedVal) => {
                const newArr = value.filter((state) => state !== deletedVal);
                onChange(newArr);
              }}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label={t('addMember.description')}
          name="description"
          margin="none"
          multiline
        />
      </Grid>
    </>
  );
};
