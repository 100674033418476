import React, { useCallback } from 'react';
import { Form as FormWrapper } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { TextField } from 'mui-rff';
import styled from '@emotion/styled';
import get from 'lodash/get';

import { useDispatchHook } from '../../../utils/hooks';
import { eventsActions } from '../../../redux/events';
import { validateReportForm } from './validations';

type Props = {
  defaultName: string,
  setGeneratedReport: Function,
  setReportState: Function,
}

export const ReportForm = ({ defaultName, setGeneratedReport, setReportState }: Props) => {
  const { t } = useTranslation();
  
  const initialValues = {
    name: defaultName,
  };
  
  const createReport = useDispatchHook(eventsActions.createReport);
  
  const onSubmit = useCallback(async values => {
    try {
      const { value } = await createReport({
        name: get(values, 'name'),
        moduleName: 'event',
        meta: JSON.stringify({}),
      });
      setGeneratedReport(value);
      toastr.success(t('common.reportWereCreated'));
    } catch (e) {
      console.warn(e);
    }
    setReportState(false);
  }, [t, createReport, setGeneratedReport, setReportState]);
  
  return (
    <Wrapper>
      <FormWrapper
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={errors => validateReportForm(errors, t('member.requiredField'))}
        render={({ handleSubmit, form, submitting, pristine, valid, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <>
              <Grid item xs={12} md={12}>
                <TextField
                  autoFocus
                  label={t('reports.reportName')}
                  name="name"
                  margin="none"
                  required
                />
              </Grid>
            </>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('reports.generate')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;