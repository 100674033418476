import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';

import { authApi } from '../api';
import api from '../api';
import access from './constants';

const isLogged = createSelector(
  state => state.auth.get('user'),
  user => !user.isEmpty(),
);

const user = createSelector(
  state => state.auth.get('user'),
  user => user.toJS(),
);


const moduleAccess = createSelector(
  state => state.auth.get('user'),
  user => {
    
    try {
      const data = user.toJS();
      if (!!+get(data, 'isAdmin')) {
        return access.modulesAccess.reduce((acc, i) => {
          acc[i] = true;
          return acc;
        }, {});
      }
      return JSON.parse(get(data, 'moduleAccess', '{}'));
    } catch (e) {
      return {};
    }
  },
);

export const authSelectors = {
  isLogged,
  moduleAccess,
  user,
};

const initialState = fromJS({
  user: {},
});

export const reducer = handleActions(
  {
    AUTH_LOGIN_FULFILLED: (state, { payload }) => state.set('user', fromJS(payload)),
    AUTH_FETCH_USER_FULFILLED: (state, { payload }) => state.set('users', fromJS(payload)),
    AUTH_FETCH_USER_DETAILS_FULFILLED: (state, { payload }) => state.set('user', fromJS(payload)),
  },
  initialState,
);

export const authActions = {
  login: createAction('AUTH_LOGIN', ({ email, password }) => authApi.post('login', { username: email, password })),
  fetchUser: createAction('AUTH_FETCH_USER', () => api.get('user')),
  fetchUserDetails: createAction('AUTH_FETCH_USER_DETAILS', () => api.get('userDetails')),
  fetchDetails: createAction('AUTH_FETCH_DETAILS', id => api.get(`users/details/${id}`)),
  deleteUser: createAction('AUTH_DELETE_USER', id => api.post('user/delete', { params: { id } })),
};
