import React, { useState, useCallback } from 'react';
import { Field, Form as FormWrapper } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

type Props = {
  handleDownloadTicket: Function,
  setGeneratePaymentTicketStatus: Function,
  setModalStatus: Function,
  id: string,
  withoutTax: bool,
};

export const GeneratePaymentTicket = ({ handleDownloadTicket, setGeneratePaymentTicketStatus, setModalStatus, id, withoutTax }: Props) => {
  const { t } = useTranslation('');
  const [amount, setAmount] = useState(0);
  const [fee, setFee] = useState(0);
  
  const handleSubmit = useCallback(state => {
    handleDownloadTicket({
      amount: get(state, 'amount', 0),
      fee: get(state, 'fee', 0),
    }, id);
    setGeneratePaymentTicketStatus(false);
    setModalStatus(false);
  }, [handleDownloadTicket, setGeneratePaymentTicketStatus, setModalStatus, id]);

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      initialValues={{
        amount: 0,
        fee: 0,
      }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container alignItems="flex-start" spacing={2}>
            {!withoutTax && (
                <Grid item xs={12} md={12}>
                    <Field name="amount">
                        {({input}) => (
                            <CurrencyTextField
                                label={t('requests.amount')}
                                variant="standard"
                                value={amount}
                                currencySymbol="MDL"
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={(event, value) => {
                                    setAmount(value);
                                    input.onChange(event);
                                }}
                            />
                        )}
                    </Field>
                </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Field name="fee">
                {({input}) => (
                  <CurrencyTextField
                    label={t('requests.fee')}
                    variant="standard"
                    value={fee}
                    currencySymbol="MDL"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => {
                      setFee(value);
                      input.onChange(event);
                    }}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {t('requests.generatePaymentTicket')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
};

GeneratePaymentTicket.defaultProps = {
    withoutTax: false,
}
