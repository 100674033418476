import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { trainingActions } from '../../redux/training';
import TypeForm from './components/typeForm';

const EditTrainingType = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  const [initialValues, setInitialValues] = useState({});
  
  const updateTrainingType = useDispatchHook(trainingActions.updateTrainingType);
  const fetchTrainingType= useDispatchHook(trainingActions.fetchTrainingType);
  
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      const { value } = await fetchTrainingType(get(route, 'params.id'));
      if (isEmpty(value)) {
        navigateTo('layout.lobbyTypes', {}, {replace: false});
      }
      let name;
      try {
        name = JSON.parse(get(value, 'name'));
      } catch (e) {
        name = {};
      }
      if (!isCancelled) {
        setInitialValues({name});
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [fetchTrainingType, navigateTo, route]);
  
  const onSubmit = useCallback(async ({ name }) => {
    await updateTrainingType({ name: JSON.stringify(name) }, get(route, 'params.id'));
    navigateTo('layout.trainingTypes', {}, {replace: false});
  }, [updateTrainingType, navigateTo, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('trainingType.edit')} backLink="layout.trainingTypes" />
      <FormWrapper
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <TypeForm />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default EditTrainingType;