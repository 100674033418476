import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import { PageHeader } from '../../_shared/headers';
import { validateEventForm } from './components/validations';
import { eventModelToService } from '../../utils';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { eventsActions } from '../../redux/events';
import Form from './components/form';

const AddEvents = () => {
  const { t } = useTranslation();
  const { navigateTo } = useRoute();
  
  const initialValues = {
    international: '0',
  };
  
  const createEvent = useDispatchHook(eventsActions.createEvent);
  
  const onSubmit = useCallback(async values => {
    try {
      await createEvent(eventModelToService(values));
      toastr.success(t('common.success'));
      navigateTo('layout.events', {}, {replace: false});
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [createEvent, navigateTo, t]);
  
  return (
    <Container fluid>
      <PageHeader title={t('addEvent.header')} />
      <FormWrapper
        onSubmit={onSubmit}
        validate={errors => validateEventForm(errors, t('member.requiredField'))}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddEvents;