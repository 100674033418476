import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { PageHeader, GridList, Pagination } from '../../_shared';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { eventsActions, eventsSelectors } from '../../redux/events';

const EventSubjects = () => {
  const { t }= useTranslation('');
  const { route } = useRoute();
  
  const subjects = useSelector(eventsSelectors.subjects);
  const pagination = useSelector(eventsSelectors.pagination);
  
  const fetchEventSubjects = useDispatchHook(eventsActions.fetchEventSubjects);
  const deleteEventSubject = useDispatchHook(eventsActions.deleteEventSubject);
  
  const columns = [
    { field: 'name', headerName: t('events.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchEventSubjects({
      page: get(route, 'params.page', 1),
    });
  }, [fetchEventSubjects, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('eventsSubject.header', { count: pagination.total })} addLink="layout.addEventSubject" />
      <GridList
        actions={{
          delete: deleteEventSubject,
          edit: 'layout.editEventSubject',
        }}
        columns={columns}
        rows={subjects}
      />
      <Pagination data={pagination} />
    </Container>
  );
};

export default EventSubjects;
