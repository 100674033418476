import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from "lodash/get";
import api from "../api";

const reports = createSelector(
  state => state.reports.get('reports'),
  reports => reports.toJS(),
);

const pagination = createSelector(
  state => state.reports.get('pagination'),
  pagination => pagination.toJS(),
);

export const reportsSelectors = {
  reports,
  pagination,
};

const initialState = fromJS({
  reports: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  },
});

export const reducer = handleActions(
  {
    REPORTS_FETCH_REPORTS_FULFILLED: (state, { payload }) => state
      .set('reports', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
  },
  initialState
);

export const reportsActions = {
  fetchReports: createAction('REPORTS_FETCH_REPORTS', params => api.get('reports', { params })),
};
