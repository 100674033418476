import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {AppBar, Tabs, Tab, Box, MenuItem} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field, Form as FormWrapper } from 'react-final-form';
import { Select } from 'mui-rff';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';

import { Map } from './map';
import { GeneralChart, RequestChart } from './charts';

type TabProps ={
  index: number,
  value: number,
  children: Node,
}

const TabPanel = ({ children, index, value }: TabProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <Box p={3} style={{ background: '#fff' }}>
        {children}
      </Box>
    )}
  </div>
);

type Props = {
  filteredData: {},
  searchValues: {},
  setFilteredData: Function,
}

export const ChartsTabs = ({ filteredData, searchValues, setFilteredData }: Props) => {
  const { t } = useTranslation('');
  
  const [value, setValue] = useState(0);
  const [filterBy, setFilter] = useState(0);
  
  
  const handleFilter = useCallback(async filterBy => {
    setFilter(filterBy);
  }, [setFilter]);
  
  const renderReport = useCallback(() => {
    if (isEmpty(filteredData)) return null;

    let form;
    switch (filterBy) {
      case 'services':
        form = (
          <GeneralChart
            filteredData={get(filteredData, 'services', [])}
            label="Servicii"
            title="Raport dupa produse si servicii"
            shortTitle="Produse / servicii"
          />
        );
        break;
      case 'activity':
        form = (
          <GeneralChart
            filteredData={get(filteredData, 'activity', [])}
            label="Activitati"
            title="Raport dupa activitati"
            shortTitle="Activitati"
          />
        );
        break;
      default:
        form = null;
    }
    return form;
  }, [filterBy, filteredData]);

  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={(e, v) => setValue(v)} aria-label="tabs">
          <Tab label={t('homeCharts.charts')} id="tab-item1" />
          <Tab label={t('homeCharts.requestsChart')} id="tab-item1" />
          <Tab label={t('homeCharts.map')} id="tab-item2" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {!isEmpty(searchValues) && (
          <FormWrapper
            onSubmit={() => {}}
            render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
              <form onSubmit={handleSubmit} noValidate>
                <Field name="filterBy">
                  {({ input }) => (
                    <Select
                      name={input.name}
                      label={t('common.filterBy')}
                      formControlProps={{ margin: 'none' }}
                      required
                      onChange={(e, v) => {
                        handleFilter(v.props.value);
                        input.onChange(e);
                      }}
                    >
                      <MenuItem value="">
                        <em>{t('common.select')}</em>
                      </MenuItem>
                      {map([
                        { id: 'services', name: t('homeCharts.services') },
                        { id: 'activity', name: t('homeCharts.activity') },
                      ], ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </Select>
                  )}
                </Field>
              </form>
            )}
          />
        )}
        
        {isEmpty(filteredData) && <Alert severity="error">{t('homeCharts.alertError')}</Alert>}
        
        {renderReport()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RequestChart />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Map />
      </TabPanel>
    </div>
  );
};

