import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Form as FormWrapper } from 'react-final-form';

import { administrationActions, administrationSelectors } from '../../../redux/administration';
import { useStyles } from '../../../utils/styles';
import { useDispatchHook } from '../../../utils/hooks';
import { HeaderText, ButtonPrimary } from '../../../_shared';

export const ReportsSearchForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  
  const activityDirections = useSelector(administrationSelectors.activityDirections);
  
  const fetchActivityDirections = useDispatchHook(administrationActions.fetchActivityDirections);
  
  useEffect(() => {
    fetchActivityDirections({ noPagination: true });
  }, [fetchActivityDirections]);
  
  return (
    <Wrapper>
      <HeaderText text={t('buttons.filter')} />
      <FormWrapper
        onSubmit={() => alert('search')}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.field}
                  label={t('reports.name')}
                  name="name"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  className={classes.field}
                  multiple
                  id="activity"
                  options={activityDirections}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      name="activity[]"
                      label={t('addMember.activityDirections')}
                    />
                  )}
                />
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.search')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;
