import get from 'lodash/get';
import keys from 'lodash/keys';

export const filedToCurrentLanguage = (field, language) => {
  let name;
  try {
    name = JSON.parse(field);
    name = get(name, language, get(name, get(keys(name), 0)));
  } catch (e) {
    name = null;
  }
  return name;
};