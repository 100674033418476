import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

export const useDispatchHook = action => {
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(action(...args)), [dispatch, action]);
};

export const useRoute = () => {
  const route = useSelector(state => state.router.route);
  const previousRoute = useSelector(state => state.router.previousRoute);
  const navigateTo = useDispatchHook(routerActions.navigateTo);
  return { route, previousRoute, navigateTo };
};
