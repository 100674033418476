import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '../../../_shared/headers';
import { GridList } from '../../../_shared';
import { useDispatchHook } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';

const Users = () => {
  const { t }= useTranslation('');
  
  const users = useSelector(administrationSelectors.users);
  const fetchUsers = useDispatchHook(administrationActions.fetchUsers);
  const deleteUser = useDispatchHook(administrationActions.deleteUser);
  
  const columns = [
    { field: 'email', headerName: t('forms.email'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  
  return (
    <Container fluid>
      <PageHeader title={t('users.header', { count: users.length })} addLink="layout.addUser" />
      <GridList
        actions={{
          delete: deleteUser,
          edit: 'layout.editUser',
        }}
        columns={columns}
        rows={users}
      />
    </Container>
  );
};

export default Users;
