import React, { useEffect, useCallback } from 'react';

import { Grid, MenuItem, FormControlLabel, Switch  } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Select, TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import {Field, Form as FormWrapper, FormSpy} from 'react-final-form';
import ChipInput from 'material-ui-chip-input';
import styled from '@emotion/styled';
import map from 'lodash/map';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { useStyles } from '../../../utils/styles';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';
import { HeaderText, ButtonPrimary } from '../../../_shared';
import { AutoCompleteField } from '../../../_shared/components';

type Props = {
  initialValues: {},
  setInitialValues: Function,
}

export const MembersSearchForm = ({ initialValues, setInitialValues }: Props) => {
  const { t } = useTranslation();
  const { route, navigateTo } = useRoute();
  const classes = useStyles();
  
  const activityDirections = useSelector(administrationSelectors.activityDirections);
  const itemCategories = useSelector(administrationSelectors.itemCategories);
  const subsidies = useSelector(administrationSelectors.subsidies);
  const legalForms = useSelector(administrationSelectors.legalForms);
  const properties = useSelector(administrationSelectors.properties);
  
  const fetchActivityDirections = useDispatchHook(administrationActions.fetchActivityDirections);
  const fetchItemCategories = useDispatchHook(administrationActions.fetchItemCategories);
  const fetchSubsidies = useDispatchHook(administrationActions.fetchSubsidies);
  const fetchLegalForms = useDispatchHook(administrationActions.fetchLegalForms);
  const fetchProperties = useDispatchHook(administrationActions.fetchProperties);
  
  useEffect(() => {
    fetchActivityDirections({ noPagination: true });
    fetchItemCategories({ noPagination: true });
    fetchSubsidies();
    fetchProperties();
    fetchLegalForms();
  }, [fetchActivityDirections, fetchItemCategories, fetchSubsidies, fetchLegalForms, fetchProperties]);
  
  const handleSearch = useCallback(values => {
    const params = get(route, 'params.isMember') && !get(route, 'params.invitationId') ? { ...values, isMember: true } : { ...values, isMember: false };
    navigateTo('layout.members', params, {replace: false});
  }, [route, navigateTo]);
  
  const toArray = useCallback(paramName => {
    const item = get(route, ['params', paramName], null);
    return isArray(item) ? item : item ? [item] : [];
  }, [route]);
  
  useEffect(() => {
    setInitialValues({
      ...get(route, 'params', {}),
      subsidy:  toArray('subsidy'),
      activity:  toArray('activity'),
      itemCategories:  toArray('itemCategories'),
      tags:  toArray('tags'),
    });
  }, [route, setInitialValues, toArray]);

  return (
    <Wrapper>
      <HeaderText text={t('buttons.filter')} />
      <FormWrapper
        onSubmit={handleSearch}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.field}
                  label={t('addMember.name')}
                  name="name"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.field}
                  label={t('addMember.membershipCard')}
                  name="membershipCard"
                  type="number"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.field}
                  label={t('addMember.idno')}
                  name="idno"
                  type="number"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  name="activity"
                  multiple
                  selected={map(get(initialValues, 'activity'), value => +value)}
                  label={t('addMember.activityDirections')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.activityDirections')}
                  options={map(activityDirections, ({ id, name }) => ({ label: name, value: +id }))}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  name="itemCategories"
                  multiple
                  selected={map(get(initialValues, 'itemCategories'), value => +value)}
                  label={t('addMember.itemCategories')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.itemCategories')}
                  options={map(itemCategories, ({ id, name }) => ({ label: name, value: +id }))}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  name="subsidy"
                  multiple
                  selected={map(get(initialValues, 'subsidy'), value => +value)}
                  label={t('addMember.subsidy')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.subsidy')}
                  options={map(subsidies, ({ id, name }) => ({ label: name, value: +id }))}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Select
                  className={classes.select}
                  name="legalForm"
                  label={t('addMember.legalForms')}
                  formControlProps={{ margin: 'none' }}
                >
                  <MenuItem value="">
                    <em>{t('common.select')}</em>
                  </MenuItem>
                  {map(legalForms, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <Select
                  className={classes.select}
                  name="propertyType"
                  label={t('addMember.ownershipType')}
                  formControlProps={{ margin: 'none' }}
                >
                  <MenuItem value="">
                    <em>{t('common.select')}</em>
                  </MenuItem>
                  {map(properties, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <Field name="tags">
                  {({ input: { value, onChange } }) => (
                    <ChipInput
                      value={value}
                      style={{ width: '100%' }}
                      label={t('common.tags')}
                      onAdd={(newVal) => {
                        const newArr = [...value, newVal];
                        onChange(newArr);
                      }}
                      onDelete={(deletedVal) => {
                        const newArr = value.filter((state) => state !== deletedVal);
                        onChange(newArr);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <Label>{t('member.registeredDate')}</Label>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.field}
                  label={t('forms.yearFrom')}
                  name="yearFrom"
                  type="number"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.field}
                  label={t('forms.yearTo')}
                  name="yearTo"
                  type="number"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  label={t('addMember.paidDue')}
                  control={
                    <Field name="paidDue" type="checkbox">
                      {props => (
                        <Switch
                          color="primary"
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e, v) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                />
              </Grid>
  
              <FormSpy subscription={{values: true}}>
                {({values}) => values.paidDue ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Label>{t('addMember.dueDate')}</Label>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.field}
                        label={t('forms.yearFrom')}
                        name="yearDueFrom"
                        type="number"
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.field}
                        label={t('forms.yearTo')}
                        name="yearDueTo"
                        type="number"
                        margin="none"
                      />
                    </Grid>
                  </>
                ) : null}
              </FormSpy>
  
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  label={t('addMember.excludedMembers')}
                  control={
                    <Field name="excludedMembers" type="checkbox">
                      {props => (
                        <Switch
                          color="primary"
                          checked={props.input.checked}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={(e, v) => props.input.onChange(e)}
                        />
                      )}
                    </Field>
                  }
                />
              </Grid>
              
              <FormSpy subscription={{values: true}}>
                {({values}) => values.excludedMembers ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Label>{t('addMember.excludedMembers')}</Label>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.field}
                        label={t('forms.yearFrom')}
                        name="excludedYearFrom"
                        type="number"
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.field}
                        label={t('forms.yearTo')}
                        name="excludedYearTo"
                        type="number"
                        margin="none"
                      />
                    </Grid>
                  </>
                ) : null}
              </FormSpy>
              
              <Grid item style={{ marginTop: 16 }}>
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.search')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;

const Label = styled('p')`
  margin: 8px 0 0 0;
`;