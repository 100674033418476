import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import isEmpty from 'lodash/isEmpty';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteField = props => {
  
  const {
    input,
    label,
    meta,
    placeholder,
    helperText,
    selected = [],
    options = [],
    ...rest
  } = props;
  const { name, onChange, value, multiple, ...restInput } = input;
  
  const error = meta.error || meta.submitError;
  const hasError = error && (meta.dirty || meta.submitFailed);
  if (isEmpty(options)) return null;

  return (
    <Autocomplete
      {...rest}
      disableCloseOnSelect
      multiple={multiple}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      options={options}
      defaultValue={
        multiple
          ? options.filter(option => selected.includes(option.value))
          : options.find(option => option.value === value)
      }
      onChange={
        multiple
          ? (_e, values) => {
            onChange(values.map(option => option.value));
          }
          : (_e, option) => onChange(option.value)
      }
      getOptionLabel={option => option.label}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          {...restInput}
          label={label}
          placeholder={placeholder || ""}
          error={hasError}
          fullWidth
          variant="standard"
        />
      )}
    />
  );
};

export default AutoCompleteField;
