import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import get from 'lodash/get';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { membersSelectors } from '../../../redux/members';
import { importTypes } from './constants';

export const MemberImports = () => {
  const { t } = useTranslation('');
  const imports = useSelector(membersSelectors.imports);

  return (
    <Wrapper>
      {map(imports, row => (
        <Article key={get(row, 'id')}>
          <Name>{get(row, 'name')}<p>{get(row, 'amount')}</p></Name>
          <div>{t(`constants.${importTypes[get(row, 'type')]}`)}</div>
        </Article>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  width: 100%;
`;

const Article = styled('div')`
  display: flex;
  width: 100%;
  border-left: ${({theme}) => theme.block.boldBorder};
  margin: 8px 0;
  padding-left: ${({theme}) => theme.block.margin};
`;

const Name = styled('div')`
  flex: 0 0 33.33%;
  font-weight: bold;
  padding-right: ${({theme}) => theme.block.margin};
  p {
    font-weight: normal;
    font-size: ${({theme}) => theme.fontSize.small};
    margin: 0;
  }
 `;

