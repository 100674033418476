import React from 'react';
import styled from '@emotion/styled';
import Paper from '@material-ui/core/Paper';
import map from 'lodash/map';

type Props = {
  rows: {
    name: string,
    value: string,
  }[],
}

export const PaperBox = ({ rows }: Props) => {
  return (
    <PaperWrapper elevation={1}>
      {map(rows, ({ name, value }) => !!value && (
        <Wrapper key={name}>
          <Name>{name}:</Name>
          <Value>{value}</Value>
        </Wrapper>
      ))}
    </PaperWrapper>
  )
};

const PaperWrapper = styled(Paper)`
  padding: ${({theme}) => theme.block.padding};
`;

const Wrapper = styled('div')`
  display: flex;
  padding-top: ${({theme}) => theme.block.padding};
  padding-bottom: ${({theme}) => theme.block.padding};
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Name = styled('div')`
  padding-right: ${({theme}) => theme.block.padding};
  font-weight: bold;
  flex: 0 0 250px;
  @media only screen and (max-width: 600px) {
    flex: auto;
  }
`;

const Value = styled('div')`
  color: rgba(0, 0, 0, 0.54);
  white-space: break-spaces;
`;