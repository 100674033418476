import React from 'react';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import { Delete as DeleteIcon, Money as MoneyIcon } from '@material-ui/icons';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

type Props = {
  dues: [],
  onDeleteDue: Function,
  className?: string,
}

export const DuesList = ({ dues, onDeleteDue, className }: Props) => {
  const { t } = useTranslation('');
  if(isEmpty(dues)) return t('member.noDues');
  
  return (
    <DueWrapper className={className}>
      {dues.map(({amount, date, id}) => (
        <ListItem key={id}>
          <ListItemAvatar>
            <Avatar>
              <MoneyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={date}
            secondary={`MDL ${amount}`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => onDeleteDue(id)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </DueWrapper>
  )
};

const DueWrapper = styled(List)`
  border: 1px solid #bdbdbd;
`;
