import React, { useEffect, useCallback } from 'react';

import { Grid, MenuItem} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Select, TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Field, Form as FormWrapper } from 'react-final-form';
import ChipInput from 'material-ui-chip-input';
import styled from '@emotion/styled';
import map from 'lodash/map';

import { useStyles } from '../../../utils/styles';
import { useDispatchHook } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';
import { membersActions } from '../../../redux/members';
import { HeaderText, ButtonPrimary } from '../../../_shared';
import { AutoCompleteField } from '../../../_shared/components';

type Props = {
  setFilteredData: Function,
  setSearchValues: Function,
}

export const FilterForm = ({ setFilteredData, setSearchValues }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  
  const activityDirections = useSelector(administrationSelectors.activityDirections);
  const itemCategories = useSelector(administrationSelectors.itemCategories);
  const subsidies = useSelector(administrationSelectors.subsidies);
  const legalForms = useSelector(administrationSelectors.legalForms);
  const properties = useSelector(administrationSelectors.properties);
  
  const fetchActivityDirections = useDispatchHook(administrationActions.fetchActivityDirections);
  const fetchItemCategories = useDispatchHook(administrationActions.fetchItemCategories);
  const fetchSubsidies = useDispatchHook(administrationActions.fetchSubsidies);
  const fetchLegalForms = useDispatchHook(administrationActions.fetchLegalForms);
  const fetchProperties = useDispatchHook(administrationActions.fetchProperties);
  const filterData = useDispatchHook(membersActions.filterData);
  
  useEffect(() => {
    fetchActivityDirections({ noPagination: true });
    fetchItemCategories({ noPagination: true });
    fetchSubsidies();
    fetchProperties();
    fetchLegalForms();
  }, [fetchActivityDirections, fetchItemCategories, fetchSubsidies, fetchLegalForms, fetchProperties]);
  
  const handleSearch = useCallback(async values => {
    const { value } = await filterData(values);
    setFilteredData(value);
    setSearchValues(values);
  }, [setSearchValues, filterData, setFilteredData]);

  return (
    <Wrapper>
      <HeaderText text={t('buttons.filter')} />
      <FormWrapper
        onSubmit={handleSearch}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={6}>
                <Field
                  name="activity"
                  multiple
                  label={t('addMember.activityDirections')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.activityDirections')}
                  options={map(activityDirections, ({ id, name }) => ({ label: name, value: +id }))}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="itemCategories"
                  multiple
                  label={t('addMember.itemCategories')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.itemCategories')}
                  options={map(itemCategories, ({ id, name }) => ({ label: name, value: +id }))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name="subsidy"
                  multiple
                  label={t('addMember.subsidy')}
                  component={AutoCompleteField}
                  placeholder={t('addMember.subsidy')}
                  options={map(subsidies, ({ id, name }) => ({ label: name, value: +id }))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  className={classes.select}
                  name="legalForm"
                  label={t('addMember.legalForms')}
                  formControlProps={{ margin: 'none' }}
                >
                  <MenuItem value="">
                    <em>{t('common.select')}</em>
                  </MenuItem>
                  {map(legalForms, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  className={classes.select}
                  name="propertyType"
                  label={t('addMember.ownershipType')}
                  formControlProps={{ margin: 'none' }}
                >
                  <MenuItem value="">
                    <em>{t('common.select')}</em>
                  </MenuItem>
                  {map(properties, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <Field name="tags">
                  {({ input: { value, onChange } }) => (
                    <ChipInput
                      value={value}
                      style={{ width: '100%' }}
                      label={t('common.tags')}
                      onAdd={(newVal) => {
                        const newArr = [...value, newVal];
                        onChange(newArr);
                      }}
                      onDelete={(deletedVal) => {
                        const newArr = value.filter((state) => state !== deletedVal);
                        onChange(newArr);
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <Label>{t('member.registeredDate')}</Label>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field name="yearFrom">
                  {({input}) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      type="number"
                      fullWidth
                      id={input.name}
                      label={t('forms.yearFrom')}
                      name={input.name}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field name="yearTo">
                  {({input}) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      type="number"
                      fullWidth
                      id={input.name}
                      label={t('forms.yearTo')}
                      name={input.name}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.search')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;

const Label = styled('p')`
  margin: 8px 0 0 0;
`;
