import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { router5Middleware } from 'redux-router5';
import promise from 'redux-promise-middleware';

import rootReducer from './redux/root-reducer';
import { router } from './router';

const middleware = applyMiddleware(
  promise,
  thunk,
  router5Middleware(router),
);

const initialState = {};

const store = createStore(rootReducer, initialState, composeWithDevTools(middleware));

export default store;
