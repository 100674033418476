import React, { useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import map from 'lodash/map';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import { useDispatchHook } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';
import { membersSelectors } from '../../../redux/members';
import { AutoCompleteField } from '../../../_shared/components';

export const FormImportExport = () => {
  const { t } = useTranslation();
  const imports = useSelector(membersSelectors.imports);
  const groupedByType = groupBy(imports, 'type');
  const countries = useSelector(administrationSelectors.countries);
  const fetchCountries = useDispatchHook(administrationActions.fetchCountries);
  
  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);
  
  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs={12} md={12}>
        <Label>{t(`constants.import`)}</Label>
        <Field
          name="importCountryId"
          multiple
          selected={get(groupedByType, 'import', []).map(({ countryId }) => countryId)}
          label={t('common.countries')}
          component={AutoCompleteField}
          placeholder={t('common.countries')}
          options={map(countries, ({ id, name }) => ({ label: name, value: +id }))}
        />
      </Grid>
  
      <Grid item xs={12} md={12}>
        <Label>{t(`constants.export`)}</Label>
        <Field
          name="exportCountryId"
          multiple
          selected={get(groupedByType, 'export', []).map(({ countryId }) => countryId)}
          label={t('common.countries')}
          component={AutoCompleteField}
          placeholder={t('common.countries')}
          options={map(countries, ({ id, name }) => ({ label: name, value: +id }))}
        />
      </Grid>
    </Grid>
  );
};

const Label = styled('p')`
  margin: 0;
`;