import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import ReduxToastr from 'react-redux-toastr';
import get from 'lodash/get';

import { getComponent } from './router';
import { appSelectors } from './redux/app';
import i18n from './i18n/i18n';
import theme from './theme/default.theme';

const App = () => {
  const route = useSelector(state => state.router.route);
  const language = useSelector(appSelectors.language);
  const component = getComponent(route);

  useEffect(() => {
    i18n.changeLanguage(get(route, 'params.lng', language));
  }, [language, route]);

  const bodyStyles = css`
    body {
      color: ${theme.colors.primary};
      margin: 0;
      padding: 0;
      background-color: ${theme.background.secondary};
      font-size: ${theme.fontSize.md};
      font-family: Roboto, sans-serif;
    }
    a {
      color: ${theme.colors.secondary};
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .MuiFormLabel-root, .MuiTypography-body1, .MuiSelect-root {
      font-size: ${theme.fontSize.md} !important;
    }
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
      background-color: ${theme.background.buttonSecondary} !important;
    }
    .MuiInput-underline:before {
      border-color: ${theme.colors.grey} !important;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 32px !important;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0 !important;
    }
  `;

  return (
    <React.Fragment>
      <Global styles={bodyStyles} />
      <ThemeProvider theme={theme}>
        {component}
      </ThemeProvider>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </React.Fragment>
  );
};

export default App;
