import React, { useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../_shared';
import { GeneratePaymentTicket } from './components';
import {useMember} from "../../hooks";

type Props = {
    isShown: bool,
    request: {
        uuid: string,
    },
    setModalStatus: func,
}

export const PaymentTicketModal = ({ isShown, setModalStatus, request, ...rest }: Props) => {
    const { t } = useTranslation();
    const { handleDownloadMemberTicket } = useMember();
    const [, setGeneratePaymentTicketStatus] = useState(false);
    return (
        <Modal
            isOpen={isShown}
            onClose={() => {
                setModalStatus(false);
            }}
            header={t('requests.generatePaymentTicket')}
        >
            <GeneratePaymentTicket
                id={get(request, 'id')}
                handleDownloadTicket={handleDownloadMemberTicket}
                setGeneratePaymentTicketStatus={setGeneratePaymentTicketStatus}
                setModalStatus={setModalStatus}
                {...rest}
            />
        </Modal>
    )
}
