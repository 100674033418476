import React, {useState, useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

import { PageHeader, ButtonPrimary, GridList, Pagination } from '../../_shared';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { lobbyActions, lobbySelectors } from '../../redux/lobby';
import { SearchForm } from './components/searchForm';

const LobbiesContainer = () => {
  const { t }= useTranslation('');
  const { navigateTo, route } = useRoute();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setLoadingState] = useState(false);
  
  const fetchLobbies = useDispatchHook(lobbyActions.fetchLobbies);
  const deleteLobby = useDispatchHook(lobbyActions.deleteLobby);
  
  const lobbies = useSelector(lobbySelectors.lobbies);
  const pagination = useSelector(lobbySelectors.pagination);
  
  const columns = [
    { field: 'name', headerName: t('lobby.name'), width: 50.6, link: { to: 'layout.lobby', params: { lobbyId: 'id' }} },
    { field: 'date', headerName: t('lobby.startDate'), width: 27.3 },
  ];
  
  const handleDelete = useCallback(async props => {
    await deleteLobby({ id: get(props, 'id') });
    toastr.success(t('common.successDeleted'));
    navigateTo('layout.lobby', { ...get(route, 'params'), page: 1 }, {replace: false});
  }, [deleteLobby, navigateTo, route, t]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoadingState(true);
      await fetchLobbies({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    };
    fetchData();
  }, [fetchLobbies, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('lobby.header', { count: pagination.total })}>
        <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={e => setAnchorEl(e.currentTarget)}>
          {t('buttons.actions')}
        </ButtonPrimary>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => navigateTo('layout.addLobby', {}, {replace: false})}>{t('buttons.add')}</MenuItem>
        </Menu>
      </PageHeader>
      <Row>
        <Col xs={12} md={9} lg={8}>
          {!isLoading ? (
            <GridList
              columns={columns}
              rows={lobbies}
              actions={{
                delete: handleDelete,
                edit: 'layout.editLobby',
              }}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={3} lg={4}>
          <SearchForm />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default LobbiesContainer;
