import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';

import api from '../api';

const pagination = createSelector(
  state => state.participants.get('pagination'),
  pagination => pagination.toJS(),
);

const participants = createSelector(
  state => state.participants.get('participants'),
  participants => participants.toJS(),
);

export const participantsSelectors = {
  participants,
  pagination,
};

const initialState = fromJS({
  participants: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  }
});

export const reducer = handleActions(
  {
    PARTICIPANTS_FETCH_PARTICIPANT_FULFILLED: (state, { payload }) => state
      .set('participants', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
  },
  initialState
);

export const participantsActions = {
  fetchParticipantsByModuleAndId: createAction('PARTICIPANTS_FETCH_PARTICIPANT_BY_MODULE_AND_ID', (module, itemId, page = 1) => api.get(`participants/${module}/${itemId}`, { params: { page } })),
  fetchParticipants: createAction('PARTICIPANTS_FETCH_PARTICIPANT', params => api.get('participants', { params })),
  getParticipantsByIdno: createAction('PARTICIPANTS_GET_BY_IDNO', params => api.get('participants/search', { params })),
  createParticipant: createAction('PARTICIPANTS_CREATE', props => api.post('participants/create', { ...props })),
  updateParticipant: createAction('PARTICIPANTS_UPDATE', (props, id) => api.put(`participants/update/${id}`, { ...props })),
  fetchParticipant: createAction('FETCH_PARTICIPANT',  participantId => api.get(`participants/${participantId}`)),
  deleteParticipant: createAction('PARTICIPANTS_DELETE', id => api.post('participant/delete', { id })),
  assignParticipant: createAction('PARTICIPANTS_ASSIGN', props => api.post('participant/assign', { ...props })),
};
