import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Form as FormWrapper } from 'react-final-form';
import styled from '@emotion/styled';
import get from 'lodash/get';

import { useStyles } from '../../../utils/styles';
import { useRoute } from '../../../utils/hooks';
import { HeaderText, ButtonPrimary } from '../../../_shared';

export const TrainerSearchForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { route, navigateTo } = useRoute();
  
  const [initialValues, setInitialValues] = useState({});
  
  const handleSearch = useCallback(values => {
    navigateTo('layout.trainers', values, {replace: false});
  }, [navigateTo]);
  
  useEffect(() => {
    setInitialValues({
      ...get(route, 'params', {}),
    });
  }, [route, setInitialValues]);
  
  return (
    <Wrapper>
      <HeaderText text={t('buttons.filter')} />
      <FormWrapper
        onSubmit={handleSearch}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.field}
                  label={t('forms.firstName')}
                  name="firstName"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.field}
                  label={t('forms.lastName')}
                  name="lastName"
                  margin="none"
                />
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.search')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;
