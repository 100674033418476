import axios from 'axios';
import get from 'lodash/get';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toastr } from 'react-redux-toastr';
import CONFIG from '../../config';

const instances = [];

function apiFactory(url, interceptors) {
  const api = axios.create({
    baseURL: `${CONFIG.api}/api`,
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  });

  instances.push({ instance: api, url, interceptors });
  return api;
}

function setupApiInstance(instance, { request = [] }, serviceUrl , dependencies) {
  instance.interceptors.request.use(
    config => request.reduce(
      async (acc, interceptor) => interceptor(await acc, dependencies),
      config,
    ),
    error => Promise.reject(error),
  );
}

export function setupApiInstances(dependencies) {
  instances.forEach(({ instance, interceptors, url }) => setupApiInstance(instance, interceptors, url, dependencies));
}

const api = apiFactory('', {});

export const authApi = apiFactory('', {});

export default api;

api.interceptors.request.use(async (config, dep) => {
  const refreshToken = await reactLocalStorage.get('refreshToken');
  const accessToken = await reactLocalStorage.get('accessToken');
  if (refreshToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }
  return config;
});

authApi.interceptors.response.use(response => Promise.resolve(response.data || response));
api.interceptors.response.use(response => Promise.resolve(get(response, 'data', response)), error => {
  const { response } = error;
  
  if (response && response.status === 401) {
    console.log('redirect to auth');
  }
  if (response && response.status === 422) {
    const errorList = get(response, 'data.errors', []);
    const errors = Object.keys(errorList).map(key => `${errorList[key]}`).join('\n');
    toastr.error(errors);
  }
  return Promise.reject({ payload: [], meta: error });
});
