import React, {useState, useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

import { PageHeader, GridList, Pagination } from '../../_shared';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { trainingActions, trainingSelectors } from '../../redux/training';
import { SearchForm } from './components/searchForm';

const TrainingsContainer = () => {
  const { t }= useTranslation('');
  const { navigateTo, route } = useRoute();
  
  const [isLoading, setLoadingState] = useState(false);
  
  const fetchTrainings = useDispatchHook(trainingActions.fetchTrainings);
  const deleteTraining = useDispatchHook(trainingActions.deleteTraining);
  
  const trainings = useSelector(trainingSelectors.trainings);
  const pagination = useSelector(trainingSelectors.pagination);
  
  const columns = [
    { field: 'name', headerName: t('forms.name'), width: 50.6, link: { to: 'layout.training', params: { trainingId: 'id' }} },
    { field: 'date', headerName: t('forms.startDate'), width: 27.3 },
  ];
  
  const handleDelete = useCallback(async props => {
    await deleteTraining({ id: get(props, 'id') });
    toastr.success(t('common.successDeleted'));
    navigateTo('layout.trainings', { ...get(route, 'params'), page: 1 }, {replace: false});
  }, [deleteTraining, navigateTo, route, t]);
  
  useEffect(() => {
    (async () => {
      setLoadingState(true);
      await fetchTrainings({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    })();
  }, [fetchTrainings, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('training.seminars', { count: pagination.total })} />
      <Row>
        <Col xs={12} md={9} lg={8}>
          {!isLoading ? (
            <GridList
              columns={columns}
              rows={trainings}
              actions={{
                delete: handleDelete,
                edit: 'layout.editTraining',
              }}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={3} lg={4}>
          <SearchForm />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default TrainingsContainer;
