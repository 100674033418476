import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Form as FormWrapper } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button, MenuItem, Menu,
} from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import omit from 'lodash/omit';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { LayoutContext, memberModelToService } from '../../utils';
import { administrationActions } from '../../redux/administration';
import { membersActions, membersSelectors } from '../../redux/members';
import { PageHeader } from '../../_shared/headers';
import { validateForm } from './components/validations';
import { Form, DuesList } from './components';
import { ButtonPrimary } from "../../_shared";
import { MemberModals } from './components/memberModals';
import { authSelectors } from '../../redux/auth';

const initial = {
  isMember: false,
  isDual: false,
  hasAttestation: false,
  hasMeditation: false,
  hasServices: false,
  activity: [],
  itemCategories: [],
  tags: [],
  email: [],
  social: [],
  phone: null,
};

const EditMemberContainer = () => {
  const { t } = useTranslation();
  
  const { images, setImages } = useContext(LayoutContext);
  const { route, navigateTo } = useRoute();
  
  const duesFormInitial = {
    date: new Date(),
  };
  
  const [initialValues, setInitialValues] = useState(initial);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  
  const handleClose = () => setAnchorEl(null);
  const [showModal, setModalState] = useState(null);
  const dues = useSelector(membersSelectors.dues);
  const user = useSelector(authSelectors.user);
  
  const updateMember = useDispatchHook(membersActions.updateMember);
  const fetchMember = useDispatchHook(membersActions.fetchMember);
  const fetchVillages = useDispatchHook(administrationActions.fetchVillages);
  const deleteDue = useDispatchHook(membersActions.deleteDue);
  const fetchDues = useDispatchHook(membersActions.fetchDues);
  const clearMember = useDispatchHook(membersActions.clearMember);
  
  const onSubmit = useCallback(async values => {
    try {
      await updateMember(get(route, 'params.id'), memberModelToService(values, images));
      toastr.success(t('common.updated'));
      window.history.go(-1);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [updateMember, images, t, route]);
  
  const onDeleteDue = useCallback(async id => {
    await deleteDue(id);
    fetchDues(get(route, 'params.id'));
  }, [deleteDue, fetchDues, route]);

  useEffect(() => {
    (async () => {
      setInitialValues({});
      if (get(route, 'params.id')) {
        const { value } = await fetchMember(get(route, 'params.id'));
    
        let address;
        let email;
        try {
          address = JSON.parse(get(value, 'address', '{}'));
          email = JSON.parse(get(value, 'email', '"[]"'));
        } catch (e) {
          address = {};
          email = [];
          console.warn(e);
        }
    
        fetchVillages(get(value, 'cityId'));
        if (get(value, 'logoUrl')) {
          setImages([get(value, 'logoUrl')]);
        } else {
          setImages([]);
        }

        setInitialValues({
          ...omit(value, ['id']),
          tags: JSON.parse(get(value, 'tags')),
          email: email || [],
          social: JSON.parse(get(value, 'social')),
          activity: get(value, 'activityIds'),
          itemCategories: get(value, 'itemCategoryIds'),
          services: get(value, 'servicesIds'),
          home: get(address, 'home', ''),
          office: get(address, 'office', ''),
          phone: get(address, 'phone', ''),
          street: get(address, 'street', ''),
          postCode: get(address, 'postCode', ''),
          legalStreet: get(address, 'legalStreet', ''),
  
          legal_home: get(address, 'legalAddress.home', ''),
          legal_office: get(address, 'legalAddress.office', ''),
          legal_street: get(address, 'legalAddress.street', ''),
          legal_postCode: get(address, 'legalAddress.postCode', ''),
        });
        fetchDues(get(route, 'params.id'));
      }
    })();
  }, [fetchMember, fetchVillages, route, setImages, fetchDues]);

  useEffect(() => {
    return () => {
      setInitialValues(initial);
      setImages([]);
      clearMember();
    }
  }, [setImages, clearMember]);
  
  useEffect(() => {
    if (get(user, 'editRestrictions', 0)) {
      navigateTo('layout.home');
    }
  }, [user, navigateTo, initialValues]);
  
  return (
    <Container fluid>
      <PageHeader title={t('addMember.edit')} backLink="hash" />
      <ActionsWrapper>
        <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
          {t('buttons.actions')}
        </ButtonPrimary>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            setModalState('fee');
            handleClose();
          }}>{t('member.membershipFee')}</MenuItem>
        </Menu>
        <DuesWrapper dues={dues} onDeleteDue={onDeleteDue} />
      </ActionsWrapper>
      <FormWrapper
        onSubmit={onSubmit}
        validate={errors => validateForm(errors, t('member.requiredField'))}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <MemberModals
        showModal={showModal}
        setModalState={setModalState}
        duesFormInitial={duesFormInitial}
      />
    </Container>
  );
};

const ActionsWrapper = styled.div`
  margin-bottom: 16px;
`;

const DuesWrapper = styled(DuesList)`
  margin-top: 16px;
`;

export default EditMemberContainer;
