import React, { useCallback, useEffect, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Select, TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Form as FormWrapper } from 'react-final-form';
import styled from '@emotion/styled';
import map from 'lodash/map';
import get from 'lodash/get';

import { useStyles } from '../../../utils/styles';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { HeaderText, ButtonPrimary } from '../../../_shared';
import { lobbyActions, lobbySelectors } from '../../../redux/lobby';

export const SearchForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { route, navigateTo } = useRoute();

  const [initialValues, setInitialValues] = useState({});

  const types = useSelector(lobbySelectors.types);
  
  const fetchLobbyTypes = useDispatchHook(lobbyActions.fetchLobbyTypes);
  
  const handleSearch = useCallback(values => {
    navigateTo('layout.lobbies', values, {replace: false});
  }, [navigateTo]);
  
  useEffect(() => {
    fetchLobbyTypes({ noPagination: true });
  }, [fetchLobbyTypes]);
  
  useEffect(() => {
    setInitialValues({
      ...get(route, 'params', {}),
    });
  }, [route, setInitialValues]);
  
  return (
    <Wrapper>
      <HeaderText text={t('buttons.filter')} />
      <FormWrapper
        onSubmit={handleSearch}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={12}>
                <Select
                  name="typeId"
                  label={t('addLobby.type')}
                  formControlProps={{ margin: 'none' }}
                  required
                >
                  <MenuItem value="">
                    <em>{t('common.select')}</em>
                  </MenuItem>
                  {map(types, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.field}
                  label={t('lobby.name')}
                  name="name"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Select
                  name="international"
                  label={t('addLobby.international')}
                  formControlProps={{ margin: 'none' }}
                >
                  <MenuItem value="">
                    <em>{t('common.select')}</em>
                  </MenuItem>
                  {map([{id: 1, name: t('addLobby.yes')}, {id: 2, name: t('addLobby.no')}], ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <ButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.search')}
                </ButtonPrimary>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${({theme}) => theme.background.primary};
  padding: ${({theme}) => theme.block.padding} ${({theme}) => theme.block.padding} 0 ${({theme}) => theme.block.padding};
  margin-bottom: ${({theme}) => theme.block.margin};
  border: ${({theme}) => theme.block.border};
`;
