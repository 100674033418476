import React, {useEffect, useCallback, useState} from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import get from 'lodash/get';
import styled from '@emotion/styled';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { membersActions, membersSelectors } from '../../redux/members';
import { PageHeader } from '../../_shared/headers';
import { ButtonPrimary, GridList, Pagination } from '../../_shared';
import { MembersSearchForm, ReportForm, SendInvitation } from './components';
import { authSelectors } from '../../redux/auth';
import CONFIG from '../../config';

const MembersContainer = () => {
  const { t } = useTranslation();
  const { route, navigateTo } = useRoute();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setLoadingState] = useState(false);
  const [generatedReport, setGeneratedReport] = useState(null);
  const [isReportShown, setReportState] = useState(false);
  const [searchInitialValues, setSearchInitialValues] = useState({
    activity: [],
    itemCategories: [],
    subsidy: [],
    tags: [],
  });

  const fetchMembers = useDispatchHook(membersActions.fetchMembers);
  const deleteMember = useDispatchHook(membersActions.deleteMember);
  const addMemberByIdno = useDispatchHook(membersActions.addMemberByIdno);
  
  const user = useSelector(authSelectors.user);

  const members = useSelector(membersSelectors.members);
  const pagination = useSelector(membersSelectors.pagination);
  
  const handleDeleteMember = useCallback(async props => {
    await deleteMember({ id: get(props, 'id') });
    toastr.success(t('common.successDeleted'));
    navigateTo('layout.members', { ...get(route, 'params'), page: 1 }, {replace: false});
  }, [deleteMember, navigateTo, route, t]);
  
  useEffect(() => {
    setGeneratedReport(false);
  }, [route, setGeneratedReport]);
  
  useEffect(() => {
    (async () => {
      setLoadingState(true);
      await fetchMembers({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    })();
  }, [fetchMembers, setLoadingState, route]);
  
  const columns = [
    { field: 'name', headerName: t('addMember.name'), width: 50.6, link: { to: 'layout.member', params: { memberId: 'id' }} },
    { field: 'registeredDate', headerName: t('addMember.registeredDate'), width: 27.3 },
  ];
  
  const headerTitle = get(route, 'params.isMember', false) ? 'header.membersList' : 'header.economicalAgentsList';
  
  const actions = get(user, 'editRestrictions', 0) ? null : {
    delete: handleDeleteMember,
    edit: 'layout.editMember',
  };
  
  return (
    <Container fluid>
      <PageHeader title={t(headerTitle, { count: get(pagination, 'total', 0) })}>
        <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={e => setAnchorEl(e.currentTarget)}>
          {t('buttons.actions')}
        </ButtonPrimary>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => {
            setReportState(true);
            setAnchorEl(null);
          }}>{t('common.generateReport')}</MenuItem>
          {get(user, 'editRestrictions', 0) ? null : (
            <>
              <MenuItem onClick={() => addMemberByIdno({ idno: 1003600122393 })}>{t('addMember.addMemberByIdno')}</MenuItem>
              <MenuItem onClick={() => navigateTo('layout.addMember', {}, {replace: false})}>{t('buttons.add')}</MenuItem>
            </>
          )}
        </Menu>
      </PageHeader>
      <Row>
        <Col xs={12} md={6} lg={6}>
          {get(route, 'params.invitationId') && <SendInvitation />}
          {!get(pagination, 'total') && isReportShown ? <AlertWrapper severity="error">{t('common.unfortunatelyNoData')}</AlertWrapper> : null}
          {isReportShown && get(pagination, 'total') ? (
            <ReportForm
              setGeneratedReport={setGeneratedReport}
              setReportState={setReportState}
              searchInitialValues={searchInitialValues}
            />
          ) : null}
          {generatedReport && (
            <AlertWrapper>
              {t('common.reportWereGenerated', { name: get(generatedReport, 'name') })},&nbsp;
              <a href={`${CONFIG.api}/uploads/reports/${get(generatedReport, 'fileName')}`}>{t('common.clickHereToDownload')}</a>
            </AlertWrapper>
          )}
          {!isLoading ? (
            <GridList
              columns={columns}
              rows={members}
              actions={actions}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={6} lg={6}>
          <MembersSearchForm
            setInitialValues={setSearchInitialValues}
            initialValues={searchInitialValues}
          />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

const AlertWrapper = styled(Alert)`
  margin-bottom: ${({theme}) => theme.block.margin};
  a {
    font-weight: bold;
  }
`;

export default MembersContainer;