import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { TextField } from 'mui-rff';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import Switch from "@material-ui/core/Switch";
import map from 'lodash/map';
import get from 'lodash/get';

import { useRoute } from '../../../../utils/hooks';
import { appSelectors } from '../../../../redux/app';
import { ImageUploader } from '../../../../_shared/components';

const Form = () => {
  const { t } = useTranslation();
  const languages = useSelector(appSelectors.languages);
  const { route } = useRoute();
  
  return (
    <Column expanded>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('addMember.generalData')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="flex-start" spacing={2}>
          {map(languages, lng => (
            <Grid item xs={12} md={12} key={lng}>
              <TextField
                label={`${t('forms.name')} - ${lng}`}
                name={`name[${lng}]`}
                margin="none"
                required
              />
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <FormControlLabel
              label={t('common.isPublic')}
              control={
                <Field name="isPublic" type="checkbox">
                  {props => (
                    <Switch
                      color="primary"
                      checked={props.input.checked}
                      name={props.input.name}
                      value={props.input.value}
                      onChange={(e, v) => props.input.onChange(e)}
                    />
                  )}
                </Field>
              }
            />
          </Grid>
          <Grid container alignItems="flex-start" spacing={2}>
            <ImageUploader isEditing={!!+get(route, 'params.id')} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Column>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default Form;
