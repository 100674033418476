import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { router } from '../../../../router';
import { CardBox } from '../../../../_shared/components';
import { useDispatchHook, useRoute } from '../../../../utils/hooks';
import { membersActions } from '../../../../redux/members';
import { filedToCurrentLanguage } from '../../../../utils';

export const Lobbies = () => {
  const { route } = useRoute();
  const { t } = useTranslation('');
  const fetchMemberLobbies = useDispatchHook(membersActions.fetchMemberLobbies);
  
  const [events, setEvents] = useState([]);
  
  useEffect(() => {
    (async () => {
      const { value } = await fetchMemberLobbies(get(route, 'params.memberId'));
      setEvents(value);
    })();
  }, [fetchMemberLobbies, route, setEvents]);

  const renderActions = id => {
    const href = router.buildUrl('layout.lobby', { lobbyId: id });
    return (
      <Button size="small" color="primary" onClick={() => window.open(href, '_blank')}>
        {t('common.moreDetails')}
      </Button>
    );
  };

  return (
    <>
      {isEmpty(events) && (
        <Alert severity="warning">{t('public.noDataWereFound')}</Alert>
      )}
      <Wrapper>
        {events.map(item => (
          <CardBox
            key={item.itemId}
            top={filedToCurrentLanguage(get(item, 'eventType'))}
            title={`${get(item, 'firstName')} ${get(item, 'lastName')}`}
            subTitle={get(item, 'name')}
            actions={renderActions(item.id)}
          >
            {get(item, 'startDate')} / {get(item, 'endDate')}<br/>
            {get(item, 'phone') && <><b>{t('forms.phoneNumber')}</b>: {get(item, 'phone')}<br/></>}
            {get(item, 'email') && <><b>{t('forms.email')}</b>: <a href={`mailto: ${get(item, 'email')}`}>{get(item, 'email')}</a><br/></>}
          </CardBox>
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;