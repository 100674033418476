import React, {useState, useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

import { PageHeader, GridList, Pagination } from '../../_shared';
import { trainingActions, trainingSelectors } from '../../redux/training';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { TrainerSearchForm } from './components/trainerSearchForm';

const TrainersContainer = () => {
  const { t }= useTranslation('');
  const { navigateTo, route } = useRoute();
  
  const [isLoading, setLoadingState] = useState(false);
  
  const fetchTrainers = useDispatchHook(trainingActions.fetchTrainers);
  const deleteTrainer = useDispatchHook(trainingActions.deleteTrainer);
  
  const trainers = useSelector(trainingSelectors.trainers);
  const pagination = useSelector(trainingSelectors.pagination);
  
  const columns = [
    { field: ['firstName', 'lastName'], headerName: t('participants.memberName'), width: 27.3, link: { to: 'layout.trainer', params: { id: 'id' }} },
    { field: 'created_at', headerName: t('participants.date'), width: 50.6 },
  ];
  
  const handleDelete = useCallback(async props => {
    await deleteTrainer({ id: get(props, 'id') });
    toastr.success(t('common.successDeleted'));
    navigateTo('layout.trainers', { ...get(route, 'params'), page: 1 }, {replace: false});
  }, [deleteTrainer, navigateTo, route, t]);
  
  useEffect(() => {
    (async () => {
      setLoadingState(true);
      await fetchTrainers({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    })();
  }, [fetchTrainers, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('trainer.header', { count: pagination.total })} addLink="layout.addTrainer" />
      <Row>
        <Col xs={12} md={9} lg={8}>
          {!isLoading ? (
            <GridList
              columns={columns}
              rows={trainers}
              actions={{
                delete: handleDelete,
                edit: 'layout.editTrainer',
              }}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={3} lg={4}>
          <TrainerSearchForm />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default TrainersContainer;
