import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { trainingActions } from '../../redux/training';
import TrainerForm from './components/trainerForm';

const EditTrainer = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  const [initialValues, setInitialValues] = useState({});
  
  const updateTrainer = useDispatchHook(trainingActions.updateTrainer);
  const fetchTrainer= useDispatchHook(trainingActions.fetchTrainer);
  
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      const { value } = await fetchTrainer(get(route, 'params.id'));
      if (isEmpty(value)) {
        navigateTo('layout.trainers', {}, {replace: false});
      }
      if (!isCancelled) {
        setInitialValues(value);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [fetchTrainer, navigateTo, route]);
  
  const onSubmit = useCallback(async (values) => {
    await updateTrainer(values, get(route, 'params.id'));
    navigateTo('layout.trainers', {}, {replace: false});
  }, [updateTrainer, navigateTo, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('trainer.edit')} backLink="hash" />
      <FormWrapper
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <TrainerForm />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default EditTrainer;