import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { Radios, TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Field, FormSpy } from 'react-final-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import styled from '@emotion/styled';

import { AddressInputs } from '../../../_shared/components';

export const FormFounder = () => {
  const { t } = useTranslation();
  
  return (
    <Row>
      <Col md={6} xs={12}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('addMember.generalData')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={12}>
                <Radios
                  label={t('forms.personType')}
                  name="person_type"
                  formControlProps={{ margin: 'none' }}
                  color="primary"
                  radioGroupProps={{ row: true }}
                  data={[
                    { label: t('member.individual'), value: '0' },
                    { label: t('member.legal'), value: '1' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Radios
                  label={t('forms.type')}
                  name="type"
                  formControlProps={{ margin: 'none' }}
                  color="primary"
                  radioGroupProps={{ row: true }}
                  data={[
                    { label: t('founder.founder'), value: '1' },
                    { label: t('founder.administrator'), value: '0' },
                  ]}
                />
              </Grid>
              <FormSpy subscription={{values: true}}>
                {({values}) => +values.person_type === 0 ?
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={t('forms.firstName')}
                        name="firstName"
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={t('forms.lastName')}
                        name="lastName"
                        margin="none"
                      />
                    </Grid>
                  </> : (
                  <Grid item xs={12} md={12}>
                    <TextField
                      label={t('forms.companyName')}
                      name="company"
                      margin="none"
                    />
                  </Grid>
                )}
              </FormSpy>
              
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('forms.idnp')}
                  name="idnp"
                  max={13}
                  type="number"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('forms.email')}
                  name="email"
                  type="email"
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field name="phone" type="checkbox">
                  {props => (
                    <MuiPhoneNumber
                      data-cy="phone"
                      defaultCountry={'md'}
                      label={t('addMember.phoneNumber')}
                      preferredCountries={['md', 'ro', 'ru']}
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
      <Col md={6} xs={12}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('forms.address')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <AddressInputs local />
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
    </Row>
  )
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;