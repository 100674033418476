import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { useRoute } from '../../utils/hooks';
import { Map } from './components/map';
import { PublicMembers } from './components/publicMembers';
import { StatisticsMap } from './components/statisticsMap';

const PublicHome = () => {
  const { t } = useTranslation('');
  const { navigateTo, route } = useRoute();
  const [value, setValue] = useState(0);
  
  const handleChange = useCallback((event, newValue) => {
    if (newValue === 2) {
      navigateTo('public', { lng: get(route, 'params.lng', 'ro') }, {replace: false});
    }
    setValue(newValue);
  }, [route, navigateTo]);
  
  const handleSubsidy = useCallback(({ subsidyId, services, service }) => {
    const filteredServices = services.filter(id => service ? id === service : true);
    navigateTo('public', {
      lng: get(route, 'params.lng', 'ro'),
      subsidy: subsidyId,
      itemCategories: filteredServices,
    }, { replace: false });
    setValue(2);
  }, [navigateTo, route]);
  
  const renderContent = useCallback(() => {
    let content;
    switch(value) {
      case 0:
        content = (
          <Row>
            <Col md={9} xs={12}>
              <Map showLegend key="map-with-legend" fullHeight={false} />
            </Col>
            <Col md={3} xs={12}>
              <Title>{t('public.mapInstruction')}</Title>
            </Col>
          </Row>
        );
        break;
      case 1:
        content = (<StatisticsMap key="statistics" handleSubsidy={handleSubsidy} />);
        break;
      case 2:
        content = (<PublicMembers key="public-members" />);
        break;
      default:
        content = null;
    }
    return content;
  }, [value, t, handleSubsidy]);

  return (
    <Wrapper>
      <Paper square>
        <Tabs
          value={value}
          centered
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label={t('public.map')} />
          <Tab label={t('public.mapStatistics')} />
          <Tab label={t('public.list')} />
        </Tabs>
      </Paper>
      <Container>
        {renderContent()}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  padding: 24px;
`;

const Title = styled('h1')`
  text-align: center;
  font-size: 24px;
`;

export default PublicHome;
