import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

import { useDispatchHook } from '../../../../utils/hooks';
import { membersActions } from '../../../../redux/members';

export const RequestChart = () => {
  const [items, setItems] = useState([]);
  const [dues, setDues] = useState([]);
  const [members, setMembers] = useState([]);
  const filterRequestsData = useDispatchHook(membersActions.filterRequestsData);
  const filterDuesData = useDispatchHook(membersActions.filterDuesData);
  const filterMembersReportData = useDispatchHook(membersActions.filterMembersReportData);
  
  const percentage = items.map(({ dataCount, year }) => [year.toString(), dataCount]);
  const percentageDues = dues.map(({ totalAmount, year }) => [year.toString(), totalAmount]);
  const percentageMembers = members.map(({ dataCount, year }) => [year ? year.toString() : new Date().getFullYear(), dataCount]);

  useEffect(() => {
    (async () => {
      const { value: request } = await filterRequestsData();
      const { value: dues } = await filterDuesData();
      const { value: members } = await filterMembersReportData();
      setMembers(members);
      setItems(request);
      setDues(dues);
    })();
  }, [filterRequestsData, filterDuesData, filterMembersReportData]);
  const label = 'Diagrama pe numarul de cereri';
  const labelMember = 'Diagrama pe numarul de membri CCI';
  const labelDues = 'Diagrama pe achitari cotizatii';

  if (!percentage.length && !percentageDues.length && !percentageMembers.length) return null;

  return (
    <>
      <Chart
        width={'600px'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['x', 'Membri CCI'],
          ...percentageMembers
        ]}
        options={{
          hAxis: {
            title: 'Time',
          },
          vAxis: {
            title: labelMember,
          },
        }}
        rootProps={{ 'data-testid': '1' }}
      />

      <Chart
        width="100%"
        height={'400px'}
        chartType="PieChart"
        loader={<div>Loading report</div>}
        data={[
          [label, 'Inregistrari'],
          ...percentage,
        ]}
        options={{
          title: label,
          // Just add this option
          is3D: true,
        }}
        rootProps={{ 'data-testid': '2' }}
      />
      
      <Chart
        width="100%"
        height={'400px'}
        chartType="PieChart"
        loader={<div>Loading report</div>}
        data={[
          [labelDues, 'Inregistrari'],
          ...percentageDues,
        ]}
        options={{
          title: labelDues,
          // Just add this option
          is3D: true,
        }}
        rootProps={{ 'data-testid': '3' }}
      />
    </>
  );
};
