import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import map from 'lodash/map';
import { Field } from 'react-final-form';

import { useDispatchHook } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';
import { membersSelectors } from '../../../redux/members';
import { AutoCompleteField } from '../../../_shared/components';

export const DirectionsCategoriesData = () => {
  const { t } = useTranslation();
  
  const activityDirections = useSelector(administrationSelectors.activityDirections);
  const itemCategories = useSelector(administrationSelectors.itemCategories);
  const services = useSelector(administrationSelectors.services);
  const member = useSelector(membersSelectors.member);
  
  const fetchActivityDirections = useDispatchHook(administrationActions.fetchActivityDirections);
  const fetchItemCategories = useDispatchHook(administrationActions.fetchItemCategories);
  const fetchServices = useDispatchHook(administrationActions.fetchServices);

  useEffect(() => {
    setTimeout(() => {
      fetchActivityDirections({ noPagination:true });
      fetchItemCategories({ noPagination:true });
      fetchServices({ noPagination:true });
    }, 400);
  }, [fetchActivityDirections, fetchItemCategories, fetchServices]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Field
          name="activity"
          multiple
          selected={get(member, 'activityIds', [])}
          label={t('addMember.activityDirections')}
          component={AutoCompleteField}
          placeholder={t('addMember.activityDirections')}
          options={map(activityDirections, ({ id, name }) => ({ label: name, value: +id }))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="itemCategories"
          multiple
          selected={get(member, 'itemCategoryIds', [])}
          label={t('addMember.itemCategories')}
          component={AutoCompleteField}
          placeholder={t('addMember.itemCategories')}
          options={map(itemCategories, ({ id, name }) => ({ label: name, value: +id }))}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Field
            name="services"
            multiple
            selected={get(member, 'servicesIds', [])}
            label={t('addMember.services')}
            component={AutoCompleteField}
            placeholder={t('addMember.services')}
            options={map(services, ({ id, name }) => ({ label: name, value: +id }))}
        />
      </Grid>
    </>
  );
};
