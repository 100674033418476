import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { PageHeader } from '../../../_shared/headers';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions } from '../../../redux/administration';
import Form from './components/form';

const EditSubsidy = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  const [initialValues, setInitialValues] = useState({});
  
  const updateSubsidy = useDispatchHook(administrationActions.updateSubsidy);
  const fetchSubsidy = useDispatchHook(administrationActions.fetchSubsidy);
  
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      const { value } = await fetchSubsidy(get(route, 'params.id'));
      if (isEmpty(value)) {
        navigateTo('layout.subsidies', {}, {replace: false});
      }
      let name;
      try {
        name = JSON.parse(get(value, 'name'));
      } catch (e) {
        name = {};
      }
      if (!isCancelled) {
        setInitialValues({
          name,
          address: get(value, 'address'),
          phone: get(value, 'phone'),
          email: get(value, 'email'),
          regions: get(value, 'regions'),
          webpage: get(value, 'webpage'),
        });
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [fetchSubsidy, navigateTo, route]);
  
  const onSubmit = useCallback(async values  => {
    await updateSubsidy({ ...values, name: JSON.stringify(values.name) }, get(route, 'params.id'));
    navigateTo('layout.subsidies', {}, {replace: false});
  }, [updateSubsidy, navigateTo, route]);

  return (
    <Container fluid>
      <PageHeader title={t('subsidy.edit')} backLink="layout.subsidies" />
      <FormWrapper
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default EditSubsidy;