import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';

import api from '../api/index';

const districts = createSelector(
  state => state.members.get('districts'),
  districts => districts.toJS(),
);

const towns = createSelector(
  state => state.members.get('towns'),
  towns => towns.toJS(),
);

const subsidies = createSelector(
  state => state.members.get('subsidies'),
  subsidies => subsidies.toJS(),
);

const companyTypes = createSelector(
  state => state.members.get('companyTypes'),
  companyTypes => companyTypes.toJS(),
);

const ownershipTypes = createSelector(
  state => state.members.get('ownershipTypes'),
  ownershipTypes => ownershipTypes.toJS(),
);

const legalForms = createSelector(
  state => state.members.get('legalForms'),
  legalForms => legalForms.toJS(),
);

const directionsOfActivity = createSelector(
  state => state.members.get('directionsOfActivity'),
  directionsOfActivity => directionsOfActivity.toJS(),
);

const members = createSelector(
  state => state.members.get('members'),
  members => members.toJS(),
);

const membersPublic = createSelector(
  state => state.members.get('membersPublic'),
  membersPublic => membersPublic.toJS(),
);

const requests = createSelector(
  state => state.members.get('requests'),
  requests => requests.toJS(),
);

const member = createSelector(
  state => state.members.get('member'),
  member => {
    const data = member.toJS();
    let address;
    let email;
    let webPage;
    let social;
    let tags;
    try {
      address = JSON.parse(get(data, 'address'));
    } catch (e) {
      address = {};
    }
    try {
      email = JSON.parse(get(data, 'email'));
    } catch (e) {
      email = [];
    }
    try {
      webPage = JSON.parse(get(data, 'webPage'));
    } catch (e) {
      webPage = [];
    }
    try {
      social = JSON.parse(get(data, 'social'));
    } catch (e) {
      social = [];
    }
    try {
      tags = JSON.parse(get(data, 'tags'));
    } catch (e) {
      tags = [];
    }
    
    return ({ ...data, address, email, webPage, social, tags });
  },
);

const notices = createSelector(
  state => state.members.get('notices'),
  notices => notices.toJS(),
);

const imports = createSelector(
  state => state.members.get('imports'),
  imports => imports.toJS(),
);

const dues = createSelector(
  state => state.members.get('dues'),
  dues => dues.toJS(),
);

const pagination = createSelector(
  state => state.members.get('pagination'),
  pagination => pagination.toJS(),
);

const requestsByStatus = createSelector(
  state => state.members.get('requestsByStatus'),
  requestsByStatus => requestsByStatus.toJS(),
);

const founders = createSelector(
  state => state.members.get('founders'),
  founders => founders.toJS(),
);

export const membersSelectors = {
  districts,
  towns,
  subsidies,
  companyTypes,
  ownershipTypes,
  legalForms,
  directionsOfActivity,
  members,
  member,
  pagination,
  notices,
  imports,
  requests,
  requestsByStatus,
  membersPublic,
  dues,
  founders,
};

const initialState = fromJS({
  districts: {},
  towns: {},
  subsidies: {},
  companyTypes: {},
  ownershipTypes: {},
  legalForms: {},
  directionsOfActivity: [],
  members: [],
  membersPublic: [],
  requests: [],
  notices: [],
  imports: [],
  dues: [],
  requestsByStatus: [],
  founders: [],
  member: {},
  pagination: {
    total: 0,
    currentPage: 0,
    pages: 0,
    perPage: 0,
  }
});

export const reducer = handleActions(
  {
    MEMBERS_FETCH_MEMBER_FULFILLED: (state, { payload }) => state.set('member', fromJS(payload)),
    MEMBERS_FETCH_REQUESTS_BY_STATUS_FULFILLED: (state, { payload }) => state.set('requestsByStatus', fromJS(payload)),
    MEMBERS_FETCH_IMPORTS_FULFILLED: (state, { payload }) => state.set('imports', fromJS(payload)),
    MEMBERS_FETCH_DUES_FULFILLED: (state, { payload }) => state.set('dues', fromJS(payload)),
    MEMBERS_FETCH_MEMBER_NOTICES_FULFILLED: (state, { payload }) => state.set('notices', fromJS(payload)),
    MEMBER_FETCH_MEMBER_FOUNDERS_FULFILLED: (state, { payload }) => state.set('founders', fromJS(payload)),
    MEMBERS_FETCH_MEMBERS_FULFILLED: (state, { payload }) => state
      .set('members', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    MEMBERS_FETCH_MEMBERS_PUBLIC_FULFILLED: (state, { payload }) => state
      .set('membersPublic', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    MEMBERS_FETCH_REQUESTS_FULFILLED: (state, { payload }) => state
      .set('requests', fromJS(get(payload, 'data', payload)))
      .set('pagination', fromJS({
        total: get(payload, 'total'),
        currentPage: get(payload, 'current_page'),
        pages: get(payload, 'last_page'),
        perPage: get(payload, 'per_page'),
      })),
    MEMBERS_CLEAR_MEMBER: state => state.set('member', fromJS({})),
  },
  initialState
);

export const membersActions = {
  createMember: createAction('MEMBERS_CREATE_MEMBER', data => api.post('members/create', { ...data })),
  createImport: createAction('MEMBERS_CREATE_IMPORT', data => api.post('members/import', { ...data })),
  deleteImport: createAction('MEMBER_IMPORT_DELETE', props => api.post('member/imports/delete', { ...props })),
  createDue: createAction('MEMBERS_CREATE_DUE', data => api.post('members/due', { ...data })),
  deleteDue: createAction('MEMBERS_DELETE_DUE', id => api.post(`members/due/${id}`)),
  createNotice: createAction('MEMBERS_CREATE_MEMBER_NOTICE', (data, id) => api.post(`members/create/notice/${id}`, { ...data })),
  fetchMembers: createAction('MEMBERS_FETCH_MEMBERS', params => api.get('members', { params })),
  fetchMembersReports: createAction('MEMBERS_FETCH_MEMBERS_REPORTS', params => api.get('members/reports', { params })),
  fetchMembersPublic: createAction('MEMBERS_FETCH_MEMBERS_PUBLIC', params => api.get('members/public', { params })),
  fetchImports: createAction('MEMBERS_FETCH_IMPORTS', memberId => api.get(`members/imports/${memberId}`)),
  fetchDues: createAction('MEMBERS_FETCH_DUES', memberId => api.get(`members/due/${memberId}`)),
  fetchMember: createAction('MEMBERS_FETCH_MEMBER', id => api.get(`member/${id}` )),
  fetchMemberNotices: createAction('MEMBERS_FETCH_MEMBER_NOTICES', id => api.get(`member/notices/${id}`)),
  deleteMemberNotice: createAction('MEMBER_NOTICE_DELETE', props => api.post('member/notices/delete', { ...props })),
  deleteMemberRequest: createAction('MEMBER_REQUEST_DELETE', props => api.post('member/request/delete', { ...props })),
  deleteMember: createAction('MEMBER_DELETE', props => api.post('member/delete', { ...props })),
  createReport: createAction('MEMBER_CREATE_REPORT', props => api.post('members/export', props, { responseType: 'blob' })),
  generateProfile: createAction('MEMBER_GENERATE_PROFILE', props => api.post('members/export-member', props, { responseType: 'blob' })),
  createRequestReport: createAction('MEMBER_CREATE_REQUEST_REPORT', props => api.post('members/exportRequests', props, { responseType: 'blob' })),
  addMemberByIdno: createAction('MEMBER_ADD_MEMBER_BY_IDNO', props => api.post('members/external/add', props)),
  updateMember: createAction('MEMBER_UPDATE_MEMBER', (memberId, props) => api.put(`members/update/${memberId}`, { ...props })),
  filterData: createAction('MEMBERS_FILTER_DATA', params => api.post('members/filter', { ...params })),
  filterRequestsData: createAction('MEMBERS_FILTER_REQUEST_DATA', params => api.post('members/requests-report', { ...params })),
  filterMembersReportData: createAction('MEMBERS_FILTER_MEMBERS_REPORT_DATA', params => api.post('members/members-report', { ...params })),
  filterDuesData: createAction('MEMBERS_FILTER_DUES_DATA', params => api.post('members/dues-report', { ...params })),
  fetchMapReport: createAction('MEMBERS_FETCH_MAP_REPORT', cityId => api.get(`map/${cityId}`)),
  getMembershipNumber: createAction('MEMBERS_GET_MEMBERSHIP_NUMBER', () => api.get('last-membership')),
  
  fetchRequests: createAction('MEMBERS_FETCH_REQUESTS', params => api.get('members/requests', { params })),
  fetchRequestsByStatus: createAction('MEMBERS_FETCH_REQUESTS_BY_STATUS', status => api.get(`/members/requests/${status}` )),
  fetchRequest: createAction('MEMBERS_FETCH_REQUEST', id => api.get(`member/request/${id}` )),
  updateRequestStatus: createAction('MEMBER_UPDATE_REQUEST_STATUS', props => api.put('requests/status', { ...props })),
  clearMember: createAction('MEMBERS_CLEAR_MEMBER'),
  
  // member founders
  createMemberFounders: createAction('MEMBER_CREATE_MEMBER_FOUNDERS', data => api.post('members-founders/store', { ...data })),
  fetchMemberFounders: createAction('MEMBER_FETCH_MEMBER_FOUNDERS', memberId => api.get(`members-founders/${memberId}`)),
  deleteMemberFounder: createAction('MEMBER_FOUNDER_DELETE', props => api.post('members-founders/delete', { ...props })),
  
  fetchMemberEvents: createAction('MEMBER_FETCH_MEMBER_EVENTS', id => api.get(`members/events/${id}`)),
  fetchMemberLobbies: createAction('MEMBER_FETCH_MEMBER_LOBBIES', id => api.get(`members/lobbies/${id}`)),
  fetchMemberTrainings: createAction('MEMBER_FETCH_MEMBER_TRAININGS', id => api.get(`members/trainings/${id}`)),
};
