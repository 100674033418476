import React from 'react';

const PaymentService = () => {
  return (
    <form action="https://testmpay.gov.md/Order/Search/CCI03" method="post">
      <input type="hidden" name="ServiceID" value="CCI03"/>
      <input type="hidden" name="ReturnUrl" value="https://membrii.chamber.md"/>
      <input type="hidden" name="OrderKey" value="1"/>
      <input type="submit" value="Pay" className="btn btn-primary btn-block w-auto"/>
    </form>
  );
};

export default PaymentService;
