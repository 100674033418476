export const validateLobbyForm = (values, message) => {
  const errors = {};
  
  if (!values.name) {
    errors.name = message;
  }
  
  if (!values.typeId) {
    errors.typeId = message;
  }
  
  if (!values.countryId) {
    errors.countryId = message;
  }
  
  if (!values.startDate) {
    errors.startDate = message;
  }
  
  if (!values.endDate) {
    errors.endDate = message;
  }
  
  return errors;
};