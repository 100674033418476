import React, {useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { membersActions, membersSelectors } from '../../redux/members';
import { PageHeader } from '../../_shared/headers';
import {ButtonPrimary, GridList, Pagination} from '../../_shared';
import { RequestsSearchForm } from './components';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {toastr} from "react-redux-toastr";

const RequestsContainer = () => {
  const { t } = useTranslation();
  const { route } = useRoute();
  const [isLoading, setLoadingState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const fetchRequests = useDispatchHook(membersActions.fetchRequests);
  const createRequestReport = useDispatchHook(membersActions.createRequestReport);
  const deleteMemberRequest = useDispatchHook(membersActions.deleteMemberRequest);
  
  const requests = useSelector(membersSelectors.requests);
  const pagination = useSelector(membersSelectors.pagination);
  
  const [initialValues, setInitialValues] = useState({
    activity: [],
    itemCategories: [],
    subsidy: [],
    tags: [],
  });
  
  const handleDelete = useCallback(async props => {
    await deleteMemberRequest({ id: get(props, 'id') });
    await fetchRequests({
      ...get(route, 'params', {}),
      page: get(route, 'params.page', 1),
    });
    toastr.success(t('common.successDeleted'));
  }, [deleteMemberRequest, fetchRequests, route, t]);
  
  const onSubmit = useCallback(async () => {
    try {
      const { value } = await createRequestReport({
        searchParams: initialValues,
      });
      const date = Date.now();
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setAnchorEl(null);
      toastr.success(t('common.reportWereCreated'));
    } catch (e) {
      console.warn(e);
    }
  }, [t, createRequestReport, initialValues]);
  
  useEffect(() => {
    (async () => {
      setLoadingState(true);
      await fetchRequests({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    })();
  }, [fetchRequests, setLoadingState, route]);
  
  const columns = [
    { field: 'name', headerName: t('addMember.name'), width: 50.6, link: { to: 'layout.request', params: { requestId: 'id' }} },
    { field: 'status', headerName: t('requests.status'), width: 27.3 },
    { field: 'created_at', headerName: t('reports.date'), width: 16.3 },
  ];
  
  return (
    <Container fluid>
      <PageHeader title={t('requests.header')}>
        <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={e => setAnchorEl(e.currentTarget)}>
          {t('buttons.actions')}
        </ButtonPrimary>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => onSubmit()}>{t('common.generateReport')}</MenuItem>
        </Menu>
      </PageHeader>
      <Row>
        <Col xs={12} md={9} lg={8}>
          {!isLoading ? (
            <GridList
              columns={columns}
              actions={{
                delete: handleDelete
              }}
              rows={requests}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={3} lg={4}>
          <RequestsSearchForm initialValues={initialValues} setInitialValues={setInitialValues} />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default RequestsContainer;