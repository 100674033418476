import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { PageHeader, GridList, Pagination } from '../../_shared';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { lobbyActions, lobbySelectors } from '../../redux/lobby';

const LobbyTypes = () => {
  const { t }= useTranslation('');
  const { route } = useRoute();
  
  const types = useSelector(lobbySelectors.types);
  const pagination = useSelector(lobbySelectors.pagination);
  
  const fetchLobbyTypes = useDispatchHook(lobbyActions.fetchLobbyTypes);
  const deleteLobbyType = useDispatchHook(lobbyActions.deleteLobbyType);
  
  const columns = [
    { field: 'name', headerName: t('lobby.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    fetchLobbyTypes({
      page: get(route, 'params.page', 1),
    });
  }, [fetchLobbyTypes, route]);

  return (
    <Container fluid>
      <PageHeader title={t('lobbyType.header', { count: pagination.total })} addLink="layout.addLobbyTypes" />
      <GridList
        actions={{
          delete: deleteLobbyType,
          edit: 'layout.editLobbyTypes',
        }}
        columns={columns}
        rows={types}
      />
      <Pagination data={pagination} />
    </Container>
  );
};

export default LobbyTypes;
