import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { AddParticipantForm } from './components/addParticipantForm';
import { validateParticipantForm } from './components/validations';
import { participantsActions } from '../../redux/participants';

const EditParticipant = () => {
  const { t } = useTranslation();
  const { route } = useRoute();
  
  const [initialValues, setInitialValues] = useState({
    type: '1',
  });
  const updateParticipant = useDispatchHook(participantsActions.updateParticipant);
  const fetchParticipant = useDispatchHook(participantsActions.fetchParticipant);
  
  const onSubmit = useCallback(async values => {
    try {
      await updateParticipant({
        ...values,
        memberId: +values.type === 1 ? null : values.memberId,
      },get(route, 'params.id'));
      toastr.success(t('common.updated'));
      window.history.go(-1);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [updateParticipant, t, route]);
  
  useEffect(() => {
    const fetchData = async () => {
      const { value } = await fetchParticipant(get(route, 'params.id'));
      setInitialValues({ ...value, type: value.type === 0 ? '0' : '1' })
    };
    fetchData();
  }, [route, fetchParticipant,]);
  
  
  return (
    <Container fluid>
      <PageHeader title={t('common.editParticipant')} backLink="hash" />
      <FormWrapper
        onSubmit={onSubmit}
        validate={errors => validateParticipantForm(errors, t('member.requiredField'))}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <AddParticipantForm initialValues={initialValues} />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default EditParticipant;
