import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { PageHeader } from '../../../_shared/headers';
import { GridList, Pagination } from '../../../_shared';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions, administrationSelectors } from '../../../redux/administration';
import { Filter } from './components/filter';

const ItemCategories = () => {
  const { t }= useTranslation('');
  const { route } = useRoute();
  
  const itemCategories = useSelector(administrationSelectors.itemCategories);
  const pagination = useSelector(administrationSelectors.pagination);
  
  const fetchItemCategories = useDispatchHook(administrationActions.fetchItemCategories);
  const deleteItemCategory = useDispatchHook(administrationActions.deleteItemCategory);
  
  const columns = [
    { field: 'name', headerName: t('events.name'), width: 56.6 },
  ];
  
  useEffect(() => {
    let params = {
      page: get(route, 'params.page', 1),
      name: get(route, 'params.name', null),
    };
    if (get(route, 'params.isPublic')) {
      params = { ...params, isPublic: get(route, 'params.isPublic', false) };
    }
    fetchItemCategories(params);
  }, [fetchItemCategories, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('itemCategory.header', { count: pagination.total })} addLink="layout.addItemCategory" />
      <Filter />
      <GridList
        actions={{
          delete: deleteItemCategory,
          edit: 'layout.editItemCategory',
        }}
        columns={columns}
        rows={itemCategories}
      />
      <Pagination data={pagination} />
    </Container>
  );
};

export default ItemCategories;
