import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from '@emotion/styled';
import get from 'lodash/get';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { participantsActions } from '../../redux/participants';
import { trainingActions } from '../../redux/training';
import { ButtonPrimary } from './../../_shared';
import { PaperBox } from '../../_shared/components';

const TrainerContainer = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [trainer, setTrainer] = useState({});
  
  const fetchTrainer = useDispatchHook(trainingActions.fetchTrainer);
  const fetchParticipantsByModuleAndId = useDispatchHook(participantsActions.fetchParticipantsByModuleAndId);
  
  useEffect(() => {
    (async () => {
      const { value: trainer } = await fetchTrainer(get(route, 'params.id'));
      setTrainer(trainer);
    })();
  }, [fetchTrainer, fetchParticipantsByModuleAndId, route]);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const rows = [
    {
      name: t('forms.firstName'),
      value: get(trainer, 'firstName'),
    },
    {
      name: t('forms.lastName'),
      value: get(trainer, 'lastName'),
    },
    {
      name: t('forms.email'),
      value: get(trainer, 'email'),
    },
    {
      name: t('forms.phoneNumber'),
      value: get(trainer, 'phone'),
    },
  ];
  
  return (
    <Container fluid>
      <PageHeader title={`${get(trainer, 'firstName')} ${get(trainer, 'lastName')}`} backLink="hash" />
      <Row>
        <Column md={12} xs={12}>
          <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            {t('buttons.actions')}
          </ButtonPrimary>
          <Menu
            id="event-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => navigateTo('layout.addTraining', { trainerId: get(route, 'params.id') }, {replace: false})}>{t('training.add')}</MenuItem>
            <MenuItem onClick={() => navigateTo('layout.editTrainer', { id: get(route, 'params.id') }, {replace: false})}>{t('common.modify')}</MenuItem>
          </Menu>
        </Column>
        <Column md={12} xs={12}>
          <PaperBox rows={rows} />
        </Column>
      </Row>
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default TrainerContainer;
