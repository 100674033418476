import { useCallback } from 'react';
import noop from 'lodash/noop';

import api from '../redux/api';

export const useMember = () => {
  const handleDownloadCard = useCallback((type, id, clBc, url = null) => {
    clBc();
    return api.get(`request/${url ? url : 'card'}/${id}/${type}`, { responseType: 'blob' }).then(data => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      const fileName = type === 'card' ? 'Carnet de membru' : 'Certificat de membru';
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }, []);
  
  const handleDownloadTicket = useCallback((params, id, clBc = noop) => {
    clBc();
    return api.get(`request/generate/${id}`, { responseType: 'blob', params }).then(data => {
      const url = window.URL.createObjectURL(new Blob(["\ufeff", data], {
        type: "text/plain;charset=utf-8;"
      }));
      const link = document.createElement('a');
      const fileName = 'Payment Ticket';
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }, []);

  const handleDownloadMemberTicket = useCallback((params, id, clBc = noop) => {
    clBc();
    return api.get(`member/generate/ticket/${id}`, { responseType: 'blob', params }).then(data => {
      const url = window.URL.createObjectURL(new Blob(["\ufeff", data], {
        type: "text/plain;charset=utf-8;"
      }));
      const link = document.createElement('a');
      const fileName = 'Payment Ticket';
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }, []);
  
  return {
    handleDownloadCard,
    handleDownloadTicket,
    handleDownloadMemberTicket,
  }
};
