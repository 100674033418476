import React, {useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';
import { toastr } from "react-redux-toastr";

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { participantsActions, participantsSelectors } from '../../redux/participants';
import { PageHeader } from '../../_shared/headers';
import { GridList, Pagination } from '../../_shared';
import { ParticipantsSearchForm } from './components/participantsSearchForm';

const ParticipantsContainer = () => {
  const { t } = useTranslation();
  const { route } = useRoute();
  const [isLoading, setLoadingState] = useState(false);
  
  const participants = useSelector(participantsSelectors.participants);
  const pagination = useSelector(participantsSelectors.pagination);

  const fetchParticipants = useDispatchHook(participantsActions.fetchParticipants);
  const deleteParticipant = useDispatchHook(participantsActions.deleteParticipant);
  
  const columns = [
    { field: ['firstName', 'lastName'], headerName: t('participants.memberName'), width: 27.3, link: { to: 'layout.participant', params: { id: 'id' }} },
    { field: 'created_at', headerName: t('participants.date'), width: 50.6 },
  ];
  
  useEffect(() => {
    (async () => {
      setLoadingState(true);
      await fetchParticipants({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    })();
  }, [fetchParticipants, setLoadingState, route]);
  
  const handleDelete = useCallback(async props => {
    await deleteParticipant(get(props, 'id'));
    await fetchParticipants({
      ...get(route, 'params', {}),
      page: get(route, 'params.page', 1),
    });
    toastr.success(t('common.successDeleted'));
  }, [deleteParticipant, fetchParticipants, route, t]);
  
  return (
    <Container fluid>
      <PageHeader title={t('participants.header', { count: get(pagination, 'total', 0) })} />
      <Row>
        <Col xs={12} md={9} lg={8}>
          {!isLoading ? (
            <GridList
              columns={columns}
              rows={participants}
              actions={{
                delete: handleDelete,
                edit: 'layout.editParticipant',
              }}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={3} lg={4}>
          <ParticipantsSearchForm />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default ParticipantsContainer;