import { createElement } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import createRouter from 'router5';
import loggerPlugin from 'router5-plugin-logger';
import browserPlugin from 'router5-plugin-browser';

import NewLayout from './pages/layout/newLayout';
import HomePage from './pages/home/home';
import PaymentService from './pages/home/paymentService';
import PublicHomePage from './pages/home/publicHome';
import LoginContainer from './pages/auth/login';
import MembersContainer from './pages/members/members';
import RequestsContainer from './pages/members/requests';
import RequestContainer from './pages/members/request';
import AddMemberContainer from './pages/members/addMember';
import EditMemberContainer from './pages/members/editMember';
import EventTypesContainer from './pages/events/eventTypes';
import EventSubjectsContainer from './pages/events/subjects';
import AddEvents from './pages/events/addEvent';
import EditEvent from './pages/events/editEvent';
import AddEventType from './pages/events/addEventType';
import AddEventSubject from './pages/events/addEventSubject';
import EditEventType from './pages/events/editEventType';
import EditEventSubject from './pages/events/editEventSubject';
import AddFair from './pages/fairs/addFair';
import AddFounder from './pages/members/addFounder';
import MemberContainer from './pages/members/member';
import ReportsContainer from './pages/reports/reports';
import EventsContainer from './pages/events/events';
import EventContainer from './pages/events/event';
import ParticipantsContainer from './pages/participants/participants';
import AddParticipant from './pages/participants/addParticipant';
import EditParticipant from './pages/participants/editParticipant';
import ParticipantContainer from './pages/participants/participant';
import InvitationsContainer from './pages/invitations/invitations';
import InvitationContainer from './pages/invitations/invitation';


import Subsidies from './pages/administration/subsidies/subsidies';
import AddSubsidy from './pages/administration/subsidies/addSubsidy';
import EditSubsidy from './pages/administration/subsidies/editSubsidy';

import Properties from './pages/administration/properties/properties';
import AddProperty from './pages/administration/properties/addProperty';
import EditProperty from './pages/administration/properties/editProperty';

import Services from './pages/administration/services/services';
import AddService from './pages/administration/services/addService';
import EditService from './pages/administration/services/editService';

import ActivityDirections from './pages/administration/activityDirections/activityDirections';
import AddActivityDirection from './pages/administration/activityDirections/addActivityDirection';
import EditActivityDirection from './pages/administration/activityDirections/editActivityDirection';

import ItemCategories from './pages/administration/itemCategories/itemCategories';
import AddItemCategory from './pages/administration/itemCategories/addItemCategory';
import EditItemCategory from './pages/administration/itemCategories/editItemCategory';

import LegalForms from './pages/administration/legalForms/legalForms';
import AddLegalForm from './pages/administration/legalForms/addLegalForm';
import EditLegalForm from './pages/administration/legalForms/editLegalForm';

import AddInvitationContainer from './pages/invitations/addInvitation';
import EditInvitationContainer from './pages/invitations/editInvitation';

import LobbiesContainer from './pages/lobby/lobbies';
import LobbyContainer from './pages/lobby/lobby';
import LobbyTypesContainer from './pages/lobby/lobbyTypes';
import AddLobby from './pages/lobby/addLobby';
import EditLobby from './pages/lobby/editLobby';
import AddLobbyType from './pages/lobby/addLobbyType';
import EditLobbyType from './pages/lobby/editLobbyType';

import TrainingsContainer from './pages/training/trainings';
import AddTrainingContainer from './pages/training/addTraining';
import TrainingContainer from './pages/training/training';
import TrainingTypesContainer from './pages/training/trainingTypes';
import AddTrainingType from './pages/training/addTrainingType';
import EditTrainingType from './pages/training/editTrainingType';

import TrainersContainer from './pages/training/trainers';
import AddTrainer from './pages/training/addTrainer';
import EditTrainer from './pages/training/editTrainer';
import TrainerContainer from './pages/training/trainer';
import EditTraining from './pages/training/editTraining';

import Users from './pages/administration/users/users';
import AddUser from './pages/administration/users/addUser';
import EditUser from './pages/administration/users/editUser';

export const routes = [
  {
    name: 'public',
    path: '/public?:page&:subsidy&:itemCategories&:activity&:name&:lng',
    component: PublicHomePage,
  },
  {
    name: 'payment',
    path: '/payment',
    component: PaymentService,
  },
  {
    name: 'layout',
    path: '/',
    component: NewLayout,
  },
  {
    name: 'auth',
    path: '/auth',
    component: LoginContainer,
  },
  {
    name: 'layout.home',
    path: 'home',
    component: HomePage,
  },
  {
    name: 'layout.members',
    path: 'members?:isMember&:isExcluded&name&:page&:activity&:idno&:itemCategories&:subsidy&:membershipCard&:legalForm&:propertyType&:tags&:invitationId&excludedMembers&excludedYearFrom&excludedYearTo',
    component: MembersContainer,
  },
  {
    name: 'layout.requests',
    path: 'members/requests?:name&:page&:idno&:yearFrom&:yearTo',
    component: RequestsContainer,
  },
  {
    name: 'layout.member',
    path: 'member/:memberId',
    component: MemberContainer,
  },
  {
    name: 'layout.request',
    path: 'member/request/:requestId',
    component: RequestContainer,
  },
  {
    name: 'layout.addMember',
    path: 'member/add',
    component: AddMemberContainer,
  },
  {
    name: 'layout.editMember',
    path: 'member/edit/:id',
    component: EditMemberContainer,
  },
  {
    name: 'layout.addFounder',
    path: 'member/founder/:memberId',
    component: AddFounder,
  },
  {
    name: 'layout.events',
    path: 'events?:page&:name&:typeId&:international&:local',
    component: EventsContainer,
  },
  {
    name: 'layout.eventTypes',
    path: 'events/types',
    component: EventTypesContainer,
  },
  {
    name: 'layout.eventSubjects',
    path: 'events/subjects',
    component: EventSubjectsContainer,
  },
  {
    name: 'layout.addEventSubject',
    path: 'events/subjects/add',
    component: AddEventSubject,
  },
  {
    name: 'layout.editEventSubject',
    path: 'events/subjects/edit/:id',
    component: EditEventSubject,
  },
  {
    name: 'layout.addEventTypes',
    path: 'events/types/add',
    component: AddEventType,
  },
  {
    name: 'layout.editEventTypes',
    path: 'events/types/edit/:id',
    component: EditEventType,
  },
  {
    name: 'layout.event',
    path: 'event/:eventId?:page',
    component: EventContainer,
  },
  {
    name: 'layout.addEvent',
    path: 'events/add',
    component: AddEvents,
  },
  {
    name: 'layout.editEvent',
    path: 'event/edit/:id',
    component: EditEvent,
  },
  {
    name:'layout.addFair',
    path: 'fairs/add',
    component: AddFair,
  },
  {
    name:'layout.fairs',
    path: 'fairs',
    component: AddFair,
  },
  {
    name:'layout.reports',
    path: 'reports',
    component: ReportsContainer,
  },
  {
    name:'layout.participants',
    path: 'participants/:module&:firstName&:lastName&:member&:page',
    component: ParticipantsContainer,
  },
  {
    name:'layout.participant',
    path: 'participant/:id',
    component: ParticipantContainer,
  },
  {
    name:'layout.addParticipant',
    path: 'participants/:module/add/:id',
    component: AddParticipant,
  },
  {
    name:'layout.editParticipant',
    path: 'participants/edit/:id',
    component: EditParticipant,
  },
  {
    name:'layout.invitations',
    path: 'invitations/:module&:subject',
    component: InvitationsContainer,
  },
  {
    name:'layout.invitation',
    path: 'invitation/:module/:id',
    component: InvitationContainer,
  },
  
  // administration
  {
    name:'layout.subsidies',
    path: 'subsidies',
    component: Subsidies,
  },
  {
    name:'layout.addSubsidy',
    path: 'subsidies/add',
    component: AddSubsidy,
  },
  {
    name:'layout.editSubsidy',
    path: 'subsidies/edit/:id',
    component: EditSubsidy,
  },
  {
    name:'layout.properties',
    path: 'properties',
    component: Properties,
  },
  {
    name:'layout.addProperty',
    path: 'properties/add',
    component: AddProperty,
  },
  {
    name:'layout.editProperty',
    path: 'properties/edit/:id',
    component: EditProperty,
  },
  {
    name:'layout.services',
    path: 'services',
    component: Services,
  },
  {
    name:'layout.addService',
    path: 'services/add',
    component: AddService,
  },
  {
    name:'layout.editService',
    path: 'services/edit/:id',
    component: EditService,
  },
  {
    name:'layout.activityDirections',
    path: 'activityDirections?:page',
    component: ActivityDirections,
  },
  {
    name:'layout.addActivityDirection',
    path: 'addActivityDirection/add',
    component: AddActivityDirection,
  },
  {
    name:'layout.editActivityDirection',
    path: 'editActivityDirection/edit/:id',
    component: EditActivityDirection,
  },
  {
    name:'layout.itemCategories',
    path: 'itemCategories?:page&:isPublic&:name',
    component: ItemCategories,
  },
  {
    name:'layout.addItemCategory',
    path: 'addItemCategory/add',
    component: AddItemCategory,
  },
  {
    name:'layout.editItemCategory',
    path: 'editItemCategory/edit/:id',
    component: EditItemCategory,
  },
  {
    name:'layout.legalForms',
    path: 'legalForms',
    component: LegalForms,
  },
  {
    name:'layout.addLegalForm',
    path: 'addLegalForm/add',
    component: AddLegalForm,
  },
  {
    name:'layout.editLegalForm',
    path: 'editLegalForm/edit/:id',
    component: EditLegalForm,
  },
  {
    name:'layout.addInvitation',
    path: 'invitations/add/:module',
    component: AddInvitationContainer,
  },
  {
    name:'layout.editInvitation',
    path: 'invitations/:module/edit/:id',
    component: EditInvitationContainer,
  },
  // Lobby
  {
    name: 'layout.lobbies',
    path: 'lobbies?:page&:name&:typeId&:international&:local',
    component: LobbiesContainer,
  },
  {
    name: 'layout.lobbyTypes',
    path: 'lobby/types',
    component: LobbyTypesContainer,
  },
  {
    name: 'layout.addLobbyTypes',
    path: 'lobby/types/add',
    component: AddLobbyType,
  },
  {
    name: 'layout.editLobbyTypes',
    path: 'lobby/types/edit/:id',
    component: EditLobbyType,
  },
  {
    name: 'layout.lobby',
    path: 'lobby/:lobbyId?:page',
    component: LobbyContainer,
  },
  {
    name: 'layout.addLobby',
    path: 'lobby/add',
    component: AddLobby,
  },
  {
    name: 'layout.editLobby',
    path: 'lobby/edit/:id',
    component: EditLobby,
  },
  
  // Training
  {
    name: 'layout.trainings',
    path: 'trainings',
    component: TrainingsContainer,
  },
  {
    name: 'layout.addTraining',
    path: 'trainings/add/:trainerId',
    component: AddTrainingContainer,
  },
  {
    name: 'layout.editTraining',
    path: 'trainings/edit/:id',
    component: EditTraining,
  },
  {
    name: 'layout.training',
    path: 'trainings/:trainingId?:page',
    component: TrainingContainer,
  },
  {
    name: 'layout.trainingTypes',
    path: 'training/types',
    component: TrainingTypesContainer,
  },
  {
    name: 'layout.addTrainingTypes',
    path: 'training/types/add',
    component: AddTrainingType,
  },
  {
    name: 'layout.editTrainingTypes',
    path: 'training/types/edit/:id',
    component: EditTrainingType,
  },
  
  // Trainers
  {
    name: 'layout.trainers',
    path: 'training/trainers?:page&:firstName&:lastName',
    component: TrainersContainer,
  },
  {
    name: 'layout.addTrainer',
    path: 'training/trainers/add',
    component: AddTrainer,
  },
  {
    name: 'layout.editTrainer',
    path: 'training/trainers/edit/:id',
    component: EditTrainer,
  },
  {
    name: 'layout.trainer',
    path: 'training/trainer/:id',
    component: TrainerContainer,
  },
  
  // Users
  {
    name: 'layout.users',
    path: 'users',
    component: Users,
  },
  {
    name: 'layout.addUser',
    path: 'users/create',
    component: AddUser,
  },
  {
    name: 'layout.editUser',
    path: 'users/edit/:id',
    component: EditUser,
  },
];

export const getComponent = (currentRoute) => {
  if (currentRoute && currentRoute.name) {
    const {name = ''} = currentRoute;
    const segments = name.split('.');
    const getRouteComponent = config => config.component;
    const config = segments.reduce(
      (acc, segment) => {
        const segmentPath = `${acc.segmentPath}${acc.segmentPath && '.'}${segment}`;
        const config = routes.find(route => route.name === segmentPath);
        if (config.component) {
          acc.components.push(config);
        }
        return {
          ...acc,
          segmentPath,
        };
      },
      {
        components: [],
        segmentPath: '',
      }
    );

    const wrapperConfig = config.components.shift();
    return createElement(
      getRouteComponent(wrapperConfig),
      null,
      config.components.reverse().reduce(
        (ChildRoute, routeConfig) =>
          createElement(
            getRouteComponent(routeConfig),
            {
              key: routeConfig.name,
            },
            ChildRoute
          ),
        null
      )
    );
  }
  return null;
};

const scrollToTopOnRouteChangePlugin = () => {
  return {
    onTransitionSuccess: () => {
      window.scrollTo(0, 0);
    },
  };
};

const authMiddleware = (router, dependencies) => async (toState, fromState, done) => {
  const { store, authActions } = dependencies;
  if (toState && ['public', 'payment'].includes(toState.name)) {
    return done();
  }
  try {
    const { value } = await store.dispatch(authActions.fetchUser());
    const { value: details } = await store.dispatch(authActions.fetchUserDetails());
    let accesses;
    try {
      accesses = details.moduleAccess ? JSON.parse(details.moduleAccess) : {};
    } catch (e) {
      accesses = {};
    }
    const hasAccess = Object.keys(accesses).some(i => "layout.members".includes(i));
    
    if (!hasAccess && toState && toState.name !== 'layout.home' && !details.isAdmin) {
      return done({ redirect: { name: 'layout.home' } });
    }

    reactLocalStorage.set('accessToken', value.accessToken);
  } catch (e) {
    if (toState.name !== 'auth') {
      return done({ redirect: { name: 'auth' } });
    }
  }
  // return done();
};

export const router = createRouter(routes, {});

router.usePlugin(loggerPlugin);
router.useMiddleware(authMiddleware);
router.usePlugin(scrollToTopOnRouteChangePlugin);
router.usePlugin(browserPlugin());
