import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import { ChartsTabs } from './components/chartsTabs';
import { FilterForm } from './components/filterForm';

const HomePage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchValues, setSearchValues] = useState([]);

  return (
    <Container fluid>
      <FilterForm setFilteredData={setFilteredData} setSearchValues={setSearchValues} />
      <ChartsTabs filteredData={filteredData} searchValues={searchValues} setFilteredData={setFilteredData} />
    </Container>
  );
};

export default HomePage;
