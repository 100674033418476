import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Radios, Select, TextField } from 'mui-rff';
import styled from '@emotion/styled';
import map from 'lodash/map';

import { AddressInputs } from '../../../_shared/components';
import { useDispatchHook } from '../../../utils/hooks';
import { eventsActions, eventsSelectors } from '../../../redux/events';

const Form = () => {
  const { t } = useTranslation();
  
  const fetchEventTypes = useDispatchHook(eventsActions.fetchEventTypes);
  
  const types = useSelector(eventsSelectors.types);
  
  useEffect(() => {
    fetchEventTypes({ noPagination: true });
  }, [fetchEventTypes]);
  
  return (
    <Row>
      <Col md={6}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('addMember.generalData')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={12}>
                <Select
                  name="typeId"
                  label={t('addEvent.type')}
                  formControlProps={{ margin: 'none' }}
                  required
                >
                  {map(types, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={t('forms.name')}
                  name="name"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Radios
                  label={t('addEvent.international')}
                  name="international"
                  formControlProps={{ margin: 'none' }}
                  color="primary"
                  radioGroupProps={{ row: true }}
                  data={[
                    { label: t('addEvent.yes'), value: '1' },
                    { label: t('addEvent.no'), value: '0' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="standard-textarea"
                  label={t('addEvent.note')}
                  name="description"
                  multiline
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
      <Col md={6}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('forms.address')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <AddressInputs />
            </Grid>
          </AccordionDetails>
        </Column>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('addEvent.period')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="startDate"
                  name="startDate"
                  label={t('addEvent.startDate')}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="endDate"
                  name="endDate"
                  label={t('addEvent.endDate')}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
    </Row>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default Form;
