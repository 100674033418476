import React, {useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MenuItem, Menu } from '@material-ui/core';
import styled from '@emotion/styled';
import get from 'lodash/get';
import compact from 'lodash/compact';
import join from 'lodash/join';
import Alert from '@material-ui/lab/Alert';
import { toastr } from 'react-redux-toastr';
import CONFIG from '../../config';
import { PageHeader } from '../../_shared/headers';
import { filedToCurrentLanguage } from '../../utils';
import { useDispatchHook, useRoute } from "../../utils/hooks";
import { eventsActions, eventsSelectors } from '../../redux/events';
import { participantsActions } from '../../redux/participants';
import { ButtonPrimary } from './../../_shared';
import { PaperBox, ItemParticipantsList } from '../../_shared/components';
import { ReportForm } from './components/reportForm';

const EventContainer = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isReportShown, setReportState] = React.useState(false);
  const [generatedReport, setGeneratedReport] = useState(null);
  const [participants, setParticipants] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    perPage: 0,
    pages: 0,
    currentPage: 0,
  });
  
  const fetchEvent = useDispatchHook(eventsActions.fetchEvent);
  const createReport = useDispatchHook(eventsActions.createReport);
  const fetchParticipantsByModuleAndId = useDispatchHook(participantsActions.fetchParticipantsByModuleAndId);
  
  const event = useSelector(eventsSelectors.event);
  
  const fetch = useCallback(async () => {
    const { value } = await fetchParticipantsByModuleAndId('event', get(route, 'params.eventId'), get(route, 'params.page', 1));
  
    if (get(value, 'data', []).length) {
    
      setParticipants(get(value, 'data', []));
      setPagination({
        total: value.total,
        perPage: value.per_page,
        pages: value.last_page,
        currentPage: value.current_page,
      });
    };
  }, [fetchParticipantsByModuleAndId, route]);
  
  useEffect(() => {
    fetchEvent(get(route, 'params.eventId'));
    fetch();
  }, [fetchEvent, fetchParticipantsByModuleAndId, fetch, route]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const address = join(compact([get(event, 'country'), get(event, 'city'), get(event, 'village')]), ', ');
  const rows = [
    {
      name: t('addEvent.type'),
      value: filedToCurrentLanguage(get(event, 'type')),
    },
    {
      name: t('addEvent.international'),
      value: get(event, 'international') ? t('common.yes') : t('common.no'),
    },
    {
      name: t('forms.address'),
      value: address,
    },
    {
      name: `${t('addEvent.startDate')} - ${t('addEvent.endDate')}`,
      value: `${get(event, 'startDate')} / ${get(event, 'endDate')}`,
    },
    {
      name: t('forms.note'),
      value: get(event, 'description'),
    },
  ];
  
  const generateReport = useCallback(async values => {
    try {
      const { value } = await createReport({
        name: get(values, 'name'),
        moduleName: 'event',
        eventId: get(route, 'params.eventId'),
        meta: JSON.stringify({}),
      });
      const date = Date.now();
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleClose();
      toastr.success(t('common.reportWereCreated'));
    } catch (e) {
      console.warn(e);
    }
    setReportState(false);
  }, [t, createReport, setReportState, route]);

  return (
    <Container fluid>
      <PageHeader title={get(event, 'name')} backLink="hash" />
      <Row>
        <Column md={12} xs={12}>
          <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            {t('buttons.actions')}
          </ButtonPrimary>
          <Menu
            id="event-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={generateReport}>{t('common.generateReport')}</MenuItem>
            <MenuItem onClick={() => {
              navigateTo('layout.addParticipant', { module: 'event', id: get(route, 'params.eventId') }, {replace: false})
            }}>{t('common.addParticipant')}</MenuItem>
            {/*<MenuItem onClick={() => {}}>{t('common.addSpeaker')}</MenuItem>*/}
            <MenuItem onClick={() => navigateTo('layout.editEvent', { id: get(route, 'params.eventId') }, {replace: false})}>{t('common.modify')}</MenuItem>
          </Menu>
        </Column>
        <Column md={12} xs={12}>
          {isReportShown ? <ReportForm setGeneratedReport={setGeneratedReport} setReportState={setReportState} defaultName={get(event, 'name')} /> : null}
          {generatedReport && (
            <AlertWrapper>
              {t('common.reportWereGenerated', { name: get(generatedReport, 'name') })},&nbsp;
              <a href={`${CONFIG.api}/uploads/reports/${get(generatedReport, 'fileName')}`}>{t('common.clickHereToDownload')}</a> sau&nbsp;
            </AlertWrapper>
          )}
          <PaperBox rows={rows} />
        </Column>
      </Row>
      <h2>{t('participants.header', { count: pagination.total })}</h2>
      <ItemParticipantsList participants={participants} pagination={pagination} fetch={fetch} />
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

const AlertWrapper = styled(Alert)`
  margin-bottom: ${({theme}) => theme.block.margin};
  a {
    font-weight: bold;
  }
`;

export default EventContainer;
