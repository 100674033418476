import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useDispatchHook } from '../../utils/hooks';
import { PageHeader } from '../../_shared/headers';
import { reportsActions, reportsSelectors } from '../../redux/reports';
import { GridList, Pagination } from '../../_shared';
import { ReportsSearchForm } from './components/reportsSearchForm';
import CONFIG from '../../config';

const ReportsContainer = () => {
  const { t }= useTranslation('');
  
  const reports = useSelector(reportsSelectors.reports);
  const pagination = useSelector(reportsSelectors.pagination);
  
  const fetchReports = useDispatchHook(reportsActions.fetchReports);
  
  useEffect(() => {
    fetchReports();
  }, [fetchReports]);
  
  const columns = [
    { field: 'name', headerName: t('reports.name'), width: 56.6, externalLink: { link: `${CONFIG.api}/uploads/reports`, field: 'fileName' } },
    { field: 'created_at', headerName: t('reports.date'), width: 26.3 },
  ];
  
  return (
    <Container fluid>
      <PageHeader title={t('reports.header')} />
      <Row>
        <Col xs={12} md={9} lg={8}>
          <GridList
            columns={columns}
            rows={reports}
          />
        </Col>
        <Col xs={12} md={3} lg={4}>
          <ReportsSearchForm />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default ReportsContainer;
