import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Select, TextField } from 'mui-rff';
import map from 'lodash/map';

import { AddressInputs } from '../../../_shared/components';
import { fairsSelectors } from '../../../redux/fairs';

const Form = () => {
  const { t } = useTranslation();
  
  const subjects = useSelector(fairsSelectors.subjects);
  
  return (
    <Row>
      <Col md={6}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('addMember.generalData')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={t('forms.name')}
                  name="name"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="note-textarea"
                  label={t('addEvent.note')}
                  name="note"
                  multiline
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Select
                  name="subjectId"
                  label={t('addEvent.subject')}
                  formControlProps={{ margin: 'none' }}
                >
                  {map(subjects, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                </Select>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
      <Col md={6}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('forms.address')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <AddressInputs />
            </Grid>
          </AccordionDetails>
        </Column>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>{t('addEvent.period')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="startDate"
                  name="startDate"
                  label={t('addEvent.startDate')}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="endDate"
                  name="endDate"
                  label={t('addEvent.endDate')}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
    </Row>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default Form;
