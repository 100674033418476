import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';

import { PageHeader } from '../../_shared/headers';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { lobbyActions } from '../../redux/lobby';
import TypeForm from './components/typeForm';

const AddLobbyType = () => {
  const { t } = useTranslation();
  const { navigateTo } = useRoute();
  const createLobbyType = useDispatchHook(lobbyActions.createLobbyType);
  
  const onSubmit = useCallback(async ({ name }) => {
    await createLobbyType({ name: JSON.stringify(name) });
    navigateTo('layout.lobbyTypes', {}, {replace: false});
  }, [createLobbyType, navigateTo]);
  
  return (
    <Container fluid>
      <PageHeader title={t('lobbyType.add')} backLink="layout.lobbyTypes" />
      <FormWrapper
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <TypeForm />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  {t('buttons.reset')}
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddLobbyType;