import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

type Props = {
  text: string,
  className?: ?string,
}

export const HeaderText = ({text, className}: Props) => (
  <Container className={className}>
    <Col md={3} />
    <InfoBlock md={9}>
      {text}
      <IconWrapper>
        <Icon icon={faSearch} />
        <Expanded>
          <span />
          <span />
          <span />
        </Expanded>
      </IconWrapper>
    </InfoBlock>
  </Container>
);

const Container = styled(Row)`
  font-size: ${({theme}) => theme.fontSize.md};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  margin-bottom: ${({theme}) => theme.block.margin};
`;

const InfoBlock = styled(Col)`
  text-align: right;
  text-transform: uppercase;
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: ${({theme}) => theme.block.margin};
  flex: 0 0 ${({theme}) => theme.block.margin};
  color: ${({theme}) => theme.background.buttonSecondary};
`;

const Expanded = styled('div')`
  width: 100%;
  
  span {
    position: relative;
    display: block;
    border-bottom: 1px dotted ${({theme}) => theme.background.buttonSecondary};
    margin-bottom: 1px;
  }
`;
