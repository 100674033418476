import React, { Node, useCallback } from 'react';
import styled from '@emotion/styled';
import isMatch from 'lodash/isMatch';

import { router } from '../../router';
import { useRoute } from '../../utils/hooks';

type Props = {
  children: Node,
  to: string,
  className?: string,
  params?: object,
};

export const Link = ({to, className, params, children, ...rest}: Props) => {
  const href = router.buildUrl(to, { ...params });
  const { route, navigateTo } = useRoute();
  const isActive = to === route.name && isMatch(route.params, params);

  const handleClick = useCallback(e => {
    e.preventDefault();
    navigateTo(to, params);
  }, [navigateTo, to, params]);
  
  return (
    <Url href={href} active={isActive} onClick={handleClick} className={className} {...rest}>
      {children}
    </Url>
  )
};

export const Href = ({to, className, params, children, ...rest}: Props) => {
  const { route, navigateTo } = useRoute();
  const isActive = to === route.name && isMatch(route.params, params);
  const href = router.buildUrl(to, { ...params });
  
  const handleClick = useCallback(e => {
    e.preventDefault();
    navigateTo(to, params);
  }, [navigateTo, to, params]);
  
  return (
    <UrlHref href={href} active={isActive} onClick={handleClick} className={className} {...rest}>
      {children}
    </UrlHref>
  )
};

const Url = styled('a')`
  color: ${({theme}) => theme.colors.primary};
  font-weight: ${({active}) => active ? '600' : '400'};
  margin-right: ${({theme}) => theme.block.margin};
  text-decoration: none;
  &:hover {
    background-color: none;
  }
  &:active {
    background-color: none;
  }
`;

const UrlHref = styled('a')`
  color: ${({theme}) => theme.colors.primary};
  font-weight: ${({active}) => active ? '600' : '400'};
  text-decoration: none;
`;

Link.defaultProps = {
  className: null,
};

export default Link;
