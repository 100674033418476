import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField } from 'mui-rff';
import styled from '@emotion/styled';
import {Field} from "react-final-form";
import MuiPhoneNumber from "material-ui-phone-number";

const TrainerForm = () => {
  const { t } = useTranslation();
  
  return (
    <Row>
      <Col md={6}>
        <Column expanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{t('addMember.generalData')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('forms.firstName')}
                  name="firstName"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('forms.lastName')}
                  name="lastName"
                  margin="none"
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field name="phone" type="checkbox">
                  {props => (
                    <MuiPhoneNumber
                      data-cy={props.input.name}
                      defaultCountry={'md'}
                      label={t('addMember.phoneNumber')}
                      preferredCountries={['md', 'ro', 'ru']}
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      required
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={t('addMember.email')}
                  name="email"
                  type="email"
                  margin="none"
                  required
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Column>
      </Col>
    </Row>
  );
};

const Column = styled(Accordion)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

export default TrainerForm;
