import React, { createContext, useState } from 'react';

export const LayoutContext = createContext();

type ProviderProps = {
  children: React.Node,
};

export const LayoutProvider = ({ children }: ProviderProps) => {
  const [images, setImages] = useState([]);
  const [isLoadingPage, setIsLoadingState] = useState(false);
  
  return (
    <LayoutContext.Provider
      value={{
        images,
        setImages,
        isLoadingPage,
        setIsLoadingState,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

