import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import get from 'lodash/get';

const language = createSelector(
  state => state.app.get('language'),
  state => state.router.route,
  (language, route) => get(route, 'params.lng', language),
);

const languages = createSelector(
  state => state.app.get('languages'),
  languages => languages.toJS(),
);

const countries = createSelector(
  state => state.app.get('countries'),
  countries => countries.toJS(),
);

const regions = createSelector(
  state => state.app.get('regions'),
  regions => regions.toJS(),
);

const towns = createSelector(
  state => state.app.get('towns'),
  towns => towns.toJS(),
);

export const appSelectors = {
  language,
  languages,
  countries,
  regions,
  towns,
};

const initialState = fromJS({
  languages: ['ro', 'ru', 'en'],
  language: 'ro',
  countries: [],
  regions: [],
  towns: [],
});

export const reducer = handleActions(
  {
    APP_SET_LANGUAGE: (state, { payload }) => state.set('language', fromJS(payload)),
    APP_FETCH_COUNTRIES: (state, { payload }) => state.set('countries', fromJS(payload)),
    APP_FETCH_REGIONS: (state, { payload }) => state.set('regions', fromJS(payload)),
  },
  initialState
);

export const appActions = {
  setLanguage: createAction('APP_SET_LANGUAGE'),
  fetchCountries: createAction('APP_FETCH_COUNTRIES'),
  fetchRegions: createAction('APP_FETCH_REGIONS'),
};
