import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../_shared';
import { Founders, Events, Lobbies, Notices, Imports, Trainings } from './relations';

type Props = {
  modalList: string,
  setModalListState: Function,
}

export const RelationsModal = ({ modalList, setModalListState }: Props) => {
  const { t } = useTranslation('');
  
  if (!modalList) return null;
  
  let content;
  switch(modalList){
    case 'founders':
      content = <Founders />;
      break;
    case 'events':
      content = <Events />;
      break;
    case 'lobbies':
      content = <Lobbies />;
      break;
    case 'notices':
      content = <Notices />;
      break;
    case 'imports':
      content = <Imports />;
      break;
    case 'trainings':
      content = <Trainings />;
      break;
    default:
      content = null;
  }
  
  return (
    <Modal
      isOpen={!!modalList}
      onClose={() => setModalListState(false)}
      header={t(`memberModalList.${modalList}`)}
      maxWidth="xl"
    >
      {content}
    </Modal>
  )
};

