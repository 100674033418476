import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Button, ButtonGroup } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';
import get from 'lodash/get';
import join from 'lodash/join';
import map from 'lodash/map';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { toastr } from 'react-redux-toastr';

import { PageHeader } from '../../_shared';
import { PaperBox } from '../../_shared/components';
import { ButtonPrimary } from '../../_shared';
import { MemberModals } from './components/memberModals';
import { RelationsModal } from './components/relationsModal';
import { filedToCurrentLanguage } from '../../utils';
import { useDispatchHook, useRoute } from '../../utils/hooks';
import { membersActions, membersSelectors } from '../../redux/members';
import { DuesList, ImportExportList } from './components';
import { authSelectors } from '../../redux/auth';
import CONFIG from '../../config';
import { useMember } from '../../hooks';
import { PaymentTicketModal } from './paymentTicketModal';

const MemberContainer = () => {
  const { t } = useTranslation();
  const { route: { params }, navigateTo } = useRoute();
  
  const member = useSelector(membersSelectors.member);
  const dues = useSelector(membersSelectors.dues);
  const imports = useSelector(membersSelectors.imports);
  const user = useSelector(authSelectors.user);
  
  const fetchMember = useDispatchHook(membersActions.fetchMember);
  const fetchDues = useDispatchHook(membersActions.fetchDues);
  const fetchImports = useDispatchHook(membersActions.fetchImports);
  const deleteDue = useDispatchHook(membersActions.deleteDue);
  const deleteImport = useDispatchHook(membersActions.deleteImport);
  const clearMember = useDispatchHook(membersActions.clearMember);
  const generateProfile = useDispatchHook(membersActions.generateProfile);

  const [showModal, setModalState] = useState(null);
  const [modalList, setModalListState] = useState(null);
  const [isPaymentTicketShown, setPaymentModalStatus] = useState(null);

  const { handleDownloadCard } = useMember();

  const formNoteInitial = {
    isPublic: '1',
  };

  const founderFormInitial = {
    type: '1',
    person_type: '0',
  };
  
  const [formImportExportInitial, setFormImportExportInitial] = useState({
    importCountryId: [],
    exportCountryId: [],
  });

  const blockFormInitial = {};
  const duesFormInitial = {
    date: new Date(),
  };
  
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  
  const handleClose = () => setAnchorEl(null);
  
  const onGenerateProfile = useCallback(async () => {
    try {
      const { value } = await generateProfile({ id: get(params, 'memberId') });
      const date = Date.now();
      const url = window.URL.createObjectURL(new Blob([value]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      handleClose();
      toastr.success(t('common.reportWereCreated'));
    } catch (e) {
      console.warn(e);
    }
    handleClose(false);
  }, [generateProfile, params, t]);
  
  useEffect(() => {
    (async() => {
      await Promise.all([
        fetchMember(get(params, 'memberId')),
        fetchDues(get(params, 'memberId')),
        fetchImports(get(params, 'memberId')),
      ]);
    })();
  }, [fetchMember, fetchDues, fetchImports, params]);
  
  useEffect(() => {
    return () => {
      clearMember();
    }
  }, [clearMember]);
  
  useEffect(() => {
    if (imports.length) {
      const grouped = groupBy(imports, 'type');
      setFormImportExportInitial({
        importCountryId: get(grouped, 'import', []).map(({countryId}) => countryId),
        exportCountryId: get(grouped, 'export', []).map(({countryId}) => countryId),
      });
    }
  }, [imports]);
  
  const formatAddress = member => {
    const address = omit(member.address, ['legalAddress']);

    return join(compact([
      get(member, 'city'),
      get(member, 'village'),
      get(address, 'street', null),
      get(address, 'home', null),
      get(address, 'office', null),
      get(address, 'postCode', null),
      get(address, 'phone', null),
    ]), ', ');
  };
  
  const legal = get(member, 'address.legalAddress', {});
  const legalAddress = join(compact(Object.values(legal)), ', ');
  const activities = join(map(get(member, 'activities', []), item => filedToCurrentLanguage(item)), ', ');
  const services = join(map(get(member, 'services', []), item => filedToCurrentLanguage(item)), ', ');
  const itemCategories = join(map(get(member, 'itemCategories', []), item => filedToCurrentLanguage(item)), ', ');

  const rows = [
    {
      name: t('addMember.name'),
      value: get(member, 'name'),
    },
    {
      name: t('addMember.idno'),
      value: get(member, 'idno'),
    },
    {
      name: t('addMember.membershipCard'),
      value: get(member, 'membershipCard'),
    },
    {
      name: t('addMember.registeredDate'),
      value: get(member, 'registeredDate'),
    },
    {
      name: t('addMember.foundationDate'),
      value: get(member, 'foundationDate'),
    },
    {
      name: t('addMember.address'),
      value: formatAddress(member),
    },
    {
      name: t('addMember.legalAddress'),
      value: legalAddress,
    },
    {
      name: t('addMember.subsidy'),
      value: filedToCurrentLanguage(get(member, 'subsidy')),
    },
    {
      name: t('addMember.activityDirections'),
      value: activities,
    },
    {
      name: t('addMember.itemCategories'),
      value: itemCategories,
    },
    {
      name: t('addMember.services'),
      value: services,
    },
  ];
  
  const onDeleteDue = useCallback(async id => {
    await deleteDue(id);
    fetchDues(get(params, 'memberId'));
  }, [deleteDue, fetchDues, params]);
  
  const onDeleteImport = useCallback(async id => {
    await deleteImport({ id });
    fetchImports(get(params, 'memberId'));
  }, [deleteImport, fetchImports, params]);
  
  const rows2 = [
    {
      name: t('addMember.ownershipType'),
      value: filedToCurrentLanguage(get(member, 'property')),
    },
    {
      name: t('addMember.legalForms'),
      value: filedToCurrentLanguage(get(member, 'legalForm')),
    },
    {
      name: t('addMember.paymentOfDues'),
      value: <DuesList dues={dues} onDeleteDue={onDeleteDue} />,
    },
    {
      name: t('addMember.importExport'),
      value: <ImportExportList items={imports} onDeleteImport={onDeleteImport} />,
    },
    /*{
      name: t('addMember.dual'),
      value: get(member, 'isDual') ? t('common.yes') : t('common.no'),
    },
    {
      name: t('addMember.noticeOfAttestation'),
      value: get(member, 'hasAttestation') ? t('common.yes') : t('common.no'),
    },
    {
      name: t('addMember.meditation'),
      value: get(member, 'hasMeditation') ? t('common.yes') : t('common.no'),
    },
    {
      name: t('addMember.services'),
      value: get(member, 'hasServices') ? t('common.yes') : t('common.no'),
    },*/
    {
      name: t('addMember.email'),
      value: join(get(member, 'email'), ', '),
    },
    {
      name: t('addMember.webPage'),
      value: join(get(member, 'webPage'), ', '),
    },
    {
      name: t('addMember.socialLink'),
      value: join(compact(get(member, 'social')), ', '),
    },
    {
      name: t('common.tags'),
      value: join(get(member, 'tags'), ', '),
    },
    {
      name: t('addMember.description'),
      value: get(member, 'description'),
    },
  ];
  
  const userHasRestrictions = get(user, 'editRestrictions', 0);

  return (
    <Container fluid>
      <PageHeader title={get(member, 'name')} backLink="hash" />
      <Row>
        <Column md={12} xs={12}>
          <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            {t('buttons.actions')}
          </ButtonPrimary>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              onGenerateProfile();
              handleClose();
            }}>{t('member.generateProfile')}</MenuItem>
            {userHasRestrictions ? null : (
              <>
                <MenuItem onClick={() => {
                  setModalState('import');
                  handleClose();
                }}>{t(`member.${isEmpty(imports) ? 'addImportExport' : 'editImportExport'}`)}</MenuItem>
                <MenuItem onClick={() => {
                  setModalState('note');
                  handleClose();
                }}>{t('member.addNote')}</MenuItem>
                <MenuItem onClick={() => {
                  setModalState('fee');
                  handleClose();
                }}>{t('member.membershipFee')}</MenuItem>
                <MenuItem onClick={() => {
                  setModalState('founder');
                  handleClose();
                }}>{t('member.addFounder')}</MenuItem>
                <MenuItem onClick={() => handleDownloadCard('card', get(member, 'id'), handleClose, 'card-member')}>{t('requests.downloadCard')}</MenuItem>
                <MenuItem onClick={() => handleDownloadCard('patent', get(member, 'id'), handleClose, 'card-member')}>{t('requests.downloadPatent')}</MenuItem>
                <MenuItem onClick={() => setPaymentModalStatus(true)}>{t('requests.generatePaymentTicket')}</MenuItem>
                <MenuItem onClick={() => navigateTo('layout.editMember', { id: get(params, 'memberId') }, {replace: false})}>{t('common.modify')}</MenuItem>
              </>
            )}
          </Menu>
          {!!get(member, 'logoUrl') && (
            <ImageWrapper>
              <Logo src={`${CONFIG.api}/images/${get(member, 'logoUrl')}`} />
            </ImageWrapper>
          )}
        </Column>
        <Column md={12} xs={12}>
          <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
            <Button onClick={() => setModalListState('founders')}>{t('memberModalList.founders')}</Button>
            <Button onClick={() => setModalListState('events')}>{t('memberModalList.events')}</Button>
            <Button onClick={() => setModalListState('lobbies')}>{t('memberModalList.lobbies')}</Button>
            <Button onClick={() => setModalListState('notices')}>{t('memberModalList.notices')}</Button>
          </ButtonGroup>
          <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
            <Button onClick={() => setModalListState('trainings')}>{t('memberModalList.trainings')}</Button>
          </ButtonGroup>
        </Column>
        <Column md={12} xs={12}>
          {!!get(member, 'isMember') && <AlertWrapper severity="info">{t('common.isMember')}</AlertWrapper>}
          <PaperBox
            rows={rows}
          />
        </Column>
        <Column md={12} xs={12}>
          <PaperBox
            rows={rows2}
          />
        </Column>
      </Row>
      
      <MemberModals
        showModal={showModal}
        setModalState={setModalState}
        formNoteInitial={formNoteInitial}
        importFormInitial={formImportExportInitial}
        blockFormInitial={blockFormInitial}
        duesFormInitial={duesFormInitial}
        founderFormInitial={founderFormInitial}
      />
      <RelationsModal
        modalList={modalList}
        setModalListState={setModalListState}
      />
      <PaymentTicketModal isShown={isPaymentTicketShown} setModalStatus={setPaymentModalStatus} request={member} withoutTax />
    </Container>
  );
};

const Column = styled(Col)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

const AlertWrapper = styled(Alert)`
  margin-bottom: ${({theme}) => theme.block.margin};
`;

const ImageWrapper = styled('div')`
  margin: ${({theme}) => theme.block.margin} 0 ${({theme}) => theme.block.margin} 0;
  display: flex;
  justify-content: center;
`;

const Logo = styled('img')`
  max-width: 100%;
`;

export default MemberContainer;
