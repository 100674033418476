import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import get from 'lodash/get';

import { PageHeader } from '../../../_shared/headers';
import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { administrationActions } from '../../../redux/administration';
import { authActions } from '../../../redux/auth';
import Form from './components/form';

const EditUser = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  const [initialValues, setInitialValues] = useState({});
  
  const updateUser = useDispatchHook(administrationActions.updateUser);
  const fetchDetails = useDispatchHook(authActions.fetchDetails);
  
  useEffect(() => {
    (async () => {
      const { value } = await fetchDetails(get(route, 'params.id'));
      let moduleAccess;
      try {
        moduleAccess = get(value, 'moduleAccess') ? JSON.parse(value.moduleAccess) : {};
      } catch (e) {
        moduleAccess = {};
      }
      setInitialValues({
        name: get(value, 'name'),
        email: get(value, 'email'),
        subsidyId: get(value, 'subsidyId'),
        editRestrictions: get(value, 'editRestrictions'),
        moduleAccess,
      });
    })();
  }, [fetchDetails, route, setInitialValues]);

  const onSubmit = useCallback(async values => {
    await updateUser({ ...values, moduleAccess: JSON.stringify(get(values, 'moduleAccess', '{}')) }, get(route, 'params.id'));
    navigateTo('layout.users', {}, { replace: false });
  }, [updateUser, navigateTo, route]);

  return (
    <Container fluid>
      <PageHeader title={t('users.edit')} backLink="layout.users" />
      <FormWrapper
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form initialValues={initialValues} />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.edit')}
                </Button>
              </Grid>
            </Grid>
            {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
          </form>
        )}
      />
    </Container>
  );
};

export default EditUser;