import React, { useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import { Pagination as Paginate } from "@material-ui/lab";

import { useRoute } from '../../utils/hooks';

type Props = {
  data: {
    total: number,
    perPage: number,
    pages: number,
    currentPage: number,
  }
}

export const Pagination = ({ data }: Props) =>  {
  const { route, navigateTo } = useRoute();
  const [page, setPage] = useState(null);
  
  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
    navigateTo(get(route, 'name'), {...get(route, 'params', {}), page: newPage}, { replace: false })
  }, [route, setPage, navigateTo]);
  
  useEffect(() => {
    if (!page && data.currentPage) {
      setPage(data.currentPage);
    }
  }, [data.currentPage, page, setPage]);

  if (get(data, 'pages', 1) <= 1) return null;

  return (
    <Paginate
      count={get(data, 'pages', 0)}
      page={page}
      onChange={handleChangePage}
      variant="outlined"
      shape="rounded"
    />
  );
};
