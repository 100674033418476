import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { CardBox } from '../../../../_shared/components';
import { useDispatchHook, useRoute } from '../../../../utils/hooks';
import { membersActions } from '../../../../redux/members';

export const Notices = () => {
  const { route } = useRoute();
  const { t } = useTranslation('');
  const fetchMemberNotices = useDispatchHook(membersActions.fetchMemberNotices);
  const deleteMemberNotice = useDispatchHook(membersActions.deleteMemberNotice);
  
  const [items, setItems] = useState([]);
  
  const fetchNotices = useCallback(async () => {
    const { value } = await fetchMemberNotices(get(route, 'params.memberId'));
    setItems(value);
  }, [fetchMemberNotices, setItems, route]);
  
  const renderActions = id => (
    <IconButton aria-label="delete" onClick={() => handleDelete(id)}>
      <DeleteIcon />
    </IconButton>
  );
  
  const handleDelete = useCallback(async id => {
    await deleteMemberNotice({ id });
    return fetchNotices();
  }, [deleteMemberNotice, fetchNotices]);
  
  
  useEffect(() => {
    fetchNotices();
  }, [fetchNotices]);

  return (
    <>
      {isEmpty(items) && (
        <Alert severity="warning">{t('public.noDataWereFound')}</Alert>
      )}
      <Wrapper>
        {items.map(item => (
          <CardBox
            key={item.id}
            title={get(item, 'name')}
            subTitle={get(item, 'created_at')}
            actions={renderActions(item.id)}
          >
            {get(item, 'description')}
          </CardBox>
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;