import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { CardBox } from '../../../../_shared/components';
import { useDispatchHook, useRoute } from '../../../../utils/hooks';
import { membersActions, membersSelectors } from '../../../../redux/members';

export const Founders = () => {
  const { route } = useRoute();
  const { t } = useTranslation('');
  const fetchMemberFounders = useDispatchHook(membersActions.fetchMemberFounders);
  const deleteMemberFounder = useDispatchHook(membersActions.deleteMemberFounder);
  const founders = useSelector(membersSelectors.founders);
  
  useEffect(() => {
    fetchMemberFounders(get(route, 'params.memberId'));
  }, [fetchMemberFounders, route]);
  
  const handleDelete = useCallback(async id => {
    await deleteMemberFounder({ id });
    fetchMemberFounders(get(route, 'params.memberId'));
  }, [deleteMemberFounder, fetchMemberFounders, route]);
  
  const renderActions = id => (
    <IconButton aria-label="delete" onClick={() => handleDelete(id)}>
      <DeleteIcon />
    </IconButton>
  );
  
  return (
    <>
      {isEmpty(founders) && (
        <Alert severity="warning">{t('public.noDataWereFound')}</Alert>
      )}
      <Wrapper>
        {founders.map(item => {
          const type = get(item, 'type') ? t('founder.founder') : t('founder.administrator');
          const title = item.person_type === 1 ? get(item, 'company') : `${get(item, 'firstName')} ${get(item, 'lastName')}`;
          return (
            <CardBox
              key={item.id}
              top={get(item, 'idnp') && `${t('forms.idnp')}: ${get(item, 'idnp')}`}
              title={title}
              subTitle={type}
              actions={renderActions(item.id)}
            >
              {get(item, 'city', '')}
              {get(item, 'village', '')}
              {get(item, 'email') && (
                <div><b>{t('forms.email')}</b>: <a href={`mailto: ${get(item, 'email')}`}>{get(item, 'email')}</a></div>
              )}
              {get(item, 'phone') && (
                <div><b>{t('forms.phoneNumber')}</b>: {get(item, 'phone')}</div>
              )}
              <div><b>{t('forms.personType')}</b>: {item.person_type === 1 ? t('member.legal') : t('member.individual')}</div>
            </CardBox>
          )
        })}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;