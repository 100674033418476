import React, { Node} from 'react';
import { Button } from '@material-ui/core';
import styled from '@emotion/styled';

type PrimaryButtonProps = {
  children: Node,
  className: string,
}

export const ButtonPrimary = ({children, className, ...rest}: PrimaryButtonProps) => (
  <Primary {...rest} className={className}>{children}</Primary>
);

export const ButtonOutline = ({children, className, ...rest}: PrimaryButtonProps) => (
  <Outline {...rest} className={className}>{children}</Outline>
);

const Primary = styled(Button)`
  background-color: ${({theme}) => theme.background.buttonSecondary} !important;
  margin-right: ${({theme}) => theme.block.margin} !important;
  color: ${({theme}) => theme.colors.white} !important;
  padding: 3px 16px !important;
`;

const Outline = styled(Button)`
  margin-right: ${({theme}) => theme.block.margin} !important;
  padding: 3px 16px !important;
`;

ButtonPrimary.defaultProps = {
  className: null,
};
