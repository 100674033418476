import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { CardBox } from '../../../../_shared/components';
import { useDispatchHook, useRoute } from '../../../../utils/hooks';
import { membersActions } from '../../../../redux/members';

export const Imports = () => {
  const { route } = useRoute();
  const { t } = useTranslation('');
  const fetchImports = useDispatchHook(membersActions.fetchImports);
  
  const [items, setItems] = useState([]);
  
  const fetch = useCallback(async () => {
    const { value } = await fetchImports(get(route, 'params.memberId'));
    setItems(value);
  }, [fetchImports, setItems, route]);
  
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      {isEmpty(items) && (
        <Alert severity="warning">{t('public.noDataWereFound')}</Alert>
      )}
      <Wrapper>
        {items.map(item => (
          <CardBox
            key={item.id}
            title={get(item, 'name')}
            subTitle={t(`importTypes.${get(item, 'type')}`)}
          />
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;