import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, Grid} from '@material-ui/core';
import { Form as FormWrapper } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { PageHeader } from '../../_shared/headers';
import { participantsActions } from '../../redux/participants';
import { AddParticipantForm } from './components/addParticipantForm';
import { validateParticipantForm } from './components/validations';

const AddParticipant = () => {
  const { t } = useTranslation();
  const { route } = useRoute();
  const initialValues = {
    type: '1',
  };
  
  const createParticipant = useDispatchHook(participantsActions.createParticipant);

  const onSubmit = useCallback(async values => {
    try {
      await createParticipant({
        ...values,
        module: get(route, 'params.module', 'event'),
        id: get(route, 'params.id')
      });
      toastr.success(t('common.success'));
      window.history.go(-1);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [createParticipant, route, t]);
  
  return (
    <Container fluid>
      <PageHeader title={t('common.addParticipant')} backLink="hash" />
      <FormWrapper
        onSubmit={onSubmit}
        validate={errors => validateParticipantForm(errors, t('member.requiredField'))}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
          <form onSubmit={handleSubmit} noValidate>
            <AddParticipantForm search />
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {t('buttons.add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default AddParticipant;
