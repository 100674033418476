import get from 'lodash/get';
import omit from 'lodash/omit';

export const memberModelToService = (model, images) => ({
  ...omit(model, ['street', 'postCode', 'home', 'office']),
  logoUrl: get(images, [0], null),
  tags: JSON.stringify(get(model, 'tags', [])),
  social: JSON.stringify(get(model, 'social', [])),
  email: JSON.stringify(get(model, 'email', [])),
  address: JSON.stringify({
    street: get(model, 'street', null),
    postCode: get(model, 'postCode', null),
    phone: get(model, 'phone', null),
    home: get(model, 'home', null),
    office: get(model, 'office', null),
    legalStreet: get(model, 'legalStreet', null),
    legalAddress: {
      street: get(model, 'legal_street', null),
      postCode: get(model, 'legal_postCode', null),
      home: get(model, 'legal_home', null),
      office: get(model, 'legal_office', null),
    }
  })
});

export const eventModelToService = model => ({
  ...model,
});