import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

type Props = {
  children: Node,
  header: string,
  isOpen: boolean,
  onClose: Function,
}

export const Modal = ({ children, isOpen, onClose, header, ...rest }: Props) => {
  const { t } = useTranslation();
  
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      maxWidth="md"
      fullWidth
      {...rest}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('buttons.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

Modal.defaultProps = {
  isOpen: false,
  onClose: noop,
};