import React, { useCallback } from 'react';
import { Form as FormWrapper } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';

import { useDispatchHook, useRoute } from '../../../utils/hooks';
import { membersActions } from '../../../redux/members';
import { FormNote } from './formNote';
import { FormBlockMember } from './formBlockMember';
import { FormImportExport } from './formImportExport';
import { MembershipFeeForm } from './membershipFeeForm';
import { FormFounder } from './formFounder';
import { Modal } from '../../../_shared';
import { validateNoteForm, validateFounderForm } from './validations';

type Props = {
  showModal: string,
  formNoteInitial?: {},
  blockFormInitial?: {},
  duesFormInitial?: {},
  importFormInitial?: {},
  founderFormInitial?: {},
  setModalState: Function,
};

export const MemberModals = ({ showModal, formNoteInitial, blockFormInitial, setModalState, importFormInitial, duesFormInitial, founderFormInitial}: Props) => {
  const { t } = useTranslation('');
  const { route } = useRoute();

  const createNotice = useDispatchHook(membersActions.createNotice);
  const createDue = useDispatchHook(membersActions.createDue);
  const createImport = useDispatchHook(membersActions.createImport);
  const fetchMemberNotices = useDispatchHook(membersActions.fetchMemberNotices);
  const fetchImports = useDispatchHook(membersActions.fetchImports);
  const fetchDues = useDispatchHook(membersActions.fetchDues);
  const createMemberFounders = useDispatchHook(membersActions.createMemberFounders);
  const fetchMemberFounders = useDispatchHook(membersActions.fetchMemberFounders);
  
  const handleSubmitNotice = useCallback(async values => {
    try {
      await createNotice(values, get(route, 'params.memberId'));
      await fetchMemberNotices(get(route, 'params.memberId'));
      toastr.success(t('common.success'));
      setModalState(null);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [createNotice, route, setModalState, t, fetchMemberNotices]);
  
  const handleCreateImport = useCallback(async values => {
    try {
      await createImport({ ...values, memberId: get(route, 'params.memberId') });
      await fetchImports(get(route, 'params.memberId'));
      toastr.success(t('common.success'));
      setModalState(null);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [createImport, fetchImports, route, setModalState, t]);
  
  const handleDueForm = useCallback(async values => {
    try {
      const memberId = get(route, 'params.memberId', get(route, 'params.id'));
      await createDue({ ...values, memberId });
      await fetchDues(memberId);
      toastr.success(t('common.success'));
      setModalState(null);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [route, setModalState, t, createDue, fetchDues]);
  
  const handleFoundersForm = useCallback(async values => {
    try {
      const memberId = get(route, 'params.memberId', get(route, 'params.id'));
      await createMemberFounders({ ...values, memberId });
      await fetchMemberFounders(memberId);
      toastr.success(t('common.success'));
      setModalState(null);
    } catch (e) {
      toastr.error(t('common.error'));
    }
  }, [createMemberFounders, fetchMemberFounders, setModalState, t, route]);
  
  const renderNoteForm = (
    <FormWrapper
      onSubmit={handleSubmitNotice}
      initialValues={formNoteInitial}
      validate={errors => validateNoteForm(errors, t('member.requiredField'))}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormNote />
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                type="button"
                variant="contained"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                {t('buttons.reset')}
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {t('buttons.add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
  
  const renderBlockMemberForm = (
    <FormWrapper
      onSubmit={handleSubmitNotice}
      initialValues={blockFormInitial}
      render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormBlockMember />
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                type="button"
                variant="contained"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                {t('buttons.reset')}
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {t('buttons.add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
  
  const renderImportExportForm = (
    <FormWrapper
      onSubmit={handleCreateImport}
      initialValues={importFormInitial}
      render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormImportExport />
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {t('buttons.add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
  
  const renderMembershipFeeForm = (
    <FormWrapper
      onSubmit={handleDueForm}
      initialValues={duesFormInitial}
      render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
        <form onSubmit={handleSubmit} noValidate>
          <MembershipFeeForm />
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                type="button"
                variant="contained"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                {t('buttons.reset')}
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {t('buttons.add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
  
  const renderFounderForm = (
    <FormWrapper
      onSubmit={handleFoundersForm}
      validate={errors => validateFounderForm(errors, t('member.requiredField'))}
      initialValues={founderFormInitial}
      render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormFounder />
          <Grid container alignItems="flex-start">
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {t('buttons.add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
  
  let modalTitle = showModal === 'note' ? 'addNote' : 'addToBlackList';
  
  let renderForm;
  switch(showModal) {
    case 'note':
      renderForm = renderNoteForm;
      break;
    case 'block':
      renderForm = renderBlockMemberForm;
      break;
    case 'fee':
      renderForm = renderMembershipFeeForm;
      modalTitle = 'addDue';
      break;
    case 'founder':
      renderForm = renderFounderForm;
      modalTitle = 'addFounder';
      break;
    case 'import':
      renderForm = renderImportExportForm;
      modalTitle = 'imports';
      break;
    default:
      renderForm = null;
  }
  
  return (
    <Modal
      isOpen={!!showModal}
      onClose={() => setModalState(null)}
      header={t(`member.${modalTitle}`)}
    >
      {renderForm}
    </Modal>
  );
};