export const validateParticipantForm = (values, message) => {
  const errors = {};
  
  if (!values.firstName) {
    errors.firstName = message;
  }
  
  if (!values.lastName) {
    errors.lastName = message;
  }
  
  if (+values.type === 1) {
    if (!values.phone) {
      errors.phone = message;
    }
    if (!values.email) {
      errors.email = message;
    }
  }
  
  return errors;
};