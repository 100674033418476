import React, {useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash/get';

import { useDispatchHook, useRoute } from '../../utils/hooks';
import { PageHeader } from '../../_shared/headers';
import { ButtonPrimary, GridList, Pagination } from '../../_shared';
import { invitationsActions, invitationsSelectors } from '../../redux/invitations';
import { SearchForm } from './components/searchForm';
import CircularProgress from "@material-ui/core/CircularProgress";
import {toastr} from "react-redux-toastr";

const InvitationsContainer = () => {
  const { t } = useTranslation();
  const { navigateTo, route } = useRoute();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setLoadingState] = useState(false);
  
  const fetchInvitations = useDispatchHook(invitationsActions.fetchInvitations);
  const deleteInvitation = useDispatchHook(invitationsActions.deleteInvitation);
  
  const invitations = useSelector(invitationsSelectors.invitations);
  const pagination = useSelector(invitationsSelectors.pagination);
  
  const columns = [
    { field: 'name', headerName: t('invitations.subject'), width: 50.6, link: { to: 'layout.invitation', params: { id: 'id', module: 'module' }} },
    { field: 'created_at', headerName: t('invitations.date'), width: 27.3 },
  ];
  
  const handleDelete = useCallback(async props => {
    await deleteInvitation({ id: get(props, 'id') });
    toastr.success(t('common.successDeleted'));
    navigateTo('layout.invitations', { ...get(route, 'params'), page: 1 }, {replace: false});
  }, [deleteInvitation, navigateTo, route, t]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoadingState(true);
      await fetchInvitations({
        ...get(route, 'params', {}),
        page: get(route, 'params.page', 1),
      });
      setLoadingState(false);
    };
    fetchData();
  }, [fetchInvitations, route]);
  
  return (
    <Container fluid>
      <PageHeader title={t('invitations.header', { count: get(pagination, 'total', 0) })}>
        <ButtonPrimary aria-controls="fade-menu" aria-haspopup="true" onClick={e => setAnchorEl(e.currentTarget)}>
          {t('buttons.actions')}
        </ButtonPrimary>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => navigateTo('layout.addInvitation', { module: get(route, 'params.module') }, {replace: false})}>{t('buttons.add')}</MenuItem>
        </Menu>
      </PageHeader>
      <Row>
        <Col xs={12} md={9} lg={8}>
          {!isLoading ? (
            <GridList
              columns={columns}
              rows={invitations}
              actions={{
                delete: handleDelete,
                edit: 'layout.editInvitation',
                editParams: ['id', 'module'],
              }}
            />
          ) : <CircularProgress color="secondary" />}
        </Col>
        <Col xs={12} md={3} lg={4}>
          <SearchForm />
        </Col>
      </Row>
      <Pagination data={pagination} />
    </Container>
  );
};

export default InvitationsContainer;